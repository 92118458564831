import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const ChevronRightCircle = ({ fill, color, height, width }: Props) => (
  <svg fill="none" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" fill={fill} fillOpacity=".72" r="12" transform="rotate(-180 12 12)" />
    <path d="m10 17 5-5-5-5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
)

export const IconChevronRightCircle = (props: IconCommonProps) => <Icon Svg={ChevronRightCircle} {...props} />
