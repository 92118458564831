import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const DeleteCircle = ({ color, width }: Props) => (
  <svg
    data-test-id="icon-delete-circle"
    version="1.1"
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17 14.83 12 12m2.83-2.83L12 12m0 0L9.17 9.17M12 12l2.83 2.83M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
)

export const IconDeleteCircle = (props: IconCommonProps) => <Icon Svg={DeleteCircle} {...props} />
