import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const LogoGoogle = ({ height, width }: Props) => (
  <svg
    data-test-id="icon-logo-google"
    fill="none"
    height={height}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M31.52 20.2729C31.52 19.422 31.4437 18.6038 31.3018 17.8184H20V22.4602H26.4582C26.18 23.9602 25.3346 25.2311 24.0636 26.082V29.0929H27.9418C30.2109 27.0038 31.52 23.9275 31.52 20.2729Z"
      fill="#4285F4"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M19.9995 31.9998C23.2395 31.9998 25.9559 30.9253 27.9414 29.0925L24.0632 26.0816C22.9886 26.8016 21.6141 27.2271 19.9995 27.2271C16.8741 27.2271 14.2286 25.1162 13.285 22.2798H9.27588V25.3889C11.2504 29.3107 15.3086 31.9998 19.9995 31.9998Z"
      fill="#34A853"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.2855 22.2799C13.0455 21.5599 12.9091 20.7909 12.9091 19.9999C12.9091 19.209 13.0455 18.4399 13.2855 17.7199V14.6108H9.27637C8.46364 16.2308 8 18.0636 8 19.9999C8 21.9363 8.46364 23.769 9.27637 25.389L13.2855 22.2799Z"
      fill="#FBBC05"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M19.9995 12.7727C21.7614 12.7727 23.3432 13.3782 24.5868 14.5673L28.0286 11.1255C25.9505 9.18909 23.2341 8 19.9995 8C15.3086 8 11.2504 10.6891 9.27588 14.6109L13.285 17.72C14.2286 14.8837 16.8741 12.7727 19.9995 12.7727Z"
      fill="#EA4335"
      fillRule="evenodd"
    />
  </svg>
)

export const IconLogoGoogle = (props: IconCommonProps) => <Icon Svg={LogoGoogle} {...props} />
