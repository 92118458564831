import { Flex } from 'cdk'
import { COLOR_DARKER, COLOR_PRIMARY, SPACING_3XS, SPACING_XS, TIME_150 } from 'design-tokens'
import { Icon as SrnsIcon, type IconElement } from 'icons'
import { type HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Text } from '../Text'

const BottomBarItemContainer = styled(Flex)`
  color: ${COLOR_DARKER};
  transition: color ${TIME_150} ease-in-out;

  a&:hover,
  a&:focus,
  button&:hover,
  button&:focus,
  a:hover &,
  a:focus &,
  button:hover &,
  button:focus & {
    color: ${COLOR_PRIMARY};
  }
`

export type BottomBarItemProps = HTMLAttributes<HTMLElement> & {
  active?: boolean
  Icon?: IconElement
  IconActive?: IconElement
  label?: string
}

export const BottomBarItem = ({ active, children, Icon, IconActive, label, title, ...props }: BottomBarItemProps) => (
  <BottomBarItemContainer $align="center" $gap={SPACING_3XS} $px={SPACING_3XS} $py={SPACING_XS} {...props}>
    <SrnsIcon Svg={active ? IconActive : Icon} colorName={active ? 'primary' : undefined} size={24} />
    <Text colorName={active ? 'primary' : undefined} ellipsis kind="micro" textAlign="center" title={title ?? label}>
      {children ?? label}
    </Text>
  </BottomBarItemContainer>
)
