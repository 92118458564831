import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisMoneyBox = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.05981 16.7021 3.1435 12.0937c-.37868-.6634.03482-1.3916.74431-1.3916h3.11225"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M2.38888 17.8056c-1.2001 2.471.52178 5.3965 3.18286 5.3965H18.4336c2.4681 0 4.1847-2.5441 3.3551-4.9682l-1.3774-4.0315 1.3149-1.8494c.4957-.6948-.0052-1.6561-.861-1.6508h-9.4285c-.4905 0-.9497.2298-1.2471.6216l-2.19131 3.1724M11.7023 4.45511c0 2.0727 1.005 3.75295 2.2448 3.75295 1.2398 0 2.2449-1.68025 2.2449-3.75295 0-2.07271-1.0051-3.752962-2.2449-3.752962-1.2398 0-2.2448 1.680252-2.2448 3.752962Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M13.9888.702148h-2.8875c-.9555 0-2.10104 1.680172-2.10104 3.752962 0 2.07278 1.14554 3.75295 2.10104 3.75295h2.8875"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisMoneyBox = (props: IconCommonProps) => <Icon Svg={SerenisMoneyBox} {...props} />
