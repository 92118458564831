import { COLOR_GREY_40 } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PaymentCardAmericanExpress = ({ width }: Props) => (
  <svg fill="none" viewBox="0 0 50 34" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z" fill="#006FCF" />
    <path
      d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z"
      stroke={COLOR_GREY_40}
      strokeWidth=".5"
    />
    <path
      d="m26.18 28.96 13.84.03 2.17-2.42 2.2 2.41h4.11v-3.54l-2.58-2.78 2.58-2.75v-3.47l-22.32-.02v12.54Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M27.67 27.59V17.8h8.3v2.26h-5.61v1.53h5.47v2.21h-5.47v1.5h5.6v2.29h-8.29Z"
      fill="#006FCF"
      fillRule="evenodd"
    />
    <path
      d="m45.1 17.8-2.81 3.1-2.81-3.1h-3.55l4.59 4.89-4.6 4.9h3.46l2.83-3.14 2.85 3.14h3.44v-.14L44 22.69l4.5-4.81v-.08h-3.4Z"
      fill="#006FCF"
    />
    <path d="M43.43 5.29 42.28 8.5 41.13 5.3H34.5v4.3l-1.89-4.3h-5.38l-5.53 12.54H48.5V5.3h-5.07Z" fill="#fff" />
    <path
      clipRule="evenodd"
      d="m28.61 12.28 1.3-3.12 1.3 3.12h-2.6Zm-.37-5.63-4.34 9.78h2.97l.82-1.96h4.44l.82 1.96H36l-4.32-9.78h-3.44Z"
      fill="#006FCF"
      fillRule="evenodd"
    />
    <path
      d="M35.96 16.43h2.64V9.7l2.5 6.72h2.36l2.45-6.7v6.72l2.59-.02V6.65h-4.03l-2.17 6.03-2.15-6.02-4.19-.01v9.78Z"
      fill="#006FCF"
    />
  </svg>
)

export const IconPaymentCardAmericanExpress = (props: IconCommonProps) => (
  <Icon Svg={PaymentCardAmericanExpress} {...props} />
)
