import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const ArrowRightToLine = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4">
      <path d="m11.18 15.057 3.257-3.258-3.257-3.257M4.666 11.8h9.771M18.666 19.333V4.666" />
    </g>
  </svg>
)

export const IconArrowRightToLine = (props: IconCommonProps) => <Icon Svg={ArrowRightToLine} {...props} />
