import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisHome = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 17h10M13.32 2.7c3.39 1.505 5.961 3.482 7.29 4.625.672.577 1.047 1.408 1.093 2.293.064 1.214.14 3.115.14 5.194 0 1.6-.045 3.144-.095 4.356a2.666 2.666 0 0 1-2.584 2.565c-1.622.055-4.029.11-7.164.11s-5.542-.055-7.164-.11a2.666 2.666 0 0 1-2.584-2.565c-.05-1.212-.096-2.757-.096-4.356 0-2.079.077-3.98.141-5.194.047-.885.421-1.716 1.093-2.293 1.329-1.143 3.9-3.12 7.29-4.626.84-.372 1.8-.372 2.64 0Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisHome = (props: IconCommonProps) => <Icon Svg={SerenisHome} {...props} />
