import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const Card = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.77 18c.1.97 1.08 1.72 2.36 1.8 1.61.1 4.19.2 7.87.2 3.68 0 6.26-.1 7.87-.2 1.28-.08 2.26-.83 2.36-1.8a68.42 68.42 0 0 0 0-12c-.1-.97-1.08-1.72-2.36-1.8C18.26 4.1 15.68 4 12 4c-3.68 0-6.26.1-7.87.2-1.28.08-2.26.83-2.36 1.8a68.35 68.35 0 0 0 0 12Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M4.08 15.5c.03.24.3.43.67.45a42.27 42.27 0 0 0 4.5 0c.36-.02.64-.2.67-.45a12.55 12.55 0 0 0 .05-2.5 9.3 9.3 0 0 0-.05-.5c-.03-.24-.3-.43-.67-.45a42.27 42.27 0 0 0-4.5 0c-.36.02-.64.2-.67.45A9.29 9.29 0 0 0 4 14c0 .7.04 1.2.08 1.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconCard = (props: IconCommonProps) => <Icon Svg={Card} {...props} />
