import {
  COLOR_GREY_100,
  COLOR_PRIMARY,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_20,
  COLOR_PRIMARY_40,
  COLOR_PRIMARY_70,
  COLOR_WHITE,
} from 'design-tokens'

type Props = {
  height?: number
}

export const EmptyCalendar = ({ height = 120 }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 176 120" xmlns="http://www.w3.org/2000/svg">
    <path d="M130.1 94.086H43.19l2.392-38.937H130.1v38.937Z" fill="url(#empty-calendar-a)" />
    <path
      d="M102.06 55.016c-7.761-1.7-11.562-18.161-12.492-26.18h-74.95L0 93.688h42.658c6.378-3.056 10.365-7.043 10.365-20.465s3.364-18.206 11.562-18.206c8.77 0 11.578 5.183 12.226 12.226 2.445 26.578 12.624 46.157 17.408 52.625h81.329c-21.927-6.113-28.438-36.545-31.495-52.625-1.911-10.053-4.917-12.403-6.91-12.226H102.06Z"
      fill={COLOR_PRIMARY_10}
    />
    <path
      d="m21.262 36.678 4.12 5.847 4.385-9.037M41.063 36.678l4.12 5.847 4.385-9.037M43.455 56.877l4.12 5.847 4.385-9.036M104.053 81.329l4.119 5.847 4.386-9.036"
      stroke={COLOR_GREY_100}
      strokeWidth="1.063"
    />
    <path
      d="M82.79 10.898H21.396a6.644 6.644 0 0 0-6.644 6.644v11.296h74.684V17.542a6.645 6.645 0 0 0-6.644-6.645Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M14.484 29.102c-1.816 15.416-2.658 49.94 8.505 64.718M20.863 69.235h32.293M15.946 48.77H95.15M76.677 71.495h68.04M80.93 90.365h68.837M87.84 108.704H159.6M73.355 29.102c.265 8.284 2.578 25.09 9.7 26.047M34.285 28.97c-.709 11.96 2.02 38.538 18.605 49.17M54.219 28.97c-.044 6.866 1.196 21.794 6.511 26.578M120.265 55.15c2.569.575 8.611 6.59 12.226 26.046 4.518 24.319 18.073 37.741 22.192 38.671M104.053 55.15c2.569.575 8.611 6.59 12.226 26.046 4.518 24.319 17.94 37.741 22.059 38.671M86.776 55.15c2.57.575 8.612 6.59 12.226 26.046 4.519 24.319 18.206 37.874 22.326 38.804"
      stroke={COLOR_PRIMARY_40}
      strokeWidth=".797"
    />
    <path
      d="M14.75 28.97c-.93 18.383 3.428 56.744 28.305 64.717M10.232 93.554c-.709-13.245-.824-44.571 4.385-63.92M137.541 55.017H64.85M27.242 11.163C27.242 4.998 32.24 0 38.405 0s11.163 4.998 11.163 11.163a11.14 11.14 0 0 1-4.092 8.638"
      stroke={COLOR_PRIMARY_40}
      strokeWidth=".797"
    />
    <path
      d="M36.278 11.163C36.278 4.998 41.276 0 47.441 0s11.163 4.998 11.163 11.163a11.14 11.14 0 0 1-4.092 8.638"
      stroke={COLOR_PRIMARY_40}
      strokeWidth=".797"
    />
    <path
      d="M45.315 11.163C45.315 4.998 50.313 0 56.478 0s11.163 4.998 11.163 11.163a11.14 11.14 0 0 1-4.092 8.638"
      stroke={COLOR_PRIMARY_40}
      strokeWidth=".797"
    />
    <path
      d="M54.352 11.163C54.352 4.998 59.349 0 65.514 0c6.165 0 11.163 4.998 11.163 11.163a11.14 11.14 0 0 1-4.091 8.638"
      stroke={COLOR_PRIMARY_40}
      strokeWidth=".797"
    />
    <path
      d="M140.437 6.001h16.744v53.262h-16.744z"
      fill="url(#empty-calendar-b)"
      transform="rotate(64.034 140.437 6.001)"
    />
    <path
      d="M137.89 7.24a7.974 7.974 0 0 1 10.66 3.678l.349.716a7.974 7.974 0 0 1-3.678 10.66l-.826.403-7.332-15.054.827-.403Z"
      fill={COLOR_PRIMARY_70}
    />
    <path d="m86.232 41.71 6.444-12.483 7.357 15.107-13.801-2.624Z" fill={COLOR_WHITE} />
    <path d="m86.232 41.71 3.09-5.985 3.527 7.243-6.617-1.258Z" fill={COLOR_GREY_100} />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="empty-calendar-a"
        x1="86.645"
        x2="86.645"
        y1="55.149"
        y2="94.086"
      >
        <stop offset=".29" stopColor={COLOR_WHITE} />
        <stop offset="1" stopColor={COLOR_PRIMARY_20} />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="empty-calendar-b"
        x1="148.809"
        x2="148.809"
        y1="6.001"
        y2="59.263"
      >
        <stop offset=".075" stopColor={COLOR_WHITE} />
        <stop offset=".56" stopColor={COLOR_PRIMARY_20} />
      </linearGradient>
    </defs>
  </svg>
)
