import { COLOR_MENTAL, COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PathSexologySolid = ({ width }: Props) => (
  <svg
    data-test-id="icon-path-sexology-solid"
    fill="none"
    height={width}
    viewBox="0 0 48 48"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 0C4.8 0 0 4.80459 0 24.023C0 43.2414 4.79999 48 24 48C43.2 48 48 43.1955 48 23.9771C48 4.75877 43.2 0 24 0Z"
      fill={COLOR_MENTAL}
    />
    <path
      d="M23.999 39.233V35.07m0 0 4.858-4.858m-4.858 4.858-4.858-4.858M16.362 27.634l-4.215-4.215a6.94 6.94 0 0 1 9.815-9.815l2.032 2.033 2.033-2.033a6.94 6.94 0 1 1 9.815 9.815l-4.215 4.215"
      stroke={COLOR_WHITE}
      strokeWidth="2.4"
    />
  </svg>
)

export const IconPathSexologySolid = (props: IconCommonProps) => <Icon Svg={PathSexologySolid} {...props} />
