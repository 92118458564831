import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const Info = ({ width, height, color }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12 1c6.0751 0 11 4.92486 11 11 0 6.0751-4.9249 11-11 11-6.07514 0-11-4.9249-11-11C1 5.92486 5.92486 1 12 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path d="M13 6.00003c0 .55229-.4477 1-1 1s-1-.44771-1-1c0-.55228.4477-1 1-1s1 .44772 1 1Z" stroke={color} />
      <path d="M12 9.5V18" stroke={color} strokeLinecap="round" strokeWidth="1.4" />
    </g>
  </svg>
)

export const IconInfo = (props: IconCommonProps) => <Icon Svg={Info} {...props} />
