import { Flex } from 'cdk'
import { IconChevronLineRight } from 'icons'
import { Fragment } from 'react'
import { Breadcrumb, type BreadcrumbProps } from './Breadcrumb'

type BreadcrumbsProps = {
  items: (Omit<BreadcrumbProps, 'children'> & { key: string; label: string })[]
  renderItem?: (item: BreadcrumbProps) => JSX.Element
}

export const Breadcrumbs = ({ items, renderItem }: BreadcrumbsProps) => (
  <Flex aria-label="Breadcrumbs" as="nav">
    <Flex $direction="row" $gap={4} as="ol">
      {items.map(({ key, ...props }, index) => (
        <Fragment key={key}>
          {renderItem == null ? <Breadcrumb {...props} /> : renderItem({ key, ...props })}
          {index < items.length - 1 && (
            <Flex $justify="center" $pb={2} aria-hidden as="li" data-testid="separator">
              <IconChevronLineRight colorName="neutral-70" size={16} />
            </Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  </Flex>
)
