import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const ChevronLineLeft = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 16 16" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4 6 8.333l4 4.334" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" />
  </svg>
)

export const IconChevronLineLeft = (props: IconCommonProps) => <Icon Svg={ChevronLineLeft} {...props} />
