import { COLOR_GREY_40, COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PaymentCardMastercard = ({ width }: Props) => (
  <svg fill="none" viewBox="0 0 50 34" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z" fill={COLOR_WHITE} />
    <path
      d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z"
      stroke={COLOR_GREY_40}
      strokeWidth=".5"
    />
    <path d="M28.5 10.71h-7v12.57h7V10.71Z" fill="#FF5F00" />
    <path d="M21.95 17a8.05 8.05 0 0 1 3.04-6.29 8 8 0 1 0 .02 12.57A8 8 0 0 1 21.95 17Z" fill="#EB001B" />
    <path
      d="M37.94 17a8 8 0 0 1-12.93 6.28 8.01 8.01 0 0 0 0-12.57 7.97 7.97 0 0 1 11.22 1.36A7.91 7.91 0 0 1 37.94 17Z"
      fill="#F79E1B"
    />
  </svg>
)

export const IconPaymentCardMastercard = (props: IconCommonProps) => <Icon Svg={PaymentCardMastercard} {...props} />
