import {
  COLOR_BLACK,
  COLOR_GREY_110,
  COLOR_NUTRITION,
  COLOR_PRIMARY,
  COLOR_PRIMARY_10,
  COLOR_WHITE,
} from 'design-tokens'
import { Svg } from './components/Svg'

type Props = {
  size?: number
}

export const PathNutrition = ({ size = 164 }: Props) => (
  <Svg fill="none" height={size} viewBox="0 0 164 164" width={size}>
    <g clipPath="url(#path-nutrition-a)">
      <path
        d="M84.33 157.71c-87.15 8.8-59.67-90.95-35.04-141.93 2.53 27.92 8.02 81.44 32.3 81.44 19.85 0 21.89-55.07 20.48-81.44 6.33 13.33 91.2 130.94-17.74 141.93Z"
        fill="url(#path-nutrition-b)"
      />
      <path
        clipRule="evenodd"
        d="M91.77 118.04a10.7 10.7 0 0 0-21.36-1.26c-1.7 8.09 1.2 25.97 11.4 29.79 11 4.12 26.34-9.68 28.92-16.58.07-.17.12-.34.17-.5a10.72 10.72 0 0 0-6.03-14.34 10.7 10.7 0 0 0-12.07 3.28l-.4-.15-.63-.24Z"
        fill="url(#path-nutrition-c)"
        fillRule="evenodd"
      />
      <path
        d="M94.82 110.04c.05-.13.2-.2.32-.15l.47.18c.13.05.2.2.15.33l-3 8.01-.95-.35 3-8.02Z"
        fill={COLOR_GREY_110}
      />
      <path
        clipRule="evenodd"
        d="M94.58 110.53a5.67 5.67 0 0 1 10.58-.57 5.67 5.67 0 0 1-10.58.57Z"
        fill={COLOR_NUTRITION}
        fillRule="evenodd"
      />
      <path
        d="m105.17 110.03-10.41.53M103.66 107.98l-1.92 2.24M101.95 106.8l-2.68 3.56M100.12 106.65l-3.33 3.86M97.56 107.03l-2.36 3.42"
        stroke={COLOR_WHITE}
        strokeWidth=".25"
      />
      <path
        clipRule="evenodd"
        d="M87.49 80.06c.05.16.1.33.13.5 1.7 7.18-3.38 27.18-14.82 29.87-10.6 2.5-22.94-10.75-26.04-18.42a10.71 10.71 0 0 1 18.46-10.83 74.05 74.05 0 0 0 1.07-.25 10.7 10.7 0 0 1 21.2-.87Z"
        fill="url(#path-nutrition-d)"
        fillRule="evenodd"
      />
      <path
        d="M63.3 72.84a.25.25 0 0 1 .2-.3l.48-.12c.14-.03.27.05.3.19l1.97 8.33-.98.23-1.97-8.33Z"
        fill={COLOR_GREY_110}
      />
      <path
        clipRule="evenodd"
        d="M63.38 73.37a5.67 5.67 0 0 1 8.48-6.35 5.67 5.67 0 0 1-8.48 6.35Z"
        fill={COLOR_NUTRITION}
        fillRule="evenodd"
      />
      <path
        d="m71.9 67.07-8.36 6.23M69.5 66.2l-.34 2.93M67.43 66.18l-.25 4.45M65.83 67.07l-.62 5.06M63.91 68.82l-.06 4.15"
        stroke={COLOR_WHITE}
        strokeWidth=".25"
      />
      <path d="M33.85 98.48c1.22.18 4.38 1.58 7.27 5.69" stroke={COLOR_NUTRITION} strokeWidth=".87" />
      <path
        clipRule="evenodd"
        d="M49.47 100.57a4.8 4.8 0 0 1 .26.4c1.62 2.53 5.2 4.44 8.7 6.31l1.16.63a19.34 19.34 0 1 1-22.24 14.96l-.19-2.06c-.33-3.68-.66-7.41-2.08-9.93a4.44 4.44 0 0 1 5.96-6.58l.23-.15a48 48 0 0 0 .15-.1 4.45 4.45 0 0 1 8.05-3.48Z"
        fill="url(#path-nutrition-e)"
        fillRule="evenodd"
      />
      <path d="M133.2 113.34c-1.06.05-3.93-.62-7-3.62" stroke={COLOR_NUTRITION} strokeWidth=".74" />
      <path
        clipRule="evenodd"
        d="m119.48 113.78.13.13.14.16a3.8 3.8 0 0 0 6.2-4.2l.12-.1.17-.17a3.8 3.8 0 0 0 3.96-6.46c-1.6-1.9-2.47-4.97-3.32-8.01l-.5-1.69a16.52 16.52 0 1 0-16.29 16.08c.37.1.73.22 1.09.33 3.23 1.02 6.53 2.06 8.3 3.93Z"
        fill="url(#path-nutrition-f)"
        fillRule="evenodd"
      />
      <path
        d="M84.33 157.71c-87.15 8.8-59.67-90.95-35.04-141.93 2.53 27.92 8.02 93.86 32.3 93.86 45.77 0 21.89-67.49 20.48-93.86 6.33 13.33 91.2 130.94-17.74 141.93Z"
        fill="url(#path-nutrition-g)"
      />
      <path
        d="M109.06 17.2c1.41-1.82.94-10.53.33-16.6a3.4 3.4 0 0 0-3.4-3.06h-3.83a3.47 3.47 0 0 0-3.47 3.48v11.17c0 1.1.51 2.12 1.38 2.78l2.96 2.23c1.31.9 4.35 2.16 6.03 0Z"
        fill={COLOR_WHITE}
        stroke={COLOR_BLACK}
        strokeWidth="1.64"
      />
      <path
        d="M55.22 30.07C42.36 28.75 42.16 8.21 43.8-2.46h59.22v17.67c0 4.6-.3 9.4-2.76 13.3-2.33 3.69-4.98 4.72-7.07 4.62-1.55-.07-3.24.05-4.5.94-5.71 4-11.4 2.59-14.67.7a4.7 4.7 0 0 0-3.38-.58c-5.93 1.37-9.71-.36-11.7-2.25a6.57 6.57 0 0 0-3.73-1.87Z"
        fill={COLOR_WHITE}
        stroke={COLOR_BLACK}
        strokeWidth="1.64"
      />
    </g>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-nutrition-b" x1="82.18" x2="82.18" y1="15.78" y2="158.26">
        <stop stopColor={COLOR_NUTRITION} />
        <stop offset="1" stopColor={COLOR_NUTRITION} stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-nutrition-c" x1="72.5" x2="99.5" y1="113" y2="143.5">
        <stop stopColor={COLOR_PRIMARY} stopOpacity=".8" />
        <stop offset="1" stopColor={COLOR_PRIMARY_10} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-nutrition-d" x1="52.5" x2="87.5" y1="98.5" y2="89.5">
        <stop stopColor={COLOR_PRIMARY} stopOpacity=".8" />
        <stop offset="1" stopColor={COLOR_PRIMARY_10} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-nutrition-e" x1="63.58" x2="47.03" y1="137.88" y2="99.21">
        <stop stopColor={COLOR_PRIMARY} stopOpacity=".8" />
        <stop offset="1" stopColor={COLOR_PRIMARY_10} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-nutrition-f" x1="102" x2="122.02" y1="85" y2="114.82">
        <stop stopColor={COLOR_PRIMARY} stopOpacity=".8" />
        <stop offset="1" stopColor={COLOR_PRIMARY_10} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-nutrition-g" x1="82.18" x2="82.18" y1="15.78" y2="158.26">
        <stop stopColor={COLOR_NUTRITION} />
        <stop offset="1" stopColor={COLOR_NUTRITION} stopOpacity="0" />
      </linearGradient>
      <clipPath id="path-nutrition-a">
        <path d="M0 0h164v164H0z" fill={COLOR_WHITE} />
      </clipPath>
    </defs>
  </Svg>
)
