import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const ArrowLeftToLine = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4">
      <path d="m12.82 8.943-3.257 3.258 3.257 3.257M19.334 12.2H9.563M5.334 4.667v14.667" />
    </g>
  </svg>
)

export const IconArrowLeftToLine = (props: IconCommonProps) => <Icon Svg={ArrowLeftToLine} {...props} />
