import { gql, type ObservableQuery, useLazyQuery } from '@apollo/client'
import { createContext, type ReactNode, useCallback, useContext, useMemo, useRef } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { type MeQuery, type MeQueryVariables } from '~/types/graphql'
import { useLinkFormFlowResponses } from './useLinkFormFlowResponses'
import { useUserLastActivity } from './useUserLastActivity'

const QUERY = gql`
  query me {
    me {
      id
      activeReferral
      company {
        id
        name
      }
      createdAt
      email
      emailVerifiedAt
      hashFb
      features
      firstName
      fullName
      hash
      lastName
      marketingEmails
      partnershipSource
      phoneNumber
      phoneNumberVerifiedAt
      professionTypeValues
      pushNotificationsEnabled
      referralCode
      timeZone
    }
  }
`

type CurrentUser = Omit<NonNullable<MeQuery['me']>, 'emailVerifiedAt' | 'phoneNumberVerifiedAt'> & {
  emailVerified: boolean
  phoneNumberFormatted: string
  phoneNumberVerified: boolean
}

export type CurrentUserContext = {
  loading: boolean
  onLoginCompleted: () => Promise<void>
  onLogoutCompleted: VoidFunction
  updateQuery: ObservableQuery<MeQuery>['updateQuery']
  user: CurrentUser | null
}

export const CurrentUserContext = createContext<CurrentUserContext | null>(null)

type Props = {
  children: ReactNode
}

export const CurrentUserProvider = ({ children }: Props) => {
  const [userLastActivity] = useUserLastActivity()
  const [linkFormFlowResponses] = useLinkFormFlowResponses()
  const hasAlreadyFetchedMe = useRef(false)

  // @TODO(comes): updateQuery should not be exported
  const [getMe, { data, error, loading, updateQuery }] = useLazyQuery<MeQuery, MeQueryVariables>(QUERY, {
    fetchPolicy: 'network-only',
  })

  const onLogoutCompleted = useCallback(() => {
    hasAlreadyFetchedMe.current = false
  }, [])

  const onLoginCompleted = useCallback(async () => {
    try {
      if (hasAlreadyFetchedMe.current) {
        return
      }

      hasAlreadyFetchedMe.current = true

      await getMe()

      await userLastActivity()
      await linkFormFlowResponses()
      // eslint-disable-next-line no-empty
    } catch {}
  }, [getMe, userLastActivity, linkFormFlowResponses])

  const value = useMemo(
    () => ({
      loading,
      onLoginCompleted,
      onLogoutCompleted,
      updateQuery,
      user:
        data?.me != null
          ? {
              activeReferral: data.me.activeReferral,
              company: data.me.company,
              createdAt: data.me.createdAt,
              email: data.me.email,
              emailVerified: data.me.emailVerifiedAt != null,
              hashFb: data.me.hashFb,
              features: data.me.features,
              firstName: data.me.firstName,
              fullName: data.me.fullName,
              hash: data.me.hash ?? '',
              id: data.me.id,
              lastName: data.me.lastName,
              marketingEmails: !!data.me.marketingEmails,
              partnershipSource: data.me.partnershipSource,
              phoneNumber: data.me.phoneNumber,
              phoneNumberFormatted: data.me.phoneNumber ?? '',
              phoneNumberVerified: data.me.phoneNumberVerifiedAt != null,
              professionTypeValues: data.me.professionTypeValues,
              pushNotificationsEnabled: data.me.pushNotificationsEnabled,
              referralCode: data.me.referralCode,
              timeZone: data.me.timeZone,
            }
          : null,
    }),
    [data?.me, loading, onLoginCompleted, onLogoutCompleted, updateQuery],
  )

  if (data?.me == null && error != null) {
    return <GenericErrorContent />
  }

  if (loading) {
    return <CenteredLoader />
  }

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}

export const useCurrentUserNullable = () => {
  const context = useContext(CurrentUserContext)

  if (context == null) {
    throw new Error('The `useCurrentUserNullable` should be wrapped with `CurrentUserProvider`.')
  }

  const { loading, onLoginCompleted, onLogoutCompleted, updateQuery, user } = context

  return { loading, onLoginCompleted, onLogoutCompleted, updateQuery, ...user }
}

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext)

  if (context == null) {
    throw new Error('The `useCurrentUser` should be wrapped with `CurrentUserProvider`.')
  }

  if (context.user == null) {
    throw new Error('Missing current user')
  }

  const { loading, onLoginCompleted, onLogoutCompleted, updateQuery, user } = context

  return { loading, onLoginCompleted, onLogoutCompleted, updateQuery, ...user }
}
