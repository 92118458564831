import { Flex, OverflowAuto, PositionFixed } from 'cdk'
import { SPACING_MD } from 'design-tokens'
import { BottomBarItem, type BottomBarItemProps } from './BottomBarItem'

export type BottomBarProps = {
  items: (BottomBarItemProps & { key: string })[]
  renderItem?: (item: BottomBarItemProps & { key: string }) => JSX.Element
}

export const BottomBar = ({ items, renderItem }: BottomBarProps) => (
  <PositionFixed
    $backgroundColorName="lighter"
    $bottom={0}
    $elevationName="xxs"
    $left={0}
    $right={0}
    aria-label="Main navigation"
    as="nav"
  >
    <OverflowAuto
      $direction="row"
      $justify="space-between"
      $px={SPACING_MD}
      aria-label="Main navigation"
      as="ul"
      role="menubar"
    >
      {items.map(({ key, ...props }) => (
        <Flex key={key} $basis="100%" $grow={1} $shrink={1} as="li" data-testid="bottom-bar-item" role="none">
          {renderItem == null ? <BottomBarItem {...props} key={key} /> : renderItem({ key, ...props })}
        </Flex>
      ))}
    </OverflowAuto>
  </PositionFixed>
)
