import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const LogoApple = ({ height, width }: Props) => (
  <svg
    data-test-id="icon-logo-apple"
    fill="none"
    height={height}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7954 10.0885C22.3723 8.01013 24.5645 8 24.5645 8C24.5645 8 24.8906 9.95406 23.3241 11.8364C21.6514 13.8464 19.7501 13.5175 19.7501 13.5175C19.7501 13.5175 19.3931 11.9367 20.7954 10.0885V10.0885ZM19.9506 14.8863C20.7619 14.8863 22.2675 13.7712 24.2273 13.7712C27.6007 13.7712 28.9278 16.1716 28.9278 16.1716C28.9278 16.1716 26.3322 17.4986 26.3322 20.7187C26.3322 24.3511 29.5656 25.603 29.5656 25.603C29.5656 25.603 27.3053 31.9648 24.2524 31.9648C22.8502 31.9648 21.76 31.0198 20.2826 31.0198C18.777 31.0198 17.2829 32 16.3098 32C13.522 32.0001 10 25.9653 10 21.1143C10 16.3417 12.9811 13.838 15.7773 13.838C17.595 13.838 19.0056 14.8863 19.9506 14.8863V14.8863Z"
      fill="#999999"
    />
  </svg>
)

export const IconLogoApple = (props: IconCommonProps) => <Icon Svg={LogoApple} {...props} />
