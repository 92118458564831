import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const ChevronLineRight = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 16 16" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="m6 13 4-4.333-4-4.334" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" />
  </svg>
)

export const IconChevronLineRight = (props: IconCommonProps) => <Icon Svg={ChevronLineRight} {...props} />
