import { COLOR_BLACK, COLOR_RED_60, COLOR_RED_80 } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const ReportsPoor = ({ width }: Props) => (
  <svg
    data-test-id="icon-reports-poor"
    fill="none"
    version="1.1"
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 16h6v5H3z" fill={COLOR_RED_80} />
    <path
      d="M9 21h6m-6 0v-5m0 5H3.6a.6.6 0 0 1-.6-.6v-3.8c0-.33.27-.6.6-.6H9m6 5V9m0 12h5.4a.6.6 0 0 0 .6-.6V3.6a.6.6 0 0 0-.6-.6h-4.8a.6.6 0 0 0-.6.6V9m0 0H9.6a.6.6 0 0 0-.6.6V16"
      stroke={COLOR_RED_60}
      strokeWidth="1.5"
    />
    <circle cx="20" cy="4" fill={COLOR_RED_60} r="3.33" stroke={COLOR_BLACK} strokeWidth="1.33" />
  </svg>
)

export const IconReportsPoor = (props: IconCommonProps) => <Icon Svg={ReportsPoor} {...props} />
