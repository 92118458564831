import { COLOR_GREY_40, COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PaymentCardPayPal = ({ width }: Props) => (
  <svg fill="none" viewBox="0 0 50 34" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z" fill={COLOR_WHITE} />
    <path
      d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z"
      stroke={COLOR_GREY_40}
      strokeWidth=".5"
    />
    <path
      d="M41.33 13.02v5.96h-1.38v-5.96h1.38Zm-1.88 2.01V19h-1.22v-.34a1.65 1.65 0 0 1-1.17.47 2 2 0 0 1-.8-.16 2.04 2.04 0 0 1-1.07-1.12c-.1-.25-.15-.53-.15-.83a2.19 2.19 0 0 1 .59-1.5c.18-.18.4-.33.64-.44.24-.1.5-.16.8-.16a1.61 1.61 0 0 1 1.16.47v-.35h1.22Zm-2.17 2.88a.8.8 0 0 0 .62-.25.89.89 0 0 0 .25-.65.88.88 0 0 0-.25-.65.81.81 0 0 0-.62-.25.83.83 0 0 0-.62.25.9.9 0 0 0-.24.65c0 .26.08.48.24.65.17.17.37.25.62.25Zm-4.2-4.89c.37 0 .67.05.93.15.26.1.47.24.65.42a1.94 1.94 0 0 1 .57 1.4 1.94 1.94 0 0 1-1.22 1.82c-.26.1-.56.15-.93.15h-.66V19h-1.4v-5.97h2.06Zm-.2 2.72c.19 0 .33-.02.43-.06a.69.69 0 0 0 .48-.69c0-.22-.07-.4-.21-.53a.74.74 0 0 0-.27-.15c-.1-.04-.24-.06-.43-.06h-.46v1.49h.46Zm-7-.7h1.51l1.03 1.89h.02l.91-1.9h1.41L27.75 21h-1.4l1.38-2.73-1.86-3.24Zm-.28 0v3.95h-1.22v-.34a1.65 1.65 0 0 1-1.16.47 2 2 0 0 1-.8-.16 2.04 2.04 0 0 1-1.07-1.12c-.1-.25-.15-.53-.15-.83a2.19 2.19 0 0 1 .58-1.5c.18-.18.4-.33.64-.44.24-.1.51-.16.8-.16a1.61 1.61 0 0 1 1.16.47v-.35h1.22Zm-2.16 2.87a.8.8 0 0 0 .61-.25.89.89 0 0 0 .25-.65.88.88 0 0 0-.25-.65.81.81 0 0 0-.61-.25.83.83 0 0 0-.63.25.9.9 0 0 0-.23.65c0 .26.08.48.23.65.17.17.38.25.63.25Zm-4.15-4.89c.37 0 .68.05.93.15.26.1.47.24.65.42a1.94 1.94 0 0 1 .57 1.4 1.94 1.94 0 0 1-1.22 1.82c-.25.1-.56.15-.93.15h-.66V19h-1.4v-5.97h2.06Zm-.2 2.72c.2 0 .33-.02.44-.06.1-.04.2-.1.26-.16.14-.13.22-.3.22-.53 0-.22-.08-.4-.22-.53a.74.74 0 0 0-.26-.15c-.1-.04-.25-.06-.43-.06h-.47v1.49h.47Z"
      fill="#000"
    />
    <path
      d="M14.38 14.84c0 1-.92 2.16-2.32 2.16h-1.34l-.07.41-.3 1.99H8.66l1-6.4h2.71c.91 0 1.63.5 1.9 1.2.07.2.1.42.1.64Z"
      fill="#002991"
    />
    <path
      d="M15.34 16.68a2.3 2.3 0 0 1-2.28 1.92h-.93l-.4 2.4h-1.65l.26-1.6.32-1.99.06-.41h1.34a2.3 2.3 0 0 0 2.32-2.16c.69.35 1.09 1.06.96 1.84Z"
      fill="#60CDFF"
    />
    <path d="M14.38 14.84a2.21 2.21 0 0 0-1.01-.24H11.1l-.38 2.4h1.34a2.3 2.3 0 0 0 2.32-2.16Z" fill="#008CFF" />
  </svg>
)

export const IconPaymentCardPayPal = (props: IconCommonProps) => <Icon Svg={PaymentCardPayPal} {...props} />
