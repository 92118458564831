import { Flex } from 'cdk'
import { SPACING_3XS } from 'design-tokens'
import { IconChevronLineLeft } from 'icons'
import { type PropsWithChildren } from 'react'
import { Text } from '../Text'

type TopBarBackProps = PropsWithChildren

export const TopBarBack = ({ children }: TopBarBackProps) => (
  <Flex $align="center" $direction="row" $gap={SPACING_3XS} as={Text} colorName="primary" ellipsis forwardedAs="div">
    <IconChevronLineLeft size={24} />
    {children}
  </Flex>
)
