import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisTherapySolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M11.3 7.25a.7.7 0 0 1 .7-.7h5.326c.932 0 1.784.03 2.333.054a2.305 2.305 0 0 1 1.55.674c.51.519 1.335 1.455 2.01 2.686.37.677.37 1.495 0 2.172a12.503 12.503 0 0 1-2.01 2.686 2.304 2.304 0 0 1-1.55.674c-.549.024-1.4.054-2.333.054H12a.7.7 0 0 1-.7-.7v-7.6Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.34 1.38c.55-.025 1.402-.055 2.334-.055H12a.7.7 0 0 1 .7.7v7.6a.7.7 0 0 1-.7.7H6.674c-.932 0-1.784-.03-2.333-.054a2.305 2.305 0 0 1-1.55-.674A12.502 12.502 0 0 1 .78 6.911a2.258 2.258 0 0 1 0-2.172 12.503 12.503 0 0 1 2.01-2.686 2.304 2.304 0 0 1 1.55-.674Z"
      fill={color}
      fillRule="evenodd"
    />
    <path d="M12.998 20.975h3.275a1 1 0 1 1 0 2h-8.55a1 1 0 1 1 0-2h3.275V2.333a1 1 0 1 1 2 0v18.642Z" fill={color} />
  </svg>
)

export const IconSerenisTherapySolid = (props: IconCommonProps) => <Icon Svg={SerenisTherapySolid} {...props} />
