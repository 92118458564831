import { COLOR_BLACK, COLOR_MENTAL, COLOR_WHITE } from 'design-tokens'
import { Svg } from './components/Svg'

type Props = {
  size?: number
}

export const PathSexology = ({ size = 164 }: Props) => (
  <Svg fill="none" height={size} viewBox="0 0 164 164" width={size}>
    <g clipPath="url(#path-sexology-a)">
      <path
        d="m85.76 57.18 8.21 5.18 8.2-5.19 2.14 9.47 9.47 2.12-5.18 8.21 5.2 8.2-9.47 2.14-2.13 9.47L94 91.6l-8.2 5.2-2.15-9.47-9.47-2.13 5.18-8.2-5.19-8.2 9.47-2.15 2.12-9.47Z"
        fill="url(#path-sexology-b)"
      />
      <path
        d="M97.9 86.03c-5.38-8.97 2.57-12.81 11.54-10.25l117.93 58.96-14.1 37.18-69.22-41.02c-9.23 3.07-12.39-3-12.81-6.41-3.08 1.03-7.27-.43-8.98-1.28-8.97.43-27.17-.77-28.2-8.97-1.28-10.26 17.95-5.13 17.95-6.41 0-1.03 3.41-8.12 5.12-11.54 0 0-15.38-3.85-19.22-10.26Z"
        fill={COLOR_WHITE}
      />
      <path
        d="m213.27 171.92-69.22-41.02c-9.23 3.07-12.39-3-12.81-6.41-3.08 1.03-7.27-.43-8.98-1.28-8.97.43-27.17-.77-28.2-8.97-1.28-10.26 17.95-5.13 17.95-6.41 0-1.03 3.41-8.12 5.12-11.54 0 0-15.38-3.85-19.22-10.26-5.39-8.97 2.56-12.81 11.53-10.25l117.93 58.96"
        stroke={COLOR_BLACK}
        strokeWidth="1.18"
      />
      <path d="M112 107.83c-1.7 4.27-2.4 13.24 7.86 13.24" stroke={COLOR_BLACK} strokeWidth="1.18" />
      <path
        d="M131.24 100.14c-5.56 5.98-15.13 18.97-8.98 23.07M141.49 107.83c-4.7 2.56-10.31 5.96-10.31 16.47"
        stroke={COLOR_BLACK}
        strokeWidth="1.18"
      />
      <path
        d="M96.47 68.88c5.38 8.98-2.57 12.82-11.54 10.26L-33 20.17-18.9-17l69.22 41.02c9.23-3.08 12.4 2.99 12.82 6.4 3.07-1.02 7.26.44 8.97 1.29 8.97-.43 27.18.77 28.2 8.97 1.28 10.26-17.94 5.13-17.94 6.41 0 1.03-3.42 8.12-5.13 11.54 0 0 15.38 3.84 19.23 10.25Z"
        fill="url(#path-sexology-c)"
      />
      <path
        d="m-18.9-17 69.22 41.02c9.23-3.08 12.4 2.99 12.82 6.4 3.07-1.02 7.26.44 8.97 1.29 8.97-.43 27.18.77 28.2 8.97 1.28 10.26-17.94 5.13-17.94 6.41 0 1.03-3.42 8.12-5.13 11.54 0 0 15.38 3.84 19.23 10.25 5.38 8.98-2.57 12.82-11.54 10.26L-33 20.17"
        stroke="url(#path-sexology-d)"
        strokeWidth="1.14"
      />
      <path d="M82.37 47.1C84.07 42.81 84.25 34 74 34" stroke={COLOR_BLACK} strokeWidth="1.18" />
      <path
        d="M63.14 54.78c5.55-5.98 15.12-18.97 8.97-23.07M52.88 47.1c4.7-2.57 10.32-5.98 10.32-16.48"
        stroke={COLOR_BLACK}
        strokeWidth="1.18"
      />
    </g>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-sexology-b" x1="74.18" x2="113.78" y1="85.2" y2="68.76">
        <stop stopColor={COLOR_MENTAL} />
        <stop offset="1" stopColor={COLOR_MENTAL} stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-sexology-c" x1="8" x2="75.5" y1="22.5" y2="64.5">
        <stop stopColor={COLOR_WHITE} stopOpacity="0" />
        <stop offset="1" stopColor={COLOR_WHITE} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-sexology-d" x1="100" x2="11" y1="73" y2="22.5">
        <stop offset=".7" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <clipPath id="path-sexology-a">
        <path d="M0 0h164v164H0z" fill={COLOR_WHITE} />
      </clipPath>
    </defs>
  </Svg>
)
