// src/lib/assertNever.ts
var assertNever = (x) => {
  throw new Error(`Unexpected object: ${JSON.stringify(x)}`);
};

// src/lib/delayPromise.ts
var delayPromise = (callback, ms) => new Promise((resolve) => {
  setTimeout(resolve, ms);
}).then(callback);

// src/lib/identity.ts
var identity = (arg) => arg;

// src/lib/isNeitherNullNorUndefined.ts
var isNeitherNullNorUndefined = (value) => value !== null && value !== void 0;

// src/lib/isObject.ts
var isObject = (value) => typeof value === "object" && value != null && !Array.isArray(value);

// src/lib/isOfExactType.ts
var isOfExactType = (value) => value !== true && value !== false && isNeitherNullNorUndefined(value);

// src/lib/noop.ts
function noop(..._args) {
}

// src/lib/sequence.ts
var sequence = (callbacks) => callbacks.reduce(
  (accumulator, callback, index) => accumulator.then(async (result) => result.concat(await callback(index))),
  Promise.resolve([])
);
export {
  assertNever,
  delayPromise,
  identity,
  isNeitherNullNorUndefined,
  isObject,
  isOfExactType,
  noop,
  sequence
};
