export { default as Icon } from './components/Icon'

export * from './types'

export * from './components/AddFilledSolid'
export * from './components/AlertSolid'
export * from './components/AmericanExpress'
export * from './components/ArrowLeftToLine'
export * from './components/ArrowRightToLine'
export * from './components/Card'
export * from './components/CheckmarkFilledSolid'
export * from './components/ChevronLeftCircle'
export * from './components/ChevronLineLeft'
export * from './components/ChevronLineRight'
export * from './components/ChevronRightCircle'
export * from './components/Copy'
export * from './components/DeleteCircle'
export * from './components/Document'
export * from './components/DocumentEdit'
export * from './components/Icon'
export * from './components/Info'
export * from './components/Intercom'
export * from './components/Linkedin'
export * from './components/LogoApple'
export * from './components/LogoFacebook'
export * from './components/LogoGoogle'
export * from './components/PathCoaching'
export * from './components/PathCoachingSolid'
export * from './components/PathCouples'
export * from './components/PathCouplesSolid'
export * from './components/PathMyself'
export * from './components/PathMyselfSolid'
export * from './components/PathNutritionDca'
export * from './components/PathNutritionDcaSolid'
export * from './components/PathNutritionWeightLoss'
export * from './components/PathNutritionWeightLossSolid'
export * from './components/PathPsychiatry'
export * from './components/PathPsychiatrySolid'
export * from './components/PathSexology'
export * from './components/PathSexologySolid'
export * from './components/PaymentCardAmericanExpress'
export * from './components/PaymentCardApplePay'
export * from './components/PaymentCardGooglePay'
export * from './components/PaymentCardMaestro'
export * from './components/PaymentCardMastercard'
export * from './components/PaymentCardPayPal'
export * from './components/PaymentCardVisa'
export * from './components/PenTool'
export * from './components/Placeholder'
export * from './components/PostePay'
export * from './components/PoweredByStripe'
export * from './components/QuestionMarkCircle'
export * from './components/RemoveInline'
export * from './components/ReportsExcellent'
export * from './components/ReportsGood'
export * from './components/ReportsPoor'
export * from './components/SerenisAgenda'
export * from './components/SerenisAgendaSolid'
export * from './components/SerenisBlackLivesMatter'
export * from './components/SerenisCalendar'
export * from './components/SerenisCalendarPlus'
export * from './components/SerenisCalendarSolid'
export * from './components/SerenisChat'
export * from './components/SerenisChatSolid'
export * from './components/SerenisCheck'
export * from './components/SerenisClock'
export * from './components/SerenisClose'
export * from './components/SerenisCode'
export * from './components/SerenisCommunity'
export * from './components/SerenisContactPhonebook'
export * from './components/SerenisDeadline'
export * from './components/SerenisGeneric'
export * from './components/SerenisGift'
export * from './components/SerenisHome'
export * from './components/SerenisHomeSolid'
export * from './components/SerenisJournaling'
export * from './components/SerenisJournalingSolid'
export * from './components/SerenisLeft'
export * from './components/SerenisLink'
export * from './components/SerenisMenu'
export * from './components/SerenisMenuSolid'
export * from './components/SerenisMoneyBox'
export * from './components/SerenisNotification'
export * from './components/SerenisNotificationOff'
export * from './components/SerenisOff'
export * from './components/SerenisPatients'
export * from './components/SerenisPatientsSolid'
export * from './components/SerenisQuestion'
export * from './components/SerenisRight'
export * from './components/SerenisShippingBox'
export * from './components/SerenisSidebarCollapse'
export * from './components/SerenisSidebarExpand'
export * from './components/SerenisSmartphone'
export * from './components/SerenisSticker'
export * from './components/SerenisStickerSquare'
export * from './components/SerenisSupport'
export * from './components/SerenisTherapy'
export * from './components/SerenisTherapySolid'
export * from './components/SerenisTicket'
export * from './components/SerenisTickets'
export * from './components/SerenisTicketsSolid'
export * from './components/SerenisXMarkCircle'
export * from './components/Whatsapp'
export * from './components/X'

/**
 * ICONS FROM ICONOIR
 */

export {
  Alarm,
  Archive,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Attachment,
  BadgeCheck,
  Bell,
  BinMinusIn,
  BookStack,
  Brain,
  Building,
  Calendar,
  ChatBubbleQuestion,
  ChatLines,
  Check,
  CheckCircle,
  Community,
  Computer,
  Copy,
  CreditCard,
  CreditCardSlash,
  DoubleCheck,
  Download,
  Drag,
  Edit,
  EditPencil,
  Expand,
  Eye,
  EyeClosed,
  FacebookTag,
  FastRightCircle,
  Finder,
  Folder,
  Gift,
  GoogleDocs,
  GraduationCap,
  Group,
  Home,
  HomeHospital,
  Hospital,
  Hourglass,
  Infinite,
  InfoCircle,
  JournalPage,
  Language,
  Laptop,
  Link,
  LogOut,
  Mail,
  MapPin,
  MediaImage,
  Menu,
  Microphone,
  MicrophoneMute,
  Minus,
  MinusCircle,
  MoreHoriz,
  MoreVert,
  MultiplePagesPlus,
  MultiWindow,
  NavArrowDown,
  NavArrowLeft,
  NavArrowRight,
  NavArrowUp,
  OffTag,
  OnTag,
  OpenInWindow,
  PageEdit,
  PageFlip,
  PagePlus,
  PasteClipboard,
  Paypal,
  PcCheck,
  PeaceHand,
  PeopleTag,
  PercentageCircle,
  PharmacyCrossTag,
  Phone,
  Plus,
  PrivacyPolicy,
  QuestionMark,
  Reduce,
  Repeat,
  Reports,
  Restart,
  Search,
  Send,
  SendDiagonal,
  SendMail,
  Settings,
  ShareIos as Share,
  SoundHigh,
  SoundOff,
  Sphere,
  Star,
  StarSolid,
  StatsUpSquare,
  Timer,
  TimerOff,
  TransitionLeft,
  TransitionRight,
  Trash,
  User,
  UserBadgeCheck,
  UserCircle,
  UserStar,
  UserXmark,
  VideoCamera,
  VideoCameraOff,
  ViewStructureUp,
  WarningCircle,
  WarningCircleSolid,
  Xmark,
  XmarkCircle,
} from 'iconoir-react'
