import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const X = ({ height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7512 3H20.818L14.1179 10.6246L22 21H15.8284L10.9946 14.7074L5.4636 21H2.39492L9.56132 12.8446L2 3H8.32824L12.6976 8.7517L17.7512 3ZM16.6748 19.1723H18.3742L7.40492 4.73168H5.58132L16.6748 19.1723Z"
      fill="black"
    />
  </svg>
)

export const IconX = (props: IconCommonProps) => <Icon Svg={X} {...props} />
