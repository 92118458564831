import { COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisRight = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#right-clip-path)">
      <circle cx="12" cy="12" fill={color} fillOpacity=".7" r="12" />
      <path d="m10 17 5-5-5-5" stroke={COLOR_WHITE} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="right-clip-path">
        <path d="M0 0h24v24H0z" fill={COLOR_WHITE} />
      </clipPath>
    </defs>
  </svg>
)

export const IconSerenisRight = (props: IconCommonProps) => <Icon Svg={SerenisRight} {...props} />
