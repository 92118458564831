// src/lib/acronym.ts
var acronym = (text) => text.replace(/[^a-zA-Z]/g, " ").split(/\s/).filter((value) => !!value.trim()).reduce((accumulator, word) => [...accumulator, word.charAt(0)], []).slice(0, 3).join("");

// src/lib/capitalize.ts
var capitalize = (value) => `${value.charAt(0).toUpperCase()}${value.slice(1)}`;

// src/lib/ellipsis.ts
var truncate = (value, max) => {
  if (value.length <= max) {
    return value;
  }
  return value.substring(0, value.lastIndexOf(" ", max));
};
var ellipsis = (value, max) => {
  const truncatedValue = truncate(value, max);
  if (truncatedValue.length < value.length) {
    return `${truncatedValue}...`;
  }
  return truncatedValue;
};

// src/lib/fillTextValues.ts
var fillTextValues = ({ text, values }) => text.replace(/\{([a-zA-Z]+)\}/g, (match, key) => String(values[key] || match));

// src/lib/title.ts
var title = (value) => `${value.split(" ").map(capitalize).join(" ").trim()}`;

// src/lib/fullName.ts
var fullName = ({ firstName, lastName }) => `${title(firstName)} ${capitalize(lastName)}`;

// src/lib/guessFirstName.ts
import { pipe } from "fp-ts/function";
var guessFirstName = (fullName2) => pipe(
  fullName2,
  (v) => v.trim(),
  (v) => v.split(" ")[0],
  capitalize
);

// src/lib/listWithConjunction.ts
var listWithConjuction = ({ items, conjuction }) => {
  switch (items.length) {
    case 0:
      return "";
    case 1:
      return items[0];
    case 2:
      return items.join(` ${conjuction} `);
    default: {
      const lastItemIndex = items.length - 1;
      const firstItems = items.slice(0, lastItemIndex);
      const lastItem = items[lastItemIndex];
      return `${firstItems.join(", ")} ${conjuction} ${lastItem}`;
    }
  }
};

// src/lib/sanitizeName.ts
var LOWER_CASE_PARTICLES = ["de", "da", "do", "dos", "das", "di", "du", "del", "der", "van", "von", "la", "le"];
var SPECIAL_PREFIXES = ["Mc", "Mac", "O'"];
var SPECIAL_SEPARATORS = ["-", "'"];
var capitalizeWord = (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
var capitalizeWordWithSpecialCases = (word) => {
  const lowerCaseWord = word.toLowerCase();
  const separator = SPECIAL_SEPARATORS.find((separator2) => lowerCaseWord.includes(separator2));
  if (separator) {
    return lowerCaseWord.split(separator).map(capitalizeWord).join(separator);
  }
  for (const prefix of SPECIAL_PREFIXES) {
    if (lowerCaseWord.startsWith(prefix.toLowerCase())) {
      return prefix + capitalizeWord(word.slice(prefix.length));
    }
  }
  return capitalizeWord(lowerCaseWord);
};
var sanitizeName = (name) => {
  const words = name.trim().split(/\s+/);
  const sanitizedWords = words.map((word, index) => {
    if (index === 0) {
      return capitalizeWordWithSpecialCases(word);
    }
    if (LOWER_CASE_PARTICLES.includes(word.toLowerCase())) {
      return word.toLowerCase();
    }
    return capitalizeWordWithSpecialCases(word);
  });
  return sanitizedWords.join(" ");
};

// src/lib/shortName.ts
var shortName = ({ firstName, lastName }) => `${title(firstName)} ${lastName.charAt(0).toUpperCase()}.`;

// src/lib/constants/specialChars.ts
var specialToNormalChars = {
  \u00E0: "a",
  \u00E1: "a",
  \u00E2: "a",
  \u00E4: "a",
  \u00E6: "a",
  \u00E3: "a",
  \u00E5: "a",
  \u0101: "a",
  \u0103: "a",
  \u0105: "a",
  \u00E7: "c",
  \u0107: "c",
  \u010D: "c",
  \u0111: "d",
  \u010F: "d",
  \u00E8: "e",
  \u00E9: "e",
  \u00EA: "e",
  \u00EB: "e",
  \u0113: "e",
  \u0117: "e",
  \u0119: "e",
  \u011B: "e",
  \u011F: "g",
  \u01F5: "g",
  \u1E27: "h",
  \u00EE: "i",
  \u00EF: "i",
  \u00ED: "i",
  \u012B: "i",
  \u012F: "i",
  \u00EC: "i",
  \u0142: "l",
  \u1E3F: "m",
  \u00F1: "n",
  \u0144: "n",
  \u01F9: "n",
  \u0148: "n",
  \u00F4: "o",
  \u00F6: "o",
  \u00F2: "o",
  \u00F3: "o",
  \u0153: "o",
  \u00F8: "o",
  \u014D: "o",
  \u00F5: "o",
  \u0151: "o",
  \u1E55: "p",
  \u0155: "r",
  \u0159: "r",
  \u00DF: "s",
  \u015B: "s",
  \u0161: "s",
  \u015F: "s",
  \u0219: "s",
  \u0165: "t",
  \u021B: "t",
  \u00FB: "u",
  \u00FC: "u",
  \u00F9: "u",
  \u00FA: "u",
  \u016B: "u",
  \u01D8: "u",
  \u016F: "u",
  \u0171: "u",
  \u0173: "u",
  \u1E83: "w",
  \u1E8D: "x",
  \u00FF: "y",
  \u00FD: "y",
  \u017E: "z",
  \u017A: "z",
  \u017C: "z",
  "\xB7": "-",
  "/": "-",
  _: "-",
  ",": "-",
  ":": "-",
  ";": "-"
};
var specialCharsRegex = new RegExp(Object.keys(specialToNormalChars).join("|"), "g");

// src/lib/slugify.ts
var slugify = (text) => text.toLowerCase().replace(/\s+/g, "-").replace(specialCharsRegex, (value) => specialToNormalChars[value]).replace(/&/g, "-and-").replace(/[^\w-]+/g, "-").replace(/--+/g, "-").replace(/^-+/, "").replace(/-+$/, "");

// src/lib/snake.ts
var snake = (text) => text.toLowerCase().replace(/\s+/g, "_").replace(specialCharsRegex, (value) => specialToNormalChars[value]).replace(/&/g, "_and_").replace(/[^\w_]+/g, "_").replace(/__+/g, "_").replace(/^_+/, "").replace(/_+$/, "");
export {
  acronym,
  capitalize,
  ellipsis,
  fillTextValues,
  fullName,
  guessFirstName,
  listWithConjuction,
  sanitizeName,
  shortName,
  slugify,
  snake,
  title
};
