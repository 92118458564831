import * as Sentry from '@sentry/nextjs'
import { usePathname, useSearchParams } from 'next/navigation'
import { useMemo } from 'react'
import { parseQueryParams } from 'url-parser'
import { getSessionStorageQueryParams } from '~/utils/getSessionStorageQueryParams'

export const getDefaultSessionStorage = (path: string) => {
  try {
    const queryParams = parseQueryParams(path)

    const defaultLastLandingReferrer = {
      lastLandingReferrer: document?.referrer == null || document.referrer === '' ? null : document.referrer,
    }

    return {
      ...queryParams,
      ...defaultLastLandingReferrer,
    }
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'utils',
      },
    })

    return {}
  }
}

export const useSessionStorageParams = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const asPath = searchParams.toString() !== '' ? `${pathname}?${searchParams.toString()}` : pathname

  return useMemo(
    () => (getSessionStorageQueryParams() ?? getDefaultSessionStorage(asPath)) as Record<string, unknown>,
    [asPath],
  )
}
