import { COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const AlertSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" fill={color} r="12" />
    <path d="M13.08 6v7.38h-2.16V6h2.16Zm-2.32 12v-2.42h2.5V18h-2.5Z" fill={COLOR_WHITE} />
  </svg>
)

export const IconAlertSolid = (props: IconCommonProps) => <Icon Svg={AlertSolid} {...props} />
