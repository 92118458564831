import { BORDER_RADIUS_CIRCLE, COLOR_RED_60, SPACING_3XS, SPACING_4XS } from 'design-tokens'
import { type PropsWithChildren, type ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Text } from './Text'
import { shouldForwardProp } from './utils/shouldForwardProp'

export type BadgeProps = PropsWithChildren<{
  sup?: ReactNode
}>

const OVERFLOW_AMOUNT = 99

const badgeSupCss = css`
  &:not(:only-child) {
    position: absolute;
    top: ${SPACING_3XS};
    right: ${SPACING_3XS};
    transform: translate(50%, -50%);
    transform-origin: 100% 0%;
    inset-inline-end: 0;
  }
`

const BadgeContainer = styled.div.withConfig({ displayName: 'Badge', shouldForwardProp })`
  position: relative;
  display: inline-flex;
`

const BadgeSup = styled.sup.withConfig({ displayName: 'BadgeSup', shouldForwardProp })`
  ${badgeSupCss}
`

const BadgeSupAmount = styled(Text).withConfig({ displayName: 'BadgeSupAmount', shouldForwardProp })`
  ${badgeSupCss}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  min-width: fit-content;
  height: 16px;
  padding: 0 ${SPACING_4XS};
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_RED_60};
`

export const Badge = ({ children, sup }: BadgeProps) => {
  const supAsNumber = Number(sup)

  return (
    <BadgeContainer>
      {children}
      {isNaN(supAsNumber) ? (
        <BadgeSup>{sup}</BadgeSup>
      ) : supAsNumber ? (
        <BadgeSupAmount as="sup" colorName="white" fontWeight="600" kind="micro" title={`${supAsNumber}`}>
          {supAsNumber > OVERFLOW_AMOUNT ? `+${OVERFLOW_AMOUNT}` : supAsNumber}
        </BadgeSupAmount>
      ) : null}
    </BadgeContainer>
  )
}
