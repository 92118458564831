import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const Copy = ({ height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#srns-copy-a)">
      <g clipPath="url(#srns-copy-b)" stroke="#1C1C36" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4">
        <path d="M17.017 20.668a2.353 2.353 0 0 1-2.135 2.153c-1.149.119-2.864.232-5.25.232-2.387 0-4.102-.113-5.25-.232a2.353 2.353 0 0 1-2.136-2.153C2.122 19.386 2 17.366 2 14.368c0-2.997.122-5.018.246-6.3a2.353 2.353 0 0 1 2.136-2.152" />
        <path d="M16.1.968c.323.095 1.589.558 3.455 2.417 1.745 1.74 2.265 2.955 2.403 3.368.026.84.042 1.795.042 2.879 0 2.997-.122 5.017-.246 6.3a2.353 2.353 0 0 1-2.136 2.152c-1.148.12-2.863.232-5.25.232-2.386 0-4.101-.113-5.25-.232a2.353 2.353 0 0 1-2.135-2.152c-.125-1.283-.246-3.303-.246-6.3 0-2.998.121-5.018.246-6.3a2.353 2.353 0 0 1 2.135-2.153c1.149-.119 2.864-.232 5.25-.232.623 0 1.2.008 1.732.021Z" />
        <path d="M21.948 6.915c-.88.133-2.41.047-3.66-.063a2.435 2.435 0 0 1-2.218-2.216c-.11-1.251-.197-2.786-.059-3.658" />
      </g>
    </g>
    <defs>
      <clipPath id="srns-copy-a">
        <path d="M0 0h24v24H0z" fill="#fff" />
      </clipPath>
      <clipPath id="srns-copy-b">
        <path d="M1 0h22v24H1z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
)

export const IconCopy = (props: IconCommonProps) => <Icon Svg={Copy} {...props} />
