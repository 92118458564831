import { COLOR_GREY_40, COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PaymentCardGooglePay = ({ width }: Props) => (
  <svg fill="none" viewBox="0 0 50 34" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z" fill={COLOR_WHITE} />
    <path
      d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z"
      stroke={COLOR_GREY_40}
      strokeWidth=".5"
    />
    <path
      d="M23.98 20.37h-1.12v-8.73h2.98c.75 0 1.4.25 1.92.75.54.5.8 1.12.8 1.84 0 .75-.26 1.36-.8 1.86-.52.5-1.16.74-1.92.74h-1.86v3.54Zm0-7.66v3.05h1.88c.44 0 .82-.15 1.11-.45a1.47 1.47 0 0 0 0-2.13c-.3-.3-.66-.46-1.11-.46h-1.88ZM31.5 14.2c.83 0 1.49.22 1.97.67.48.44.72 1.05.72 1.82v3.68h-1.07v-.83h-.05a2.14 2.14 0 0 1-1.85 1.03 2.4 2.4 0 0 1-1.66-.59c-.44-.39-.67-.87-.67-1.46 0-.62.24-1.11.7-1.47.47-.37 1.1-.55 1.88-.55.66 0 1.21.12 1.64.37v-.26c0-.4-.15-.72-.46-1-.31-.27-.67-.4-1.09-.4a1.7 1.7 0 0 0-1.48.79l-1-.62a2.74 2.74 0 0 1 2.42-1.18Zm-1.45 4.34c0 .29.13.54.38.73.24.2.54.3.87.3.47 0 .9-.18 1.26-.53.37-.36.56-.77.56-1.24a2.31 2.31 0 0 0-1.46-.41c-.46 0-.84.1-1.15.32-.3.23-.46.5-.46.83ZM40.3 14.4 36.54 23h-1.16l1.4-3.01-2.47-5.6h1.22l1.78 4.3h.02l1.73-4.3h1.22Z"
      fill="#3C4043"
    />
    <path
      d="M19.52 16.12c0-.36-.04-.71-.1-1.05h-4.7V17h2.7a2.32 2.32 0 0 1-1 1.55v1.26h1.61a4.9 4.9 0 0 0 1.49-3.69Z"
      fill="#4285F4"
    />
    <path
      d="M16.42 18.55c-.45.3-1.03.48-1.7.48a3 3 0 0 1-2.82-2.06h-1.66v1.29a5.01 5.01 0 0 0 4.48 2.76c1.35 0 2.49-.45 3.31-1.21l-1.6-1.26Z"
      fill="#34A853"
    />
    <path
      d="M11.75 16c0-.32.05-.65.15-.95v-1.3h-1.66a4.98 4.98 0 0 0 0 4.5l1.66-1.28c-.1-.3-.15-.63-.15-.96Z"
      fill="#FABB05"
    />
    <path
      d="M14.72 12.98c.74 0 1.4.26 1.92.75l1.43-1.42a5.01 5.01 0 0 0-7.83 1.45l1.66 1.29a3 3 0 0 1 2.82-2.07Z"
      fill="#E94235"
    />
  </svg>
)

export const IconPaymentCardGooglePay = (props: IconCommonProps) => <Icon Svg={PaymentCardGooglePay} {...props} />
