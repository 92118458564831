import { Flex } from 'cdk'
import { COLOR_DARKER, COLOR_NEUTRAL_70 } from 'design-tokens'
import { type HTMLProps } from 'react'
import styled from 'styled-components'
import { Text } from '../Text'

const BreadcrumbContainer = styled(Flex)`
  &:last-child {
    ${Text} {
      color: ${COLOR_DARKER};
    }
  }

  ${Text} {
    color: ${COLOR_NEUTRAL_70};
  }

  a${Text} {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`
/**
 * `kind` is a valid attribute for html tag, but is used in `Text` as strict type prop
 * TODO(@heavybeard): replace `kind` with `$kind` in `Text` component
 * @see https://linear.app/serenis/issue/ENG-1616/text-styled-component-should-use-dollar-as-prefix-for-props
 */
export type BreadcrumbProps = Omit<HTMLProps<HTMLElement>, 'kind'>

export const Breadcrumb = ({ children, label, title, ...props }: BreadcrumbProps) => (
  <BreadcrumbContainer $align="center" $direction="row" $gap={4} $shrink={1} as="li" data-testid="breadcrumb">
    <Text ellipsis kind="caption" title={title ?? label} {...props}>
      {children ?? label}
    </Text>
  </BreadcrumbContainer>
)
