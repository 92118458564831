import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const RemoveInline = ({ color, width, height }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5625 12c0 2.2378.88895 4.3839 2.47129 5.9662C7.61612 19.5486 9.76224 20.4375 12 20.4375c2.2378 0 4.3839-.8889 5.9662-2.4713 1.5824-1.5823 2.4713-3.7284 2.4713-5.9662 0-2.23776-.8889-4.38388-2.4713-5.96621C16.3839 4.45145 14.2378 3.5625 12 3.5625c-2.23776 0-4.38388.88895-5.96621 2.47129C4.45145 7.61612 3.5625 9.76224 3.5625 12Zm5.0625 3.375 6.75-6.75m0 6.75-6.75-6.75"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconRemoveInline = (props: IconCommonProps) => <Icon Svg={RemoveInline} {...props} />
