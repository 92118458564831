import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisAgenda = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.288 22.736a2.662 2.662 0 0 0 2.455-2.489c.125-1.69.257-4.389.257-8.247 0-3.858-.132-6.557-.257-8.247a2.662 2.662 0 0 0-2.455-2.489C17.741 1.132 15.352 1 12 1c-3.352 0-5.741.132-7.288.264a2.662 2.662 0 0 0-2.455 2.489C2.132 5.443 2 8.142 2 12c0 3.858.132 6.556.257 8.247a2.662 2.662 0 0 0 2.455 2.489C6.259 22.867 8.648 23 12 23c3.352 0 5.741-.133 7.288-.264Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M9.572 6.959c.052-.453.583-.805 1.393-.866C11.628 6.043 12.603 6 14 6c1.397 0 2.372.043 3.035.093.81.061 1.341.413 1.393.866.04.344.072.84.072 1.541 0 .7-.032 1.197-.072 1.541-.052.453-.583.804-1.393.866A40.84 40.84 0 0 1 14 11a40.84 40.84 0 0 1-3.035-.093c-.81-.062-1.341-.413-1.393-.866-.04-.344-.072-.84-.072-1.541 0-.7.032-1.197.072-1.541Z"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path d="M6 1.5v21" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" />
  </svg>
)

export const IconSerenisAgenda = (props: IconCommonProps) => <Icon Svg={SerenisAgenda} {...props} />
