import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisLink = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4471 13.2502C10.8497 13.7885 11.3634 14.2338 11.9532 14.5561C12.5431 14.8784 13.1954 15.07 13.8658 15.118C14.5363 15.166 15.2092 15.0693 15.839 14.8344C16.4687 14.5995 17.0406 14.2318 17.5158 13.7565L20.3283 10.944C21.1822 10.0599 21.6547 8.87582 21.644 7.64677C21.6333 6.41772 21.1403 5.24204 20.2712 4.37294C19.4021 3.50384 18.2264 3.01086 16.9974 3.00018C15.7684 2.9895 14.5843 3.46197 13.7002 4.31584L12.0877 5.91896M14.1969 11.3749C13.7943 10.8367 13.2806 10.3913 12.6908 10.069C12.1009 9.74675 11.4486 9.55511 10.7782 9.50709C10.1077 9.45908 9.43479 9.55581 8.80502 9.79073C8.17524 10.0257 7.60336 10.3933 7.12815 10.8687L4.31565 13.6812C3.46179 14.5652 2.98931 15.7493 2.99999 16.9783C3.01067 18.2074 3.50365 19.3831 4.37275 20.2522C5.24185 21.1213 6.41754 21.6143 7.64659 21.6249C8.87563 21.6356 10.0597 21.1631 10.9438 20.3093L12.5469 18.7062"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisLink = (props: IconCommonProps) => <Icon Svg={SerenisLink} {...props} />
