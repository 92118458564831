import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisCalendarSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.137 22.743c-1.277-.098-2.255-1.03-2.359-2.246-.13-1.538-.27-3.99-.27-7.497 0-.523.004-1.023.01-1.5H22.5c.006.477.009.977.009 1.5 0 3.507-.139 5.96-.27 7.497-.104 1.216-1.081 2.148-2.359 2.246-1.614.125-4.19.257-7.871.257-3.682 0-6.258-.132-7.872-.257ZM19.957 3.257c1.278.099 2.256 1.03 2.36 2.246.065.766.132 1.76.183 2.997H1.672c.051-1.238.118-2.23.184-2.997.103-1.216 1.081-2.147 2.359-2.246C5.829 3.132 8.405 3 12.086 3c3.682 0 6.257.132 7.871.257Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path d="M7.586 5.503V1m9 4.503V1" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
)

export const IconSerenisCalendarSolid = (props: IconCommonProps) => <Icon Svg={SerenisCalendarSolid} {...props} />
