import { cssvarColor } from 'design-tokens'
import { type IconProps } from '../types'

function Icon({ Svg, colorName, fillColorName, size }: IconProps): JSX.Element | null {
  if (Svg == null) {
    return null
  }

  return (
    <Svg
      color={colorName ? cssvarColor(colorName) : 'currentColor'}
      fill={fillColorName ? cssvarColor(fillColorName) : 'none'}
      height={size}
      width={size}
    />
  )
}

export default Icon
