// src/constants.ts
var authenticatedUserGroups = [
  "bi",
  "coach",
  "engineering",
  "finance",
  "marketing",
  "operations",
  "patient",
  "saas-therapist",
  "sexologist",
  "therapist"
];

// src/enums.ts
var Action = /* @__PURE__ */ ((Action2) => {
  Action2["Create"] = "create";
  Action2["Delete"] = "delete";
  Action2["Manage"] = "manage";
  Action2["Read"] = "read";
  Action2["Update"] = "update";
  return Action2;
})(Action || {});

// src/utils/authenticatedUserHasGroup.ts
import { some } from "fp-ts/Array";
import { pipe } from "fp-ts/function";
var authenticatedUserHasGroup = (groups) => (user) => pipe(
  groups,
  some((group) => user.groups.includes(group))
);

// src/utils/toAuthenticatedUserGroups.ts
var toAuthenticatedUserGroups = (groups) => {
  const parsed = authenticatedUserGroups.filter((g) => groups.includes(g));
  if (!parsed.length) {
    return ["patient"];
  }
  return parsed;
};
export {
  Action,
  authenticatedUserGroups,
  authenticatedUserHasGroup,
  toAuthenticatedUserGroups
};
