import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisGeneric = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2873 20.9964c.6512.0181 1.3632.0285 2.1375.0285 2.9754 0 5.0188-.152 6.2928-.2978 1.0459-.1197 1.8287-.9039 1.9442-1.9499.151-1.3727.3135-3.677.3135-7.2523 0-1.3651-.0238-2.54455-.0618-3.55773-.1662-.4655-.7348-1.6948-2.5023-3.4276-1.8729-1.83635-3.1692-2.35267-3.572-2.47855-.8045-.02467-1.6095-.03671-2.4144-.0361-2.9759 0-5.01933.152-6.2928.29783-1.04548.1197-1.82875.90392-1.94418 1.95035-.10212.92767-.209 2.28095-.2679 4.16432"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M15.7998 2.26221c0 1.16232.0394 2.32465.0865 3.29365.0302.66853.3094 1.30158.7826 1.77478.4732.47321 1.1062.75236 1.7748.78262.969.04702 2.1313.08645 3.2936.08645M5.9655 18.3044v-2.2216c0-1.0388-.88207-1.881-1.9703-1.881-1.08775 0-1.9703.8422-1.9703 1.881v2.2648c0 2.0026 1.70145 3.6271 3.8 3.6271s3.8-1.624 3.8-3.6271v-4.1458c0-2.0026-1.70145-3.6271-3.8-3.6271-1.29152 0-2.432.6147-3.11885 1.5547"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisGeneric = (props: IconCommonProps) => <Icon Svg={SerenisGeneric} {...props} />
