import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisBlackLivesMatter = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.80304 23.3586v-4.299C4.52787 18.2701 3 15.6205 3 12.5093c0-1.8552.41718-3.794 2.47716-3.794h5.22104c1.0707 0 1.9384.868 1.9384 1.9388 0 .854-.5589 1.6074-1.3761 1.8553l-2.98499.9053c1.52715 0 3.40039 1.4484 3.36009 3.3244M17.1207 23.3593v-4.2999s1.0244-.5121 2.0947-1.5364c2.0613-1.9727 2.33-5.0924 1.7724-7.89059-.186-.93303-.4519-1.87842-.8323-2.67385M4.65173 8.58859V5.2987c0-1.4246 1.15487-2.57946 2.57946-2.57946 1.4246 0 2.57947 1.15486 2.57947 2.57946v3.28989"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M9.81073 7.9108V3.58106C9.81073 2.15558 10.9663 1 12.3918 1s2.581 1.15558 2.581 2.58106V7.9108c0 1.35789-1.0485 2.4709-2.3801 2.5734M14.973 5.29966c0-1.42512 1.1552-2.58042 2.5803-2.58042 1.4252 0 2.5804 1.1553 2.5804 2.58042v2.21466c0 1.42512-1.1552 2.58038-2.5804 2.58038-1.4251 0-2.5803-1.15526-2.5803-2.58038V5.29966Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisBlackLivesMatter = (props: IconCommonProps) => <Icon Svg={SerenisBlackLivesMatter} {...props} />
