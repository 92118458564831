import { COLOR_PRIMARY } from 'design-tokens'
import { Svg } from './components/Svg'

type SerenisLogoIconProps = {
  color?: string
}

export const SerenisLogoIcon = ({ color = COLOR_PRIMARY }: SerenisLogoIconProps) => (
  <Svg className="srns-serenis-light" fill="none" height="32" viewBox="0 0 32 32" width="32">
    <g clipPath="url(#a)" fill={color}>
      <path d="m16.097 18.502-4.526-1.171c-.68-.195-4.073-.946-5.08-2.755-1.245-2.46 1.682-4.97 3.705-6.124 3.339-1.902 7.14-3.35 10.55-4.391a77.092 77.092 0 0 1 4.74-1.276l.057-.014c.773-.18.846-1.253.104-1.538a14.265 14.265 0 0 0-2.006-.59C21.586.186 19.066 0 15.993 0 3.2 0 0 3.2 0 15.999c0 4.786.33 8.291 1.676 10.664.576 1.016 1.578.886 1.871.805 5.752-1.615 12.663-3.689 14.94-5.797.22-.206 1.838-2.108-2.39-3.17Z" />
      <path d="M30.213 5.13a.796.796 0 0 0-.85-.403c-3.547.675-16.974 3.79-16.805 7.34.062 1.285 2.04 1.9 3.669 2.337 2.11.615 3.891 1.126 5.968 1.82 1.558.523 3.525 1.508 3.59 3.664.11 3.553-6.485 6.92-8.139 7.8-1.07.57-3.49 1.758-5.427 2.692-.739.356-.527 1.468.291 1.518 1.075.068 2.235.1 3.491.1 12.798 0 15.998-3.201 15.998-16 0-4.921-.474-8.423-1.786-10.87v.003Z" />
    </g>
  </Svg>
)
