// src/lib/endOfWeek.ts
import { endOfWeekWithOptions } from "date-fns/fp";
import { pipe } from "fp-ts/function";
var endOfWeek = (date) => pipe(date, endOfWeekWithOptions({ weekStartsOn: 1 }));

// src/lib/format.ts
import { formatWithOptions } from "date-fns/fp";
import { enUS, it } from "date-fns/locale";
var FormatDateEnum = /* @__PURE__ */ ((FormatDateEnum2) => {
  FormatDateEnum2["ATOM"] = "yyyy-MM-dd'T'HH:mm:ssxxx";
  FormatDateEnum2["DATE_MONTH_NAME"] = "dd MMMM";
  FormatDateEnum2["DATE_MONTH_NAME_YEAR"] = "dd MMMM yyyy";
  FormatDateEnum2["DAY_MONTH_YEAR"] = "dd/MM/yyyy";
  FormatDateEnum2["DAY_OF_MONTH"] = "dd";
  FormatDateEnum2["DAY_OF_WEEK"] = "i";
  FormatDateEnum2["HOURS"] = "HH";
  FormatDateEnum2["HOURS_MINUTES"] = "HH:mm";
  FormatDateEnum2["LONG_DAY_DATE_MONTH_NAME"] = "eeee dd MMMM";
  FormatDateEnum2["MINUTES"] = "mm";
  FormatDateEnum2["MONTH_NAME"] = "MMMM";
  FormatDateEnum2["MONTH_NAME_YEAR"] = "MMMM yyyy";
  FormatDateEnum2["MONTH_NAME_SHORT"] = "MMM";
  FormatDateEnum2["MONTH_YEAR"] = "MM/yyyy";
  FormatDateEnum2["SHORT_DAY_DATE_MONTH_NAME"] = "eee, dd MMMM";
  FormatDateEnum2["WEEK_DAY"] = "EEEE";
  FormatDateEnum2["WEEK_DAY_SHORT"] = "EEE";
  FormatDateEnum2["WEEK_DAY_SHORT_DAY"] = "EEE d";
  FormatDateEnum2["YEAR"] = "yyyy";
  FormatDateEnum2["YEAR_SHORT"] = "yy";
  FormatDateEnum2["YEAR_MONTH"] = "yyyy-MM";
  FormatDateEnum2["YEAR_MONTH_DAY"] = "yyyy-MM-dd";
  FormatDateEnum2["YEAR_MONTH_DAY_NO_HYPHEN"] = "yyyyMMdd";
  return FormatDateEnum2;
})(FormatDateEnum || {});
var fromFormatDateLanguageToDateFnsLocale = {
  en: enUS,
  it
};
var format = (value, locale) => formatWithOptions({ locale: locale && fromFormatDateLanguageToDateFnsLocale[locale], weekStartsOn: 1 }, value);
var toAtom = format("yyyy-MM-dd'T'HH:mm:ssxxx" /* ATOM */);
var toDateMonthName = format("dd MMMM" /* DATE_MONTH_NAME */);
var toDateMonthNameYear = format("dd MMMM yyyy" /* DATE_MONTH_NAME_YEAR */);
var toDayMonthYear = format("dd/MM/yyyy" /* DAY_MONTH_YEAR */);
var toDayOfMonth = format("dd" /* DAY_OF_MONTH */);
var toDayOfWeek = format("i" /* DAY_OF_WEEK */);
var toHours = format("HH" /* HOURS */);
var toHoursMinutes = format("HH:mm" /* HOURS_MINUTES */);
var toLongDayDateMonthName = format("eeee dd MMMM" /* LONG_DAY_DATE_MONTH_NAME */);
var toMinutes = format("mm" /* MINUTES */);
var toMonthName = format("MMMM" /* MONTH_NAME */);
var toMonthNameYear = format("MMMM yyyy" /* MONTH_NAME_YEAR */);
var toMonthNameShort = format("MMM" /* MONTH_NAME_SHORT */);
var toMonthYear = format("MM/yyyy" /* MONTH_YEAR */);
var toWeekDay = format("EEEE" /* WEEK_DAY */);
var toWeekDayShort = format("EEE" /* WEEK_DAY_SHORT */);
var toWeekDayShortDay = format("EEE d" /* WEEK_DAY_SHORT_DAY */);
var toYear = format("yyyy" /* YEAR */);
var toYearShort = format("yy" /* YEAR_SHORT */);
var toYearMonth = format("yyyy-MM" /* YEAR_MONTH */);
var toYearMonthDay = format("yyyy-MM-dd" /* YEAR_MONTH_DAY */);
var toYearMonthDayNoHyphen = format("yyyyMMdd" /* YEAR_MONTH_DAY_NO_HYPHEN */);

// src/lib/formatDuration.ts
import { formatDurationWithOptions } from "date-fns/fp";
var formatDuration = (format2, locale) => formatDurationWithOptions({
  format: format2,
  locale: locale && fromFormatDateLanguageToDateFnsLocale[locale]
});

// src/lib/formatRelative.ts
import { formatRelativeWithOptions } from "date-fns/fp";
var formatRelative = (locale) => formatRelativeWithOptions({ locale: locale && fromFormatDateLanguageToDateFnsLocale[locale], weekStartsOn: 1 });

// src/lib/formatWithTimeZone.ts
import { formatInTimeZone } from "date-fns-tz";
var formatWithTimeZone = (pattern, timeZone = "Europe/Rome", originalDate, locale) => (date) => formatInTimeZone(date, timeZone, pattern, {
  locale: locale && fromFormatDateLanguageToDateFnsLocale[locale],
  originalDate,
  weekStartsOn: 1,
  timeZone
});

// src/lib/isAfterOrEqual.ts
import { isAfter, isEqual } from "date-fns/fp";
import { pipe as pipe2 } from "fp-ts/function";
var isAfterOrEqual = (a) => (b) => pipe2(b, isAfter(a)) || pipe2(b, isEqual(a));

// src/lib/isBeforeOrEqual.ts
import { isBefore, isEqual as isEqual2 } from "date-fns/fp";
import { pipe as pipe3 } from "fp-ts/function";
var isBeforeOrEqual = (a) => (b) => pipe3(b, isBefore(a)) || pipe3(b, isEqual2(a));

// src/lib/isIntervalOverlappingLeft.ts
import { isBefore as isBefore2, isWithinInterval } from "date-fns/fp";
import { pipe as pipe4 } from "fp-ts/function";
var isIntervalOverlappingLeft = (a) => (b) => pipe4(b.end, isWithinInterval(a)) && pipe4(b.start, isBefore2(a.start));

// src/lib/isIntervalOverlappingRight.ts
import { isAfter as isAfter2, isWithinInterval as isWithinInterval2 } from "date-fns/fp";
import { pipe as pipe5 } from "fp-ts/function";
var isIntervalOverlappingRight = (a) => (b) => pipe5(b.start, isWithinInterval2(a)) && pipe5(b.end, isAfter2(a.end));

// src/lib/isIntervalWithinInterval.ts
import { isWithinInterval as isWithinInterval3 } from "date-fns/fp";
import { pipe as pipe6 } from "fp-ts/function";
var isIntervalWithinInterval = (a) => (b) => pipe6(b.start, isWithinInterval3(a)) && pipe6(b.end, isWithinInterval3(a));

// src/lib/isSameInterval.ts
import { isEqual as isEqual3 } from "date-fns/fp";
import { pipe as pipe7 } from "fp-ts/function";
var isSameInterval = (a) => (b) => pipe7(b.start, isEqual3(a.start)) && pipe7(b.end, isEqual3(a.end));

// src/lib/now.ts
import { pipe as pipe8 } from "fp-ts/function";

// src/lib/nowInMilliseconds.ts
var nowInMilliseconds = () => Date.now();

// src/lib/now.ts
var now = () => pipe8(nowInMilliseconds(), format("yyyy-MM-dd'T'HH:mm:ssxxx" /* ATOM */));

// src/lib/setHoursAndMinutes.ts
import { getHours, getMinutes, setHours, setMinutes } from "date-fns/fp";
import { pipe as pipe9 } from "fp-ts/function";
var setHoursAndMinutes = (override) => (base) => pipe9(base, setHours(getHours(override)), setMinutes(getMinutes(override)));

// src/lib/shiftUtcByTimezoneDaylightSavingTime.ts
import { addMilliseconds, parseISO } from "date-fns/fp";
import { fromZonedTime, getTimezoneOffset } from "date-fns-tz";
import { pipe as pipe10 } from "fp-ts/function";
var shiftUtcByTimezoneDaylightSavingTime = (timeZone, referDate) => (date) => fromZonedTime(
  pipe10(
    date,
    formatWithTimeZone("yyyy-MM-dd'T'HH:mm:ssxxx" /* ATOM */, timeZone, referDate),
    parseISO,
    addMilliseconds(getTimezoneOffset(timeZone, referDate))
  ),
  timeZone
);

// src/lib/sortByDate.ts
import { compareAsc, compareDesc } from "date-fns/fp";
var sortByDateAsc = (field) => (a, b) => compareAsc(b[field], a[field]);
var sortByDateDesc = (field) => (a, b) => compareDesc(b[field], a[field]);

// src/lib/startOfWeek.ts
import { startOfWeekWithOptions } from "date-fns/fp";
import { pipe as pipe11 } from "fp-ts/function";
var startOfWeek = (date) => pipe11(date, startOfWeekWithOptions({ weekStartsOn: 1 }));

// src/lib/trimInterval.ts
import { isAfter as isAfter3, isBefore as isBefore3 } from "date-fns/fp";
import { pipe as pipe12 } from "fp-ts/function";
var trimInterval = (a) => (b) => {
  if (pipe12(b.start, isBefore3(a.start)) && pipe12(b.end, isAfter3(a.end))) {
    return { ...b, end: a.end, start: a.start };
  }
  if (pipe12(b.start, isBefore3(a.start))) {
    return { ...b, start: a.start };
  }
  if (pipe12(b.end, isAfter3(a.end))) {
    return { ...b, end: a.end };
  }
  return b;
};
export {
  FormatDateEnum,
  endOfWeek,
  format,
  formatDuration,
  formatRelative,
  formatWithTimeZone,
  fromFormatDateLanguageToDateFnsLocale,
  isAfterOrEqual,
  isBeforeOrEqual,
  isIntervalOverlappingLeft,
  isIntervalOverlappingRight,
  isIntervalWithinInterval,
  isSameInterval,
  now,
  nowInMilliseconds,
  setHoursAndMinutes,
  shiftUtcByTimezoneDaylightSavingTime,
  sortByDateAsc,
  sortByDateDesc,
  startOfWeek,
  toAtom,
  toDateMonthName,
  toDateMonthNameYear,
  toDayMonthYear,
  toDayOfMonth,
  toDayOfWeek,
  toHours,
  toHoursMinutes,
  toLongDayDateMonthName,
  toMinutes,
  toMonthName,
  toMonthNameShort,
  toMonthNameYear,
  toMonthYear,
  toWeekDay,
  toWeekDayShort,
  toWeekDayShortDay,
  toYear,
  toYearMonth,
  toYearMonthDay,
  toYearMonthDayNoHyphen,
  toYearShort,
  trimInterval
};
