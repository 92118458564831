import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisSupport = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 14.282S4 13 6.5 13c1.7635 0 3.7.709 4.997 1.2935.995.448 1.5675.731 1.795 1.7985.1585.7415-.374 1.9775-1.13 2.0385l-.5325.043m0 0c-1.221 0-3.111-.362-3.111-.362m3.111.362c1.6935 0 6.1515-.8045 7.7315-1.1.377-.07.7675-.1215 1.126.0135.451.1705 1.056.582 1.345 1.575.226.776-.3165 1.5225-1.07 1.814-2.21.855-6.8185 2.524-9.1325 2.524C5.5 23 1 21.4615 1 21.4615M19.3649 7.67685c.5886-.91694.78-2.05276.524-3.10942-.5468-2.40608-3.5742-3.38913-5.4599-1.77664-.1494.12666-.2822.27305-.4291.41888-.1468-.14583-.28-.29222-.4291-.41888-1.8857-1.61249-4.91307-.62944-5.45992 1.77664-.25571 1.05638-.06428 2.19248.52399 3.10942 1.02941 1.6072 2.68933 2.85835 4.30853 3.99275.3079.2148.6775.3304 1.0565.3304.3791 0 .7486-.1156 1.0566-.3304 1.6191-1.1342 3.279-2.38555 4.3084-3.99275Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisSupport = (props: IconCommonProps) => <Icon Svg={SerenisSupport} {...props} />
