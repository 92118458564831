import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisMenu = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.064 3.534c.046-.725.518-1.287 1.238-1.385C3.892 2.069 4.758 2 6 2s2.109.069 2.698.149c.72.098 1.192.66 1.238 1.385C9.971 4.085 10 4.88 10 6c0 1.121-.029 1.915-.064 2.466-.046.725-.518 1.287-1.238 1.385C8.108 9.93 7.242 10 6 10s-2.108-.069-2.698-.15c-.72-.097-1.192-.66-1.238-1.384A39.235 39.235 0 0 1 2 6c0-1.121.029-1.915.064-2.466ZM2.064 15.534c.046-.725.518-1.287 1.238-1.385C3.892 14.07 4.758 14 6 14s2.109.069 2.698.15c.72.097 1.192.66 1.238 1.384.035.551.064 1.345.064 2.466 0 1.121-.029 1.915-.064 2.466-.046.725-.518 1.287-1.238 1.385C8.108 21.93 7.242 22 6 22s-2.108-.069-2.698-.15c-.72-.097-1.192-.66-1.238-1.384A39.236 39.236 0 0 1 2 18c0-1.121.029-1.915.064-2.466ZM14.064 3.534c.046-.725.518-1.287 1.238-1.385C15.892 2.069 16.758 2 18 2s2.109.069 2.698.149c.72.098 1.192.66 1.238 1.385C21.971 4.085 22 4.88 22 6c0 1.121-.029 1.915-.064 2.466-.046.725-.518 1.287-1.238 1.385-.59.08-1.456.149-2.698.149s-2.108-.069-2.698-.15c-.72-.097-1.192-.66-1.238-1.384A39.212 39.212 0 0 1 14 6c0-1.121.029-1.915.064-2.466ZM14.064 15.534c.046-.725.518-1.287 1.238-1.385.59-.08 1.456-.149 2.698-.149s2.109.069 2.698.15c.72.097 1.192.66 1.238 1.384.035.551.064 1.345.064 2.466 0 1.121-.029 1.915-.064 2.466-.046.725-.518 1.287-1.238 1.385-.59.08-1.456.149-2.698.149s-2.108-.069-2.698-.15c-.72-.097-1.192-.66-1.238-1.384A39.213 39.213 0 0 1 14 18c0-1.121.029-1.915.064-2.466Z"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisMenu = (props: IconCommonProps) => <Icon Svg={SerenisMenu} {...props} />
