import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import { Flex, OverflowAuto, OverflowHidden, Pressable } from 'cdk'
import {
  BORDER_RADIUS_2XS,
  BORDER_RADIUS_XS,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_30,
  cssvarFontSize,
  cssvarSpacing,
  type FontSizeName,
  SPACING_3XS,
  SPACING_SM,
  type SpacingName,
  TIME_150,
} from 'design-tokens'
import { IconRemoveInline, type IconSize } from 'icons'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { type SizeName, TextField, type TextFieldProps } from './TextField'

const MAX_VISIBLE_ITEMS = 10

type AddressInputSizeStyle = {
  fontSize: FontSizeName
  py: SpacingName
}

const addressInputSizesStyles: Record<SizeName, AddressInputSizeStyle> = {
  sm: { py: 'xs', fontSize: '16' },
  md: { py: 'sm', fontSize: '18' },
  lg: { py: 'md', fontSize: '20' },
}

const suffixIconSizes: Record<SizeName, IconSize> = {
  sm: 16,
  md: 20,
  lg: 24,
}

type ItemProps = {
  $active: boolean
  $sizeName: SizeName
}

const Item = styled(Flex)<ItemProps>`
  ${({ $sizeName }) => {
    const { fontSize, py } = addressInputSizesStyles[$sizeName]

    return css`
      padding-block: ${cssvarSpacing(py)};
      font-size: ${cssvarFontSize(fontSize)};
    `
  }}

  cursor: pointer;
  transition: background-color ${TIME_150} ease-in-out;

  &:hover {
    background-color: ${COLOR_NEUTRAL_20};
  }

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${COLOR_NEUTRAL_30};

      &:hover {
        background-color: ${COLOR_NEUTRAL_30};
      }
    `}
`

export type AutocompleteProps<T = any> = TextFieldProps & {
  onClickOption?: (suggestion: T | null) => void
  options?: T[]
  renderOption?: (option: T) => string
  onSearch?: (value: string) => void
}

export const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>(
  ({ options = [], onClickOption, renderOption, sizeName = 'md', onSearch, ...props }, forwardedRef) => {
    const [open, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [activeIndex, setActiveIndex] = useState<number | null>(null)
    const [filteredOptions, setFilteredOptions] = useState(options) // State for filtered options
    const inputRef = useRef<HTMLInputElement | null>(null)
    const mergedRef = useMergeRefs([inputRef, forwardedRef])
    const listRef = useRef<HTMLElement[] | null[]>([])

    const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
      whileElementsMounted: autoUpdate,
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(8),
        flip({ padding: 10 }),
        size({
          apply({ rects, elements, availableHeight }) {
            const itemHeight = (elements.floating.childNodes[0] as HTMLElement)?.offsetHeight || 0
            const maxHeight = itemHeight * MAX_VISIBLE_ITEMS + 8

            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
              maxHeight: `${Math.min(availableHeight, maxHeight)}px`,
            })
          },
          padding: 10,
        }),
      ],
    })

    const role = useRole(context, { role: 'listbox' })
    const dismiss = useDismiss(context)
    const listNav = useListNavigation(context, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      virtual: true,
      loop: true,
    })

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([role, dismiss, listNav])

    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setInputValue(value)
        if (onSearch) {
          onSearch(value) // Use external search if provided
        } else {
          // Inner search logic
          const filtered = options.filter((item) =>
            renderOption
              ? renderOption(item).toLowerCase().includes(value.toLowerCase())
              : String(item).toLowerCase().includes(value.toLowerCase()),
          )

          setFilteredOptions(filtered)
        }

        if (value) {
          setOpen(true)
          setActiveIndex(0)
          onClickOption?.(null) // Reset selected option
        } else {
          setOpen(false)
        }
      },
      [onSearch, options, renderOption, onClickOption],
    )

    useEffect(() => {
      if (onSearch) {
        setFilteredOptions(options) // Use provided options if external search is used
      }
    }, [options, onSearch])

    const onSelect = useCallback(
      (item: any) => {
        setInputValue(renderOption != null ? renderOption(item) : String(item))
        onClickOption?.(item)
        setActiveIndex(null)
        setOpen(false)
        refs.domReference.current?.focus()
      },
      [refs.domReference, onClickOption, renderOption],
    )

    const onReset = useCallback(() => {
      onSearch?.('')
      setInputValue('')
      onClickOption?.(null) // Reset selected option
      setActiveIndex(null)
      setOpen(false)
    }, [onSearch, onClickOption])

    return (
      <>
        <TextField
          ref={mergedRef}
          suffix={
            inputValue.length > 0 && (
              <Pressable aria-controls={props.id} aria-label="Clear" onClick={onReset}>
                <Flex>
                  <IconRemoveInline colorName="primary-70" size={suffixIconSizes[sizeName]} />
                </Flex>
              </Pressable>
            )
          }
          {...{ sizeName, ...props }}
          {...getReferenceProps({
            ref: refs.setReference,
            onChange,
            value: inputValue,
            'aria-autocomplete': 'list',
            onKeyDown(event) {
              if (event.key === 'Enter' && activeIndex != null && filteredOptions?.[activeIndex] != null) {
                onSelect(filteredOptions[activeIndex])
              }
            },
          })}
        />
        {open && filteredOptions.length > 0 && (
          <FloatingPortal>
            <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
              <OverflowHidden
                $backgroundColorName="lighter"
                $borderRadius={BORDER_RADIUS_XS}
                $elevationName="xs"
                {...getFloatingProps({
                  ref: refs.setFloating,
                  style: floatingStyles,
                })}
              >
                <OverflowAuto $p={SPACING_3XS} as="ul">
                  {filteredOptions.map((item, index) => (
                    <Item
                      key={renderOption ? renderOption(item) : String(index)}
                      $active={activeIndex === index}
                      $borderRadius={BORDER_RADIUS_2XS}
                      $px={SPACING_SM}
                      $sizeName={sizeName}
                      as="li"
                      {...getItemProps({
                        key: String(index),
                        ref(node) {
                          listRef.current[index] = node
                        },
                        onClick() {
                          onSelect(item)
                        },
                      })}
                    >
                      {renderOption ? renderOption(item) : String(item)}
                    </Item>
                  ))}
                </OverflowAuto>
              </OverflowHidden>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </>
    )
  },
)
