import { COLOR_GREY_40, COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PaymentCardApplePay = ({ width }: Props) => (
  <svg fill="none" viewBox="0 0 50 34" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z" fill={COLOR_WHITE} />
    <path
      d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z"
      stroke={COLOR_GREY_40}
      strokeWidth=".5"
    />
    <path
      d="M16.52 12.7c.32-.4.54-.93.48-1.48a2.07 2.07 0 0 0-1.87 2.14c.53.05 1.06-.26 1.4-.65ZM17 13.47c-.77-.05-1.42.43-1.79.43-.36 0-.92-.41-1.53-.4-.79.01-1.52.46-1.92 1.17-.82 1.42-.22 3.52.58 4.67.4.58.86 1.2 1.48 1.18.58-.02.81-.37 1.52-.37.7 0 .91.37 1.53.36.64-.01 1.04-.57 1.43-1.14.45-.65.63-1.28.64-1.32-.01 0-1.23-.48-1.25-1.89 0-1.17.96-1.73 1.01-1.77-.55-.81-1.4-.9-1.7-.92ZM23.67 11.87a2.7 2.7 0 0 1 2.82 2.82c0 1.68-1.18 2.83-2.87 2.83h-1.84v2.93h-1.33v-8.58h3.22Zm-1.89 4.53h1.53c1.16 0 1.82-.62 1.82-1.7 0-1.09-.66-1.7-1.81-1.7h-1.54v3.4ZM26.84 18.67c0-1.09.84-1.76 2.32-1.85l1.72-.1v-.48c0-.7-.47-1.11-1.26-1.11-.74 0-1.2.36-1.32.92H27.1c.07-1.13 1.03-1.97 2.58-1.97 1.52 0 2.48.8 2.48 2.06v4.31h-1.23v-1.03h-.03a2.24 2.24 0 0 1-1.97 1.14c-1.23 0-2.08-.76-2.08-1.89Zm4.04-.56v-.5l-1.54.1c-.77.05-1.2.4-1.2.93 0 .55.45.9 1.14.9.9 0 1.6-.62 1.6-1.43ZM33.32 22.75v-1.04c.1.03.3.03.41.03.6 0 .92-.25 1.11-.9l.12-.38-2.26-6.26h1.39l1.58 5.09h.02l1.59-5.1h1.35l-2.34 6.59c-.54 1.52-1.15 2-2.45 2-.1 0-.43 0-.52-.03Z"
      fill="#000"
    />
  </svg>
)

export const IconPaymentCardApplePay = (props: IconCommonProps) => <Icon Svg={PaymentCardApplePay} {...props} />
