import { COLOR_PRIMARY } from 'design-tokens'
import { Svg } from './components/Svg'

export const SerenisLogoText = () => (
  <Svg className="srns-serenis-light" fill="none" height="26" viewBox="0 0 120 26" width="120">
    <path
      d="M0 19.4812L4.14318 18.6769C4.75847 21.0531 5.98906 22.6617 9.06553 22.6617C11.4031 22.6617 12.5532 21.7755 12.5934 20.2855C12.5934 18.8772 11.6503 18.1915 7.75156 17.5057C2.54169 16.5405 0.6958 14.7287 0.6958 11.7062C0.6958 8.28299 3.24036 6.06763 8.28635 6.06763C13.3323 6.06763 15.713 8.3225 16.5324 11.9066L12.3059 12.8323C11.6906 10.377 10.5836 9.28767 8.28635 9.28767C6.1932 9.28767 5.08624 10.092 5.08624 11.503C5.08624 12.7927 5.82517 13.7184 9.76421 14.3618C15.138 15.327 16.9436 17.2602 16.9436 20.1613C16.9436 23.7059 14.4422 26.0003 8.98502 26.0003C2.83208 26.0003 0.655547 23.1415 0 19.4783V19.4812Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M18.8297 16.2582C18.8297 9.85477 22.6451 6.07031 28.6773 6.07031C34.7095 6.07031 37.4984 9.69391 37.4984 14.8894V14.9289C37.4984 15.6937 37.5387 16.419 37.415 17.4265H23.2604C23.6715 20.607 25.4772 22.179 28.7175 22.179C31.9579 22.179 32.9843 20.6889 33.5162 18.7557L37.3719 19.8422C36.3886 23.4263 33.7606 25.9634 28.6744 25.9634C22.7658 25.9634 18.8268 22.5402 18.8268 16.2582H18.8297ZM33.3955 14.2037C33.1482 11.2235 31.5496 9.73342 28.5536 9.73342C25.5577 9.73342 23.7952 11.1417 23.3035 14.2037H33.3955Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M44.8819 12.7558H44.9653C45.8681 7.84244 47.5099 6.55556 50.4627 6.55556H51.5697V11.4265H49.6835C46.2361 11.4265 44.8848 12.5554 44.8848 15.8968V25.4807H40.4541V6.55273H44.8848V12.7529L44.8819 12.7558Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M52.6366 16.2582C52.6366 9.85477 56.452 6.07031 62.4842 6.07031C68.5163 6.07031 71.3053 9.69391 71.3053 14.8894V14.9289C71.3053 15.6937 71.3455 16.419 71.2219 17.4265H57.0673C57.4784 20.607 59.2841 22.179 62.5244 22.179C65.7648 22.179 66.7912 20.6889 67.3231 18.7557L71.1788 19.8422C70.1955 23.4263 67.5675 25.9634 62.4813 25.9634C56.5727 25.9634 52.6337 22.5402 52.6337 16.2582H52.6366ZM67.1995 14.2037C66.9522 11.2235 65.3536 9.73342 62.3577 9.73342C59.3617 9.73342 57.5992 11.1417 57.1075 14.2037H67.1995Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M78.6884 11.7484H78.7287C79.5913 7.72125 82.0122 6.07031 85.2928 6.07031C89.9276 6.07031 91.693 9.05047 91.693 13.1567V25.478H87.2623V14.2827C87.2623 12.0278 86.2359 9.9733 83.2428 9.9733C80.4107 9.9733 78.6884 11.5847 78.6884 14.2827V25.478H74.2578V6.55289H78.6884V11.7484Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M100.267 4.01594H95.4687V0.795898H100.267V4.01876V4.01594ZM100.104 25.481H95.6728V6.55302H100.104V25.4781V25.481Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M103.056 19.4809L107.2 18.6766C107.815 21.0528 109.045 22.6615 112.122 22.6615C114.459 22.6615 115.61 21.7753 115.65 20.2852C115.65 18.877 114.707 18.1912 110.808 17.5054C105.598 16.5403 103.752 14.7285 103.752 11.706C103.752 8.28275 106.297 6.06738 111.343 6.06738C116.389 6.06738 118.769 8.32226 119.589 11.9063L115.362 12.832C114.747 10.3768 113.64 9.28742 111.343 9.28742C109.25 9.28742 108.143 10.0917 108.143 11.5028C108.143 12.7925 108.882 13.7182 112.821 14.3616C118.194 15.3268 120 17.2599 120 20.1611C120 23.7056 117.499 26 112.041 26C105.888 26 103.712 23.1412 103.056 19.4781V19.4809Z"
      fill={COLOR_PRIMARY}
    />
  </Svg>
)
