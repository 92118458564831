import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const AddFilledSolid = ({ color, height, width, fill }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" fill={fill} r="16" />
    <path d="M10 16h12m-6-6v12" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" />
  </svg>
)

export const IconAddFilledSolid = (props: IconCommonProps) => <Icon Svg={AddFilledSolid} {...props} />
