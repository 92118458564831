import { Flex, type PropsWithAs, type StyledProps } from 'cdk'
import {
  BORDER_RADIUS_XS,
  BORDER_WIDTH_0,
  COLOR_NEUTRAL_20,
  type ColorName,
  cssvarColor,
  FONT_FAMILY_DEGULAR_TEXT,
  FONT_SIZE_18,
  FONT_WEIGHT_500,
  LINE_HEIGHT_24,
  SPACING_SM,
  SPACING_XS,
  TIME_150,
} from 'design-tokens'
import { Icon, type IconElement } from 'icons'
import { type ButtonHTMLAttributes, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../Text'

type Kind = 'default' | 'danger'

const navigationButtonKindsColors: Record<Kind, ColorName> = {
  default: 'darker',
  danger: 'error',
}

type BaseNavigationButtonProps = {
  active?: boolean
  IconEnd?: IconElement
  IconStart?: IconElement
  kind: Kind
  renderStart?: () => JSX.Element
  renderEnd?: () => JSX.Element
}

export type NavigationButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & PropsWithAs<BaseNavigationButtonProps>

const NavigationButtonContainer = styled(Flex)<StyledProps<NavigationButtonProps>>`
  font-weight: ${FONT_WEIGHT_500};
  font-size: ${FONT_SIZE_18};
  font-family: ${FONT_FAMILY_DEGULAR_TEXT};
  line-height: ${LINE_HEIGHT_24};
  cursor: pointer;
  transition:
    color ${TIME_150} ease,
    background-color ${TIME_150} ease;

  ${({ $kind }) => css`
    color: ${cssvarColor(navigationButtonKindsColors[$kind])};
  `}

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: ${COLOR_NEUTRAL_20};
    }
  }
`

export const NavigationButton = forwardRef<HTMLButtonElement, NavigationButtonProps>(
  ({ active, children, IconEnd, IconStart, kind, renderEnd, renderStart, ...props }, ref) => (
    <NavigationButtonContainer
      ref={ref}
      $align="center"
      $backgroundColorName={active ? 'neutral-20' : 'lighter'}
      $borderRadius={BORDER_RADIUS_XS}
      $borderSize={BORDER_WIDTH_0}
      $direction="row"
      $gap={SPACING_SM}
      $justify="space-between"
      $kind={kind}
      $p={SPACING_XS}
      as="button"
      type="button"
      {...props}
    >
      {renderStart == null
        ? IconStart && (
            <Flex>
              <Icon Svg={IconStart} size={24} />
            </Flex>
          )
        : renderStart()}
      <Flex $align="flex-start" $grow={1} $shrink={1}>
        <Text ellipsis>{children}</Text>
      </Flex>
      {renderEnd == null
        ? IconEnd && (
            <Flex>
              <Icon Svg={IconEnd} size={16} />
            </Flex>
          )
        : renderEnd()}
    </NavigationButtonContainer>
  ),
)
