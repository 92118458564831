import { COLOR_BLACK, COLOR_MENTAL, COLOR_PRIMARY_10, COLOR_PRIMARY_30, COLOR_WHITE } from 'design-tokens'
import { Svg } from './components/Svg'

type Props = {
  size?: number
}

export const PathCoaching = ({ size = 164 }: Props) => (
  <Svg fill="none" height={size} viewBox="0 0 164 164" width={size}>
    <g clipPath="url(#path-coaching-a)">
      <g clipPath="url(#path-coaching-b)">
        <path
          d="M140.22 114.65c1.41.71 1.77 6.78 1.77 9.73l-23.87 3.09-9.72-6.19-3.1-11.05 26.96-4.41c4.6 0 5.16 2.94 4.87 4.42 2.12.35 2.94 3.09 3.09 4.41Z"
          fill={COLOR_WHITE}
        />
        <path d="M141.08 117.58c.3-2.26 0-5.92-8-5.08l-11.54 1.7" stroke={COLOR_BLACK} strokeWidth="1.14" />
        <path d="M139.37 113.25c.3-2.26-.07-5.06-8.06-4.21l-11.55 1.69" stroke={COLOR_BLACK} strokeWidth="1.14" />
        <path d="M137.52 109.81c.3-2.26-.07-5.06-8.06-4.2l-13.27 2.44" stroke={COLOR_BLACK} strokeWidth="1.14" />
        <path
          d="M121.54 117.58c2.85-12.48-18.42-12.71-29.41-11.27H-.5v31.2h92.63c13.96.29 43.32-1.9 49.02-13 5.7-11.1-10.7-9.24-19.6-6.93Z"
          fill="url(#path-coaching-c)"
          stroke="url(#path-coaching-d)"
          strokeWidth="1.14"
        />
        <path d="M121.54 117.58c-.6 2.02-5.7 5.9-21.39 5.2" stroke={COLOR_BLACK} strokeWidth="1.14" />
        <path
          d="m81.03 39.99 9.96 6.28 9.94-6.3 2.6 11.48L115 54.03l-6.28 9.96 6.3 9.94-11.48 2.6L100.97 88l-9.95-6.28-9.95 6.3-2.6-11.48L67 73.97l6.28-9.95-6.3-9.95 11.48-2.6L81.03 40Z"
          fill="url(#path-coaching-e)"
        />
        <path
          d="M109.44 24.17C137.48 9.1 204.04 40.02 222 52.57l-9.09 33.75c-24.4-16.1-73.18-31-82.7-31.65a115.99 115.99 0 0 1-11.03 14.93c-5.72 3.12-5.85-3.66-5.2-7.77-4.15 0-5.4-4.33-5.19-5.85-3.11 1.56-4.54 0-5.2-1.3-.51 3.1-4.68 3.9-5.83 1.3-7.8-17.5 3.88-27.62 11.68-31.81Z"
          fill={COLOR_WHITE}
          stroke={COLOR_BLACK}
          strokeWidth="1.14"
        />
        <path
          d="M122.42 47.53c-2.38 2.82-7.4 9.6-8.44 14.28M115.93 39.74c-2.16 2.6-6.62 9.61-7.14 16.88M109.44 33.9c-3.03 3.46-8.44 12.6-5.84 21.42"
          stroke={COLOR_BLACK}
          strokeWidth="1.14"
        />
        <path
          d="M91.93 94.03a5.62 5.62 0 0 1 9.14-5.28l3.15 2.62a5.62 5.62 0 0 0 4.54 1.22l4.04-.7a5.62 5.62 0 0 1 5.27 9.14l-2.61 3.16a5.62 5.62 0 0 0-1.22 4.53l.69 4.04a5.62 5.62 0 0 1-9.13 5.28l-3.16-2.62a5.62 5.62 0 0 0-4.54-1.22l-4.04.7a5.62 5.62 0 0 1-5.27-9.14l2.62-3.16a5.62 5.62 0 0 0 1.21-4.53l-.69-4.04Z"
          fill={COLOR_PRIMARY_10}
        />
        <path
          clipRule="evenodd"
          d="M56.9 85.94a3 3 0 0 1 1.5-3.97l9.42-4.3a6.8 6.8 0 1 1 5.63 12.37l-6.4 2.92a.33.33 0 0 0 .27.6l6.72-3.07a6.45 6.45 0 0 1 5.35 11.73l-6.49 2.96a.59.59 0 0 0 .5 1.07l9.6-4.38a3 3 0 1 1 2.5 5.47l-9.62 4.38A6.6 6.6 0 0 1 70.4 99.7l6.49-2.95a.44.44 0 0 0-.37-.8l-6.72 3.06a6.33 6.33 0 1 1-5.25-11.53l6.4-2.91a.79.79 0 1 0-.65-1.44l-9.43 4.3a3 3 0 0 1-3.98-1.5Z"
          fill={COLOR_PRIMARY_30}
          fillRule="evenodd"
        />
      </g>
      <path
        d="m81.03 39.99 9.96 6.28 9.94-6.3 2.6 11.48L115 54.03l-6.28 9.96 6.3 9.94-11.48 2.6L100.97 88l-9.95-6.28-9.95 6.3-2.6-11.48L67 73.97l6.28-9.95-6.3-9.95 11.48-2.6L81.03 40Z"
        fill="url(#path-coaching-f)"
      />
      <path
        d="M109.44 24.17C137.48 9.1 204.04 40.02 222 52.57l-9.09 33.75c-24.4-16.1-73.18-31-82.7-31.65a115.99 115.99 0 0 1-11.03 14.93c-5.72 3.12-5.85-3.66-5.2-7.77-4.15 0-5.4-4.33-5.19-5.85-3.11 1.56-4.54 0-5.2-1.3-.51 3.1-4.68 3.9-5.83 1.3-7.8-17.5 3.88-27.62 11.68-31.81Z"
        fill={COLOR_WHITE}
        stroke={COLOR_BLACK}
        strokeWidth="1.14"
      />
      <path
        d="M122.42 47.53c-2.38 2.82-7.4 9.6-8.44 14.28M115.93 39.74c-2.16 2.6-6.62 9.61-7.14 16.88M109.44 33.9c-3.03 3.46-8.44 12.6-5.84 21.42"
        stroke={COLOR_BLACK}
        strokeWidth="1.14"
      />
      <path
        d="M91.93 94.03a5.62 5.62 0 0 1 9.14-5.28l3.15 2.62a5.62 5.62 0 0 0 4.54 1.22l4.04-.7a5.62 5.62 0 0 1 5.27 9.14l-2.61 3.16a5.62 5.62 0 0 0-1.22 4.53l.69 4.04a5.62 5.62 0 0 1-9.13 5.28l-3.16-2.62a5.62 5.62 0 0 0-4.54-1.22l-4.04.7a5.62 5.62 0 0 1-5.27-9.14l2.62-3.16a5.62 5.62 0 0 0 1.21-4.53l-.69-4.04Z"
        fill={COLOR_PRIMARY_10}
      />
      <path
        clipRule="evenodd"
        d="M56.9 85.94a3 3 0 0 1 1.5-3.97l9.42-4.3a6.8 6.8 0 1 1 5.63 12.37l-6.4 2.92a.33.33 0 0 0 .27.6l6.72-3.07a6.45 6.45 0 0 1 5.35 11.73l-6.49 2.96a.59.59 0 0 0 .5 1.07l9.6-4.38a3 3 0 1 1 2.5 5.47l-9.62 4.38A6.6 6.6 0 0 1 70.4 99.7l6.49-2.95a.44.44 0 0 0-.37-.8l-6.72 3.06a6.33 6.33 0 1 1-5.25-11.53l6.4-2.91a.79.79 0 1 0-.65-1.44l-9.43 4.3a3 3 0 0 1-3.98-1.5Z"
        fill={COLOR_PRIMARY_30}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-coaching-c" x1=".5" x2="143.5" y1="122" y2="122">
        <stop stopColor={COLOR_WHITE} stopOpacity="0" />
        <stop offset=".24" stopColor={COLOR_WHITE} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-coaching-d" x1="142" x2="-1" y1="122" y2="122">
        <stop offset=".7" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-coaching-e" x1="66.99" x2="115.01" y1="73.97" y2="54.03">
        <stop stopColor={COLOR_MENTAL} />
        <stop offset="1" stopColor={COLOR_MENTAL} stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-coaching-f" x1="66.99" x2="115.01" y1="73.97" y2="54.03">
        <stop stopColor={COLOR_MENTAL} />
        <stop offset="1" stopColor={COLOR_MENTAL} stopOpacity="0" />
      </linearGradient>
      <clipPath id="path-coaching-a">
        <path d="M0 0h164v164H0z" fill={COLOR_WHITE} />
      </clipPath>
      <clipPath id="path-coaching-b">
        <path d="M0 0h164v164H0z" fill={COLOR_WHITE} />
      </clipPath>
    </defs>
  </Svg>
)
