import { COLOR_GREY_40, COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PaymentCardMaestro = ({ width }: Props) => (
  <svg fill="none" viewBox="0 0 50 34" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z" fill={COLOR_WHITE} />
    <path
      d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z"
      stroke={COLOR_GREY_40}
      strokeWidth=".5"
    />
    <path
      d="M19.92 26.89v-1.33a.78.78 0 0 0-.83-.83.82.82 0 0 0-.74.37.77.77 0 0 0-.7-.37.7.7 0 0 0-.62.31v-.26h-.45v2.1h.46v-1.16a.5.5 0 0 1 .51-.56c.3 0 .46.2.46.55v1.18h.47v-1.17a.5.5 0 0 1 .51-.56c.31 0 .46.2.46.55v1.18h.47Zm2.58-1.06v-1.05h-.46v.26a.8.8 0 0 0-.66-.31 1.1 1.1 0 0 0 0 2.21.8.8 0 0 0 .66-.3v.25h.46v-1.06Zm-1.7 0a.64.64 0 1 1 1.27.08.64.64 0 0 1-1.28-.08Zm11.51-1.1a1.19 1.19 0 0 1 .81.31c.1.1.18.22.24.35.11.29.11.6 0 .88a1.07 1.07 0 0 1-.6.59c-.29.11-.61.11-.9 0a1.09 1.09 0 0 1-.6-.59c-.11-.28-.11-.6 0-.88a1.08 1.08 0 0 1 .6-.58c.14-.06.3-.09.45-.09Zm0 .43a.68.68 0 0 0-.26.05.62.62 0 0 0-.35.35.77.77 0 0 0 0 .55c.03.08.08.15.14.21.06.06.13.1.2.14.18.07.36.07.53 0a.63.63 0 0 0 .35-.35.77.77 0 0 0 0-.55.64.64 0 0 0-.61-.4Zm-7.32.67c0-.65-.4-1.1-1-1.1a1.1 1.1 0 0 0 .03 2.21c.32.01.63-.1.87-.3l-.23-.33a1 1 0 0 1-.6.22.59.59 0 0 1-.64-.52H25v-.18Zm-1.56-.18a.54.54 0 0 1 .56-.51.52.52 0 0 1 .53.5h-1.1Zm3.5-.33c-.2-.12-.43-.18-.66-.18-.25 0-.4.09-.4.24s.16.18.35.21l.22.03c.46.07.74.26.74.63 0 .4-.36.69-.96.69-.33 0-.65-.09-.92-.27l.22-.36c.2.15.45.23.7.22.31 0 .48-.1.48-.26 0-.11-.12-.18-.37-.22l-.22-.03c-.47-.06-.72-.27-.72-.62 0-.42.34-.67.87-.67.3-.01.6.06.86.22l-.2.37Zm2.2-.12h-.75v.95c0 .21.07.35.3.35.15 0 .29-.04.4-.12l.14.4c-.17.1-.37.16-.57.16-.54 0-.73-.3-.73-.78v-.96h-.43v-.42h.43v-.64h.46v.64h.75v.42Zm1.58-.47c.12 0 .23.02.33.05l-.14.44a.75.75 0 0 0-.29-.06c-.3 0-.45.2-.45.55v1.18h-.45v-2.11h.45v.26a.62.62 0 0 1 .55-.31Zm3.18 1.85a.21.21 0 0 1 .2.3.21.21 0 0 1-.12.1.21.21 0 0 1-.28-.11.2.2 0 0 1 .11-.27.21.21 0 0 1 .09-.02Zm0 .37a.16.16 0 0 0 .11-.04.16.16 0 1 0-.11.04Zm.01-.26c.02 0 .04 0 .06.02a.06.06 0 0 1 .02.04.05.05 0 0 1-.02.04.08.08 0 0 1-.04.02l.06.08h-.05l-.06-.08h-.02v.08h-.04v-.2h.1Zm-.05.04v.05h.08a.02.02 0 0 0 0-.03.02.02 0 0 0 0-.02h-.08Z"
      fill="#000"
    />
    <path d="M28.48 8.7h-6.96v12.5h6.96V8.7Z" fill="#7375CF" />
    <path d="M21.96 14.95A7.93 7.93 0 0 1 25 8.7a7.95 7.95 0 1 0 0 12.5 7.93 7.93 0 0 1-3.04-6.25Z" fill="#EB001B" />
    <path
      d="M37.1 19.87v-.26h.1v-.05h-.26v.05h.1v.26h.06Zm.5 0v-.3h-.07l-.1.2-.09-.2h-.08v.3h.06v-.23l.09.2h.05l.1-.2v.23h.05ZM37.86 14.95A7.94 7.94 0 0 1 25 21.19a7.95 7.95 0 0 0 0-12.5 7.95 7.95 0 0 1 12.86 6.26Z"
      fill="#00A2E5"
    />
  </svg>
)

export const IconPaymentCardMaestro = (props: IconCommonProps) => <Icon Svg={PaymentCardMaestro} {...props} />
