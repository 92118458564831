import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisPatientsSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" fill={color}>
      <path
        clipRule="evenodd"
        d="M12 7.443c.843 0 1.65-.34 2.247-.943.596-.604.93-1.424.93-2.278 0-.855-.334-1.674-.93-2.278A3.156 3.156 0 0 0 12 1c-.843 0-1.65.34-2.247.944a3.245 3.245 0 0 0-.93 2.278c0 .854.334 1.674.93 2.278A3.156 3.156 0 0 0 12 7.443Zm-2.92.851A35.035 35.035 0 0 1 12 8.187c1.285 0 2.245.05 2.921.107.89.075 1.622.672 1.902 1.527a27.464 27.464 0 0 1 1.166 5.345 1.616 1.616 0 0 1-1.505 1.802c-.268.017-.57.035-.9.052l-.322 4.394c-.058.795-.648 1.46-1.447 1.541-.147.015-.304.03-.468.043a.714.714 0 0 1-.537-.185.733.733 0 0 1-.24-.52l-.183-4.452a.25.25 0 0 0-.075-.168.243.243 0 0 0-.17-.07h-.284a.242.242 0 0 0-.17.07.25.25 0 0 0-.075.169l-.182 4.451a.741.741 0 0 1-.24.52.72.72 0 0 1-.538.185 21.658 21.658 0 0 1-.468-.043c-.799-.081-1.388-.746-1.447-1.542l-.32-4.393a75.349 75.349 0 0 1-.902-.052 1.616 1.616 0 0 1-1.505-1.802c.21-1.817.6-3.607 1.166-5.345.28-.855 1.012-1.452 1.902-1.527Z"
        fillRule="evenodd"
      />
      <path d="M4.526 20.422 5 16.5c0-1.66.457-4.003 1.091-6.187.19-.652-.287-1.319-.967-1.324l-2.558-.02a1.817 1.817 0 0 0-1.585 1.248 22.122 22.122 0 0 0-.972 4.374 1.33 1.33 0 0 0 1.255 1.474c.223.014.474.028.75.042l.268 3.595c.049.651.54 1.194 1.205 1.261.13.013.26.025.39.035a.605.605 0 0 0 .649-.576ZM19.483 20.422 19.01 16.5c0-1.66-.457-4.003-1.091-6.187-.19-.652.287-1.319.966-1.324l2.559-.02c.741.06 1.352.549 1.585 1.248A22.12 22.12 0 0 1 24 14.591a1.33 1.33 0 0 1-1.255 1.474c-.223.014-.474.028-.75.042l-.268 3.595a1.353 1.353 0 0 1-1.206 1.261c-.13.013-.26.025-.39.035a.604.604 0 0 1-.648-.576Z" />
      <path
        clipRule="evenodd"
        d="M4.704 8.272c.702 0 1.376-.278 1.873-.772a2.63 2.63 0 0 0 0-3.728 2.654 2.654 0 0 0-3.745 0 2.63 2.63 0 0 0 0 3.728c.496.494 1.17.772 1.872.772ZM19.352 8.272A2.654 2.654 0 0 1 17.48 7.5a2.63 2.63 0 0 1 0-3.728 2.654 2.654 0 0 1 3.744 0 2.63 2.63 0 0 1 0 3.728 2.654 2.654 0 0 1-1.872.772Z"
        fillRule="evenodd"
      />
    </g>
  </svg>
)

export const IconSerenisPatientsSolid = (props: IconCommonProps) => <Icon Svg={SerenisPatientsSolid} {...props} />
