import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisXMarkCircle = ({ color, fill, height, width }: Props) => (
  <svg fill="none" height={height} role="img" width={width} xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" fill={fill} fillOpacity=".72" r="12" />
    <path d="M16 8.00001 12 12m0 0-4 4m4-4 4 4m-4-4L8 8" stroke={color} strokeLinecap="round" strokeWidth="2" />
  </svg>
)

export const IconSerenisXMarkCircle = (props: IconCommonProps) => (
  <Icon Svg={SerenisXMarkCircle} {...props} data-test-id="srns-icon" />
)
