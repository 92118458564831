import { COLOR_LIGHTER } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PathNutritionWeightLoss = ({ color, width }: Props) => (
  <svg
    data-test-id="icon-path-nutrition-weight-loss"
    fill="none"
    height={width}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-path-nutrition-weight-loss-a)">
      <path
        clipRule="evenodd"
        d="M25.6 9.71a5.72 5.72 0 0 1-11.2 0 14.24 14.24 0 0 1 11.2 0Zm-.25-3.14a17.13 17.13 0 0 0-10.7 0 5.72 5.72 0 0 1 10.7 0ZM28.55 8a8.57 8.57 0 0 0-17.1 0 17.14 17.14 0 1 0 17.1 0Zm-.37 3.14a8.58 8.58 0 0 1-16.36 0 14.29 14.29 0 1 0 16.36 0Z"
        fill={color}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="icon-path-nutrition-weight-loss-a">
        <path d="M0 0h40v40H0z" fill={COLOR_LIGHTER} />
      </clipPath>
    </defs>
  </svg>
)

export const IconPathNutritionWeightLoss = (props: IconCommonProps) => <Icon Svg={PathNutritionWeightLoss} {...props} />
