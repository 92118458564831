import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const Linkedin = ({ height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4310_1519)">
      <path
        d="M19.0409 19.0408H16.0776V14.4C16.0776 13.2934 16.0578 11.8688 14.5363 11.8688C12.9929 11.8688 12.7567 13.0745 12.7567 14.3194V19.0405H9.79336V9.49716H12.6382V10.8014H12.678C12.9627 10.3146 13.3741 9.91412 13.8684 9.64265C14.3627 9.37118 14.9214 9.23886 15.4849 9.25978C18.4884 9.25978 19.0422 11.2354 19.0422 13.8055L19.0409 19.0408ZM6.44969 8.19266C6.10957 8.19272 5.77706 8.09192 5.49422 7.90301C5.21139 7.7141 4.99094 7.44556 4.86073 7.13135C4.73051 6.81715 4.69638 6.47138 4.76268 6.13778C4.82897 5.80419 4.99271 5.49774 5.23317 5.25719C5.47363 5.01665 5.78002 4.85281 6.11359 4.78639C6.44716 4.71998 6.79293 4.75397 7.10719 4.88407C7.42144 5.01418 7.69006 5.23454 7.87907 5.51731C8.06808 5.80008 8.16901 6.13254 8.16907 6.47267C8.16911 6.6985 8.12466 6.92213 8.03827 7.13078C7.95189 7.33944 7.82527 7.52904 7.66561 7.68876C7.50595 7.84847 7.31638 7.97518 7.10775 8.06164C6.89913 8.1481 6.67552 8.19262 6.44969 8.19266ZM7.93137 19.0408H4.96491V9.49716H7.93137V19.0408ZM20.5183 2.00136H3.47583C3.08901 1.997 2.71626 2.14638 2.43953 2.41669C2.1628 2.68699 2.00472 3.05611 2 3.44292V20.5561C2.00456 20.9431 2.16255 21.3125 2.43927 21.5831C2.71599 21.8537 3.08882 22.0033 3.47583 21.9992H20.5183C20.9061 22.0041 21.28 21.8548 21.5578 21.5842C21.8356 21.3137 21.9946 20.9439 22 20.5561V3.44169C21.9945 3.05411 21.8353 2.68457 21.5575 2.41427C21.2797 2.14397 20.9059 1.99502 20.5183 2.00013"
        fill="#0A66C2"
      />
    </g>
    <defs>
      <clipPath id="clip0_4310_1519">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export const IconLinkedin = (props: IconCommonProps) => <Icon Svg={Linkedin} {...props} />
