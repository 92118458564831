import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisCommunity = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.181 8.302c-.768.064-1.4.57-1.642 1.302-.544 1.65-.92 3.35-1.123 5.075a1.317 1.317 0 0 0 1.242 1.463c.306.018.658.038 1.049.056l.354 4.43c.052.652.542 1.185 1.192 1.251.504.05 1.125.096 1.747.096.622 0 1.243-.045 1.746-.096.651-.067 1.14-.599 1.193-1.25l.354-4.43c.391-.019.743-.039 1.049-.058a1.317 1.317 0 0 0 1.242-1.462 25.774 25.774 0 0 0-1.123-5.075 1.883 1.883 0 0 0-1.642-1.302 34.018 34.018 0 0 0-2.82-.102c-1.241 0-2.168.048-2.818.102Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M16.52 6.3a2.28 2.28 0 1 0 4.56 0 2.28 2.28 0 0 0-4.56 0ZM7.48 6.3a2.28 2.28 0 1 1-4.56 0 2.28 2.28 0 0 1 4.56 0Z"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M6.849 8.583a71.005 71.005 0 0 0-3.591.09c-.928.049-1.694.74-1.86 1.694a27.91 27.91 0 0 0-.376 3.187c-.038.681.397 1.277 1.046 1.356.231.028.463.048.695.06l.308 3.683c.055.665.511 1.218 1.148 1.28.678.064 1.36.063 2.038-.004.37-.036.68-.239.885-.534M16.858 19.395c.205.295.515.498.886.534a10.5 10.5 0 0 0 2.036.003c.638-.061 1.094-.614 1.15-1.279l.307-3.682c.232-.013.464-.033.695-.06.65-.08 1.084-.675 1.046-1.357a27.89 27.89 0 0 0-.375-3.186c-.168-.954-.933-1.646-1.86-1.695a70.56 70.56 0 0 0-3.592-.09M8.912 5.112a3.087 3.087 0 1 0 6.175 0 3.087 3.087 0 0 0-6.175 0ZM12 17.225v4.75"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisCommunity = (props: IconCommonProps) => <Icon Svg={SerenisCommunity} {...props} />
