import { Flex, type FlexProps, PositionSticky } from 'cdk'
import { COLOR_PRIMARY_80, OPACITY_32, SPACING_MD, TIME_150 } from 'design-tokens'
import { type ReactNode, useId } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../Text'

type TopBarContainerProps = {
  $showBorder: boolean
}

const TopBarContainer = styled(PositionSticky)<TopBarContainerProps>`
  ${({ $showBorder }) =>
    $showBorder &&
    css`
      border-color: rgb(from ${COLOR_PRIMARY_80} r g b / ${OPACITY_32});
    `};
  transition: border-color ${TIME_150} ease-in-out;
`

const TopBarLogoContainer = styled(Flex)`
  margin: 0;
  transition: max-width ${TIME_150} ease-in-out;
`

const TopBarTitleContainer = styled(Flex)`
  transition: opacity ${TIME_150} ease-in-out;
`

export type TopBarProps = {
  back?: ReactNode
  center?: ReactNode
  edgeMaxWidth?: FlexProps['$basis']
  end?: ReactNode
  logo?: ReactNode
  logoClosingWidth?: number
  shouldShrinkLogo?: boolean
}

export const TopBar = ({
  back,
  center,
  edgeMaxWidth = '25%',
  end,
  logo,
  logoClosingWidth = 24,
  shouldShrinkLogo = false,
}: TopBarProps) => {
  const id = useId()

  return (
    <TopBarContainer
      $align="center"
      $backgroundColorName="lighter"
      $borderColorName="transparent"
      $borderSizeBottom={0.2}
      $direction="row"
      $gap={SPACING_MD}
      $justify="space-between"
      $maxHeight={56}
      $p={SPACING_MD}
      $showBorder={shouldShrinkLogo}
      $top={0}
      {...(center != null && { 'aria-labelledby': id })}
      as="nav"
    >
      <Flex $align="flex-start" $basis={edgeMaxWidth}>
        {back ?? (
          <TopBarLogoContainer
            $align="flex-start"
            $basis="fit-content"
            $maxWidth={shouldShrinkLogo ? logoClosingWidth : '100%'}
            data-testid="top-bar-logo"
            {...(shouldShrinkLogo && { $overflow: 'hidden' })}
          >
            {logo}
          </TopBarLogoContainer>
        )}
      </Flex>
      {center && (
        <TopBarTitleContainer
          $backgroundColorName="lighter"
          $direction="row"
          $grow={1}
          $justify="center"
          $opacity={shouldShrinkLogo ? 1 : 0}
          $shrink={1}
          id={id}
        >
          <Text as="div" ellipsis kind="h3" textAlign="center">
            {center}
          </Text>
        </TopBarTitleContainer>
      )}
      <Flex $align="flex-end" $basis={edgeMaxWidth} data-testid="top-bar-end">
        {end}
      </Flex>
    </TopBarContainer>
  )
}
