import { COLOR_BLACK, COLOR_MENTAL, COLOR_PRIMARY_20, COLOR_PRIMARY_30, COLOR_WHITE } from 'design-tokens'
import { Svg } from './components/Svg'

type Props = {
  size?: number
}

export const PathPsychiatry = ({ size = 164 }: Props) => (
  <Svg fill="none" height={size} viewBox="0 0 164 164" width={size}>
    <g clipPath="url(#path-psychiatry-a)">
      <rect fill={COLOR_PRIMARY_30} height="6" rx="1.48" width="19" x="41.05" y="28" />
      <rect fill="url(#path-psychiatry-b)" height="77.15" rx="6.07" width="57.1" x="22" y="34" />
      <rect fill={COLOR_PRIMARY_30} height="5.93" rx="1.48" width="57.04" x="22" y="31.09" />
      <rect fill={COLOR_PRIMARY_30} height="5.93" rx="1.48" width="57.04" x="22" y="31.09" />
      <path
        d="M31.63 52.2h38.9M31.63 57.39h38.9M31.63 62.58h38.9M31.63 67.76h38.9M31.63 72.95h38.9M31.63 80.16h7.4c1.37-.37 3.64-1.26 1.86-1.86-2.22-.74-2.22 1.12 1.85 1.86 3.26.59 16.67.24 22.97 0M55.08 88.2H36.93c-1.97-.25-5.48-1.04-3.7-2.23 2.22-1.48 2.85 2.97-1.6 2.97"
        stroke={COLOR_WHITE}
        strokeOpacity=".7"
        strokeWidth="1.48"
      />
      <path
        d="M70.1 72.49a3 3 0 0 1 3.86 1.93l3.23 10.36a3 3 0 0 1-1.76 3.69l-9.37 3.67a3 3 0 0 1-1.41.2l-13.3-1.42a3 3 0 0 1-1.73-5.17l9.83-9.22a3 3 0 0 1 1.05-.64l9.6-3.4Z"
        fill={COLOR_MENTAL}
      />
      <path
        d="M74.94 59.12C56.05 68.9 59.56 76.52 63.67 79.1c1.59.36 7.1 1.72 8.6-1.81 3.7-8.59 15.7-12.37 22.67-3.1 5.02 6.66 3.28 17.5-10.59 22.52S68.4 91.69 70.23 85.76c1.39-4.5-4.97-8.68-11.35-4.34-6.37 4.35-17.63 22.79 15.6 49.68l13.26 9.07c6.33 4.32 14.55 5.06 21.33 1.48 11.07-5.85 22.93-13.76 25-24.17 2.66-35.65-3.8-43.5-7.35-42.96-5.03-12.18-13.4-13.69-16.95-12.92-11.98-8.76-28.21-5.3-34.83-2.48Z"
        fill={COLOR_WHITE}
        stroke={COLOR_BLACK}
        strokeWidth="1.18"
      />
      <path
        d="M126.67 74.3c2 5.49 1.56 11.9.93 15.24M107.65 60.24c3.66 1.17 11.52 14.05 12.36 24.55"
        stroke={COLOR_BLACK}
        strokeWidth="1.18"
      />
      <path
        d="M139.16 43.84 61.12 75.6c-.64.26-1.06.9-1.04 1.59.04 1.17 1.27 1.99 2.42 1.8 1.83-.29 4.4-.25 6.52 1.48a6.38 6.38 0 0 1 2.38 4.71c.17 2.34 2.14 4.16 4.3 3.27l69.74-28.53a3 3 0 0 0 1.67-3.85l-4.02-10.5a3 3 0 0 0-3.93-1.72Z"
        fill="url(#path-psychiatry-c)"
      />
    </g>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-psychiatry-b" x1="50.55" x2="50.55" y1="1.27" y2="111.15">
        <stop stopColor={COLOR_PRIMARY_20} stopOpacity="0" />
        <stop offset="1" stopColor={COLOR_PRIMARY_20} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-psychiatry-c" x1="147.62" x2="71.01" y1="50.76" y2="79.9">
        <stop stopColor={COLOR_MENTAL} stopOpacity="0" />
        <stop offset=".71" stopColor={COLOR_MENTAL} />
      </linearGradient>
      <clipPath id="path-psychiatry-a">
        <path d="M0 0h164v164H0z" fill={COLOR_WHITE} />
      </clipPath>
    </defs>
  </Svg>
)
