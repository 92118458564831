import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const Whatsapp = ({ height, width }: Props) => (
  <svg
    data-test-id="icon-whatsapp"
    fill="none"
    height={height}
    viewBox="0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m0 32 2.26-8.217a15.774 15.774 0 0 1-2.126-7.927C.138 7.113 7.284 0 16.067 0a15.87 15.87 0 0 1 11.27 4.65A15.722 15.722 0 0 1 32 15.87c-.004 8.742-7.152 15.856-15.933 15.856h-.007a15.98 15.98 0 0 1-7.614-1.93L0 32Z"
      fill="#fff"
    />
    <path
      d="M16.072 2.679c-7.305 0-13.246 5.912-13.248 13.178a13.103 13.103 0 0 0 2.024 7.014l.315.499-1.338 4.863 5.013-1.309.483.286a13.27 13.27 0 0 0 6.74 1.837h.006c7.299 0 13.24-5.912 13.242-13.18a13.043 13.043 0 0 0-1-5.048 13.092 13.092 0 0 0-2.875-4.275 13.16 13.16 0 0 0-4.292-2.866 13.207 13.207 0 0 0-5.07-1Z"
      fill="url(#icon-whatsapp-a)"
    />
    <path
      clipRule="evenodd"
      d="M12.084 9.227c-.298-.66-.612-.673-.895-.685l-.764-.009c-.265 0-.696.1-1.061.496-.365.397-1.393 1.355-1.393 3.305 0 1.95 1.426 3.834 1.625 4.1.199.264 2.754 4.393 6.8 5.982 3.363 1.32 4.047 1.057 4.778.991.73-.066 2.355-.959 2.687-1.884.331-.925.331-1.718.232-1.884-.1-.166-.365-.264-.763-.463-.399-.198-2.356-1.157-2.72-1.29-.366-.131-.63-.197-.896.2-.266.396-1.028 1.288-1.26 1.553-.233.264-.465.298-.863.1-.399-.199-1.68-.617-3.202-1.968-1.183-1.05-1.982-2.347-2.214-2.744-.233-.396-.025-.611.175-.808.178-.178.398-.463.597-.695.2-.231.265-.396.398-.66.132-.264.066-.497-.034-.695-.1-.198-.873-2.158-1.227-2.942Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="icon-whatsapp-a" x1="15.797" x2="15.93" y1="4.261" y2="26.795">
        <stop stopColor="#57D163" />
        <stop offset="1" stopColor="#23B33A" />
      </linearGradient>
    </defs>
  </svg>
)

export const IconWhatsapp = (props: IconCommonProps) => <Icon Svg={Whatsapp} {...props} />
