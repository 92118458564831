import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisClock = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 6v6l3 3M12 2.025c5.51 0 9.975 4.466 9.975 9.975 0 5.509-4.466 9.975-9.975 9.975-5.509 0-9.975-4.466-9.975-9.975 0-5.51 4.466-9.975 9.975-9.975Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisClock = (props: IconCommonProps) => <Icon Svg={SerenisClock} {...props} />
