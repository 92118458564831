// src/lib/alphanum.ts
import { customAlphabet } from "nanoid";
var alphanum = (size = 4) => customAlphabet("1234567890ABCDEFGHIKJLMNOPQRSTUVWXYZ")(size);

// src/lib/password.ts
import { customAlphabet as customAlphabet2 } from "nanoid";
var password = customAlphabet2("0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ", 16);

// src/lib/uid.ts
import { customAlphabet as customAlphabet3 } from "nanoid";
var uid = customAlphabet3("1234567890abcdefghikjlmnopqrstuvwxyz", 16);
var uidWithPrefix = (prefix) => `${prefix}_${uid()}`;
export {
  alphanum,
  password,
  uid,
  uidWithPrefix
};
