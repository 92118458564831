import { Auth, type CognitoUser as BaseCognitoUser } from '@aws-amplify/auth'
import { type AuthOptions } from '@aws-amplify/auth/lib-esm/types'
import * as Sentry from '@sentry/nextjs'
import { noop } from 'functions'
import { useCallback } from 'react'
import { useReactNativePostMessage } from '~/domains/appNative/hooks/useReactNativePostMessage'
import { type CognitoUser } from '../types/cognitoUser'

type AuthConfig = {
  oauth: {
    domain: string
    scope: string[]
    redirectSignIn: string
    redirectSignOut: string
    responseType: string
  }
}

export const useAuthMethods = () => {
  const { requestClearIdentityCIO } = useReactNativePostMessage()
  const currentAuthenticatedUser = useCallback((): Promise<CognitoUser> => Auth.currentAuthenticatedUser(), [])

  const refreshSession = useCallback(async (onError: (error: Error) => void = noop) => {
    try {
      const authenticatedUser = (await Auth.currentAuthenticatedUser()) as BaseCognitoUser
      const authenticatedUserSession = await Auth.currentSession()
      const refreshToken = authenticatedUserSession.getRefreshToken()

      authenticatedUser.refreshSession(refreshToken, onError)
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'authentication',
          app: 'serenis',
        },
      })
    }
  }, [])

  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      const user = await currentAuthenticatedUser()

      await Auth.changePassword(user, oldPassword, newPassword)

      await refreshSession()
    },
    [currentAuthenticatedUser, refreshSession],
  )

  const configure = useCallback((config: AuthConfig): AuthOptions => Auth.configure(config), [])

  const forgotPassword = useCallback(async (email: string) => {
    await Auth.forgotPassword(email)
  }, [])

  const forgotPasswordSubmit = useCallback(async (email: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(email, code, password)
  }, [])

  const signIn = useCallback(async (username: string, password: string) => {
    await Auth.signIn(username, password)
  }, [])

  const signOut = useCallback(async () => {
    await Auth.signOut({ global: true })
    requestClearIdentityCIO()
  }, [requestClearIdentityCIO])

  return {
    changePassword,
    configure,
    currentAuthenticatedUser,
    forgotPassword,
    forgotPasswordSubmit,
    refreshSession,
    signIn,
    signOut,
  }
}
