import { useBreakpoints } from 'hooks'
import { type ReactNode, useCallback } from 'react'
import { IntercomProvider as IntercomProviderPackage } from 'react-use-intercom'
import { getEnv } from '~/utils/getEnv'
import { IntercomStateProvider, useIntercomState } from '../hooks/useIntercomState'
import { IntercomDefaultLauncherVisibility } from './IntercomDefaultLauncherVisibility'

type Props = {
  children: ReactNode
}

const Provider = ({ children }: Props) => {
  const { incrementUnreadCount, resetUnreadCount, setMessagesVisible } = useIntercomState()
  const { isSmallOnly } = useBreakpoints()

  const handleHide = useCallback(() => {
    setMessagesVisible(false)
  }, [setMessagesVisible])

  const handleShow = useCallback(() => {
    resetUnreadCount()

    setMessagesVisible(true)
  }, [resetUnreadCount, setMessagesVisible])

  const handleUnreadCountChange = useCallback(
    (newMessagesCount: number) => {
      incrementUnreadCount(newMessagesCount)
    },
    [incrementUnreadCount],
  )

  return (
    <IntercomProviderPackage
      appId={getEnv('INTERCOM_APP_ID')}
      autoBoot
      autoBootProps={{
        hideDefaultLauncher: true,
        verticalPadding: isSmallOnly ? 80 : 20,
      }}
      onHide={handleHide}
      onShow={handleShow}
      onUnreadCountChange={handleUnreadCountChange}
    >
      <IntercomDefaultLauncherVisibility />

      {children}
    </IntercomProviderPackage>
  )
}

export const IntercomProvider = ({ children }: Props) => (
  <IntercomStateProvider>
    <Provider>{children}</Provider>
  </IntercomStateProvider>
)
