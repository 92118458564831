import { COLOR_MENTAL, COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PathPsychiatrySolid = ({ width }: Props) => (
  <svg
    data-test-id="icon-path-psychiatry-solid"
    fill="none"
    height={width}
    viewBox="0 0 48 48"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 0C4.8 0 0 4.80459 0 24.023C0 43.2414 4.79999 48 24 48C43.2 48 48 43.1955 48 23.9771C48 4.75877 43.2 0 24 0Z"
      fill={COLOR_MENTAL}
    />
    <circle cx="24" cy="24" r="13.467" stroke={COLOR_WHITE} strokeWidth="2.4" />
    <circle cx="24" cy="30.667" r="6.8" stroke={COLOR_WHITE} strokeWidth="2.4" />
  </svg>
)

export const IconPathPsychiatrySolid = (props: IconCommonProps) => <Icon Svg={PathPsychiatrySolid} {...props} />
