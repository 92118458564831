import {
  COLOR_BLACK,
  COLOR_GREY_120,
  COLOR_NUTRITION,
  COLOR_PRIMARY,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_30,
  COLOR_WHITE,
} from 'design-tokens'
import { Svg } from './components/Svg'

type Props = {
  size?: number
}

export const PathDCA = ({ size = 164 }: Props) => (
  <Svg fill="none" height={size} viewBox="0 0 164 164" width={size}>
    <g clipPath="url(#path-dca-a)">
      <path d="M119.15 52.72c.71 1.68 1.39 6.74-1.57 13.54" stroke={COLOR_NUTRITION} strokeWidth="1.29" />
      <path
        clipRule="evenodd"
        d="M113.7 59.51c2.6 1.27 4 4.02 3.64 6.74l.38.19.23.11a6.56 6.56 0 0 1 10.41 7.8l-.13.3c-1.94 4-1.6 9.96-1.27 15.81l.1 1.95A28.56 28.56 0 1 1 91.12 75.9l2.44-1.82c4.39-3.24 8.84-6.54 10.92-10.27a6.57 6.57 0 0 1 9.23-4.29Z"
        fill="url(#path-dca-b)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M52.22 87.76A15.07 15.07 0 0 0 26 102.59c4.19 10.86 21.26 29.79 36.24 26.52 16.15-3.54 23.75-31.58 21.53-41.71-.05-.25-.11-.49-.18-.72l-.16-.84a15.06 15.06 0 0 0-29.7 1.6 96.76 96.76 0 0 1-1.51.32Z"
        fill="url(#path-dca-c)"
        fillRule="evenodd"
      />
      <path
        d="M49.71 75.98a.35.35 0 0 1 .27-.42l.7-.15c.19-.05.38.08.42.27l2.57 11.77-1.38.3-2.58-11.77Z"
        fill={COLOR_GREY_120}
      />
      <path
        clipRule="evenodd"
        d="M49.8 76.73a7.98 7.98 0 0 1 12.08-8.75 7.98 7.98 0 0 1-12.07 8.75Z"
        fill={COLOR_NUTRITION}
        fillRule="evenodd"
      />
      <path
        d="m61.95 68.04-11.91 8.6M58.59 66.78l-.56 4.12M55.67 66.7l-.45 6.26M53.4 67.92l-1 7.1M50.66 70.33l-.18 5.84"
        stroke={COLOR_WHITE}
        strokeWidth=".35"
      />
      <path
        d="M81.59 136.14c31.93 0 57.81-15.13 57.81-33.8H23.78c0 18.67 25.88 33.8 57.81 33.8Z"
        fill="url(#path-dca-d)"
      />
      <path d="M81.59 136.14c9.49 0 17.18 3.64 17.18 8.13H64.4c0-4.5 7.7-8.13 17.18-8.13Z" fill="url(#path-dca-e)" />
      <path
        d="M122.5 24.17c28.05-15.06 94.6 15.85 112.56 28.4l-9.08 33.75c-24.4-16.1-73.18-31-82.7-31.65a115.99 115.99 0 0 1-11.04 14.93c-5.71 3.12-5.84-3.66-5.2-7.77-4.15 0-5.4-4.33-5.18-5.85-3.12 1.56-4.55 0-5.2-1.3-.52 3.1-4.68 3.9-5.84 1.3-7.79-17.5 3.88-27.62 11.69-31.81Z"
        fill={COLOR_WHITE}
        stroke={COLOR_BLACK}
        strokeWidth="1.14"
      />
      <path
        d="M135.49 47.53c-2.38 2.82-7.4 9.6-8.44 14.28M129 39.74c-2.17 2.6-6.62 9.61-7.14 16.88M122.5 33.9c-3.02 3.46-8.43 12.6-5.83 21.42"
        stroke={COLOR_BLACK}
        strokeWidth="1.14"
      />
    </g>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-dca-b" x1="112.5" x2="111.49" y1="128.5" y2="66.48">
        <stop stopColor={COLOR_PRIMARY} stopOpacity=".8" />
        <stop offset=".47" stopColor={COLOR_PRIMARY_30} stopOpacity=".89" />
        <stop offset=".96" stopColor={COLOR_PRIMARY_10} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-dca-c" x1="61.07" x2="56.5" y1="122.61" y2="77.8">
        <stop stopColor={COLOR_PRIMARY} />
        <stop offset="1" stopColor={COLOR_PRIMARY_10} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-dca-d" x1="81.59" x2="81.59" y1="102.34" y2="136.14">
        <stop stopColor={COLOR_NUTRITION} />
        <stop offset="1" stopColor={COLOR_NUTRITION} stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-dca-e" x1="81.59" x2="81.59" y1="144.27" y2="136.14">
        <stop stopColor={COLOR_NUTRITION} />
        <stop offset="1" stopColor={COLOR_NUTRITION} stopOpacity="0" />
      </linearGradient>
      <clipPath id="path-dca-a">
        <path d="M0 0h164v164H0z" fill={COLOR_WHITE} />
      </clipPath>
    </defs>
  </Svg>
)
