import { COLOR_BLACK, COLOR_MENTAL, COLOR_PRIMARY_30, COLOR_WHITE } from 'design-tokens'
import { Svg } from './components/Svg'

type Props = {
  size?: number
}

export const PathCouples = ({ size = 164 }: Props) => (
  <Svg fill="none" height={size} viewBox="0 0 164 164" width={size}>
    <circle cx="56.5" cy="55.63" fill="url(#path-couples-a)" r="34.63" transform="rotate(-90 56.5 55.63)" />
    <circle cx="116.98" cy="117.98" fill="url(#path-couples-b)" r="25.69" transform="rotate(-116 116.98 117.98)" />
    <path
      d="M40 97.43c-4.09-13.29 2.56-28.54 6.39-34.5l30.7-13.85 37.43 42.58-23.74 36.03S44.09 110.72 40 97.43Z"
      fill={COLOR_WHITE}
      stroke={COLOR_BLACK}
      strokeWidth="1.46"
    />
    <path
      d="M46.39 53.99c-4.09 8.17-.45 10.54 2.53 10.54-.74 6.3 5.56 5.64 8.97 4.79 0 5.6 5.11 7.24 8.94 7.67-1.02 11.24 9.74 11.12 14.06 7.66 4.75-3.8 9.2-8.63 11.5-5.11 1.9 2.92.03 33.21-.83 48.55 0 0 29.63 13.75 32.77-16.6 3.14-30.36-10.22-62.61-30.66-71.56C73.22 31 51.5 43.76 46.39 54Z"
      fill={COLOR_WHITE}
      stroke={COLOR_BLACK}
      strokeWidth="1.46"
    />
    <path d="M66.83 76.99c2.13-11.93 13.3-31.44 40.89-14.06" stroke={COLOR_BLACK} strokeWidth="1.46" />
    <path d="M57.89 69.32c1.28-12.35 12.52-32.97 47.27-16.61" stroke={COLOR_BLACK} strokeWidth="1.46" />
    <path d="M48.95 64.2c1.27-7.66 9.45-23 31.94-23" stroke={COLOR_BLACK} strokeWidth="1.46" />
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-couples-a" x1="91.12" x2="21.87" y1="55.63" y2="55.63">
        <stop offset=".29" stopColor={COLOR_PRIMARY_30} />
        <stop offset="1" stopColor={COLOR_PRIMARY_30} stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-couples-b" x1="116.98" x2="116.98" y1="92.29" y2="143.67">
        <stop stopColor={COLOR_MENTAL} />
        <stop offset="1" stopColor={COLOR_MENTAL} stopOpacity="0" />
      </linearGradient>
    </defs>
  </Svg>
)
