import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const Document = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <g stroke={color} strokeWidth="1.4">
      <path d="M21.4379 8.3034c-1.1275.1672-3.1761.03195-4.6909-.11025-1.1232-.10545-2.0066-.9824-2.1167-2.1052-.1453-1.48255-.2803-3.46758-.1048-4.55182" />
      <path
        d="M16.5 13H7M14.5 17.5H7M14.684 1.54017c.4486.1395 1.8885.71085 3.9683 2.73914 1.9603 1.91184 2.5934 3.26924 2.7794 3.78519.0419 1.1207.0682 2.4255.0682 3.9355 0 4.0322-.1874 6.6021-.3584 8.1072-.125 1.1008-.9514 1.924-2.0517 2.0523-1.4075.1643-3.7064.3405-7.0898.3405-3.3835 0-5.6824-.1762-7.08992-.3405-1.10034-.1283-1.92668-.9515-2.05169-2.0523C2.68745 18.6021 2.5 16.0322 2.5 12c0-4.0322.18745-6.602.35839-8.10717.12501-1.10072.95136-1.92398 2.05169-2.05235C6.3176 1.67627 8.6165 1.5 12 1.5c.985 0 1.8781.01494 2.684.04017Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4379 8.3034c-1.1275.1672-3.1761.03195-4.6909-.11025-1.1232-.10545-2.0066-.9824-2.1167-2.1052-.1453-1.48255-.2803-3.46758-.1048-4.55182"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const IconDocument = (props: IconCommonProps) => <Icon Svg={Document} {...props} />
