import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const CheckmarkFilledSolid = ({ color, fill, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 24A12.04 12.04 0 0 1 .93 7.35 12.2 12.2 0 0 1 7.36.94C8.8.3 10.35 0 12 0a12.04 12.04 0 0 1 11.05 16.65A12.03 12.03 0 0 1 12.01 24Z"
      fill={fill}
    />
    <path
      d="M10.7 17.74c.43 0 .77-.2 1.03-.6l5.48-8.58c.08-.13.15-.26.2-.39.07-.14.1-.27.1-.4a.83.83 0 0 0-.33-.7 1.1 1.1 0 0 0-.73-.27c-.35 0-.65.2-.89.58l-4.9 7.87-2.32-2.97a1.27 1.27 0 0 0-.43-.4.99.99 0 0 0-.47-.1c-.3 0-.54.1-.73.3-.2.2-.29.45-.29.73 0 .28.1.54.31.8l2.9 3.54c.15.2.31.34.49.44.17.1.37.15.59.15Z"
      fill={color}
    />
  </svg>
)

export const IconCheckmarkFilledSolid = (props: IconCommonProps) => <Icon Svg={CheckmarkFilledSolid} {...props} />
