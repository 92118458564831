import { COLOR_BLACK, COLOR_MENTAL, COLOR_PRIMARY_30, COLOR_WHITE } from 'design-tokens'
import { Svg } from './components/Svg'

type Props = {
  size?: number
}

export const PathMyself = ({ size = 164 }: Props) => (
  <Svg fill="none" height={size} viewBox="0 0 164 164" width={size}>
    <path
      d="M74.43 72.61h68.95c1-6.69-7.29-7.6-11.56-7.23 1.51-5.78-2.11-10.84-7.99-10.84-.96-11.68-11.6-11.84-15.98-5.93 1.47-3.26.88-11.37-8.36-12.57-11.18-1.45-10.76 9.17-11.18 15.8-6.56.35-9.05 8-4.84 10.6-9.88.88-9.32 7.16-9.04 10.17Z"
      fill="url(#path-myself-a)"
    />
    <g clipPath="url(#path-myself-b)">
      <path
        d="M122.4 81.62c2.05-1.74-1.5-8.73-3.54-12.01l-.79 14.57c.6-.14 2.28-.83 4.33-2.56Z"
        fill={COLOR_WHITE}
        stroke={COLOR_BLACK}
        strokeWidth="1.25"
      />
      <path
        d="M118.7 61.73c3.77 35.6-11.16 47.12-19.1 48.44 30.86 3.46 35.96 29.4 34.65 41.94H29.5c0-26.15 23.37-38.86 35.05-41.94C49.8 99.19 48.66 81.16 47.22 73.74c-6.16-56.11 66.75-56.5 71.47-12.01Z"
        fill="url(#path-myself-c)"
        stroke="url(#path-myself-d)"
        strokeWidth="1.14"
      />
    </g>
    <path
      d="M105.49 106.36H12.1c-1.36-8.95 9.87-10.17 15.66-9.67-2.05-7.74 2.86-14.5 10.82-14.5 1.3-15.64 15.72-15.85 21.64-7.95-1.98-4.36-1.2-15.21 11.32-16.82C86.7 55.48 86.13 69.7 86.7 78.56c8.88.48 12.25 10.71 6.55 14.2 13.38 1.17 12.62 9.57 12.24 13.6Z"
      fill="url(#path-myself-e)"
    />
    <path
      d="M28.33 52.98h63.84c.93-6.22-6.75-7.08-10.7-6.73 1.4-5.38-1.97-10.08-7.4-10.08-.9-10.87-10.75-11.02-14.8-5.52 1.36-3.04.82-10.58-7.74-11.7-10.36-1.35-9.97 8.53-10.36 14.7-6.07.33-8.38 7.45-4.47 9.87-9.15.82-8.63 6.65-8.37 9.46Z"
      fill="url(#path-myself-f)"
    />
    <path
      d="M79.55 129.8h63.83c.94-6.21-6.74-7.07-10.7-6.72 1.4-5.38-1.96-10.08-7.4-10.08-.88-10.87-10.75-11.02-14.79-5.53 1.36-3.03.82-10.57-7.74-11.69-10.36-1.35-9.97 8.53-10.36 14.7-6.07.33-8.38 7.44-4.47 9.87-9.15.82-8.63 6.65-8.37 9.46Z"
      fill="url(#path-myself-g)"
    />
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-myself-a" x1="74.32" x2="143.46" y1="54.26" y2="54.26">
        <stop offset=".29" stopColor={COLOR_PRIMARY_30} />
        <stop offset="1" stopColor={COLOR_PRIMARY_30} stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-myself-c" x1="82" x2="83.22" y1="146" y2="28.86">
        <stop offset=".02" stopColor={COLOR_WHITE} stopOpacity="0" />
        <stop offset=".17" stopColor={COLOR_WHITE} />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-myself-d" x1="83.22" x2="82" y1="30.14" y2="142">
        <stop offset=".8" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-myself-e" x1="12" x2="105.64" y1="81.8" y2="81.8">
        <stop stopColor={COLOR_MENTAL} />
        <stop offset="1" stopColor={COLOR_MENTAL} stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-myself-f" x1="28.22" x2="92.24" y1="35.9" y2="35.9">
        <stop offset=".29" stopColor={COLOR_PRIMARY_30} />
        <stop offset="1" stopColor={COLOR_PRIMARY_30} stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="path-myself-g" x1="79.44" x2="143.46" y1="112.73" y2="112.73">
        <stop offset=".29" stopColor={COLOR_PRIMARY_30} />
        <stop offset="1" stopColor={COLOR_PRIMARY_30} stopOpacity="0" />
      </linearGradient>
      <clipPath id="path-myself-b">
        <path d="M12 12h140v140H12z" fill={COLOR_WHITE} />
      </clipPath>
    </defs>
  </Svg>
)
