// src/lib/fromCents.ts
function fromCents(value) {
  if (Number.isNaN(value)) {
    return 0;
  }
  return value / 100;
}

// src/lib/isInteger.ts
function isInteger(input) {
  return Math.round(input) === input;
}

// src/lib/isNumber.ts
function isNumber(input) {
  return typeof input === "number";
}

// src/lib/toCents.ts
function toCents(value) {
  if (Number.isNaN(value)) {
    return 0;
  }
  return value * 100;
}

// src/lib/toCurrency.ts
import { fold, tryCatch } from "fp-ts/Either";
import { identity, pipe } from "fp-ts/function";
var options = {
  currency: "EUR",
  style: "currency"
};
var optionsWithNoDigits = {
  ...options,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
};
var optionsWithDigits = {
  ...options,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
};
var locale = "it-IT";
var toCurrency = (value) => pipe(
  value,
  fromCents,
  (v) => tryCatch(
    () => v.toLocaleString(locale, optionsWithNoDigits),
    () => v.toLocaleString(locale, options)
  ),
  fold(identity, identity)
);
var toCurrencyWithFractionDigits = (value) => pipe(
  value,
  fromCents,
  (v) => tryCatch(
    () => v.toLocaleString(locale, optionsWithDigits),
    () => v.toLocaleString(locale, options)
  ),
  fold(identity, identity)
);
var toCurrencyIntOrDecimal = (value) => pipe(
  value,
  fromCents,
  (v) => tryCatch(
    () => v.toLocaleString(locale, isInteger(v) ? optionsWithNoDigits : optionsWithDigits),
    () => v.toLocaleString(locale, options)
  ),
  fold(identity, identity)
);

// src/lib/toInt.ts
function toInt(value) {
  const integer = parseInt(String(value), 10);
  if (Number.isNaN(integer)) {
    return 0;
  }
  return integer;
}

// src/lib/toPercentage.ts
var toPercentage = ({ max = 100, min = 0, value }) => (value - min) * 100 / (max - min);
export {
  fromCents,
  isInteger,
  isNumber,
  toCents,
  toCurrency,
  toCurrencyIntOrDecimal,
  toCurrencyWithFractionDigits,
  toInt,
  toPercentage
};
