import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const DocumentEdit = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.815 7.863c-.165-.46-.726-1.672-2.47-3.381-1.847-1.812-3.126-2.322-3.524-2.446A75.855 75.855 0 0 0 11.438 2c-2.937 0-4.953.15-6.21.294a2.142 2.142 0 0 0-1.919 1.924C3.16 5.573 3 7.847 3 11.375s.16 5.802.31 7.157a2.142 2.142 0 0 0 1.917 1.924c.89.102 2.16.206 3.867.26"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M19.822 8.074c-.979.146-2.738.034-4.074-.09a2.186 2.186 0 0 1-1.982-1.973c-.126-1.314-.237-3.031-.085-3.979M20.813 16.652s-.222-.988-1.553-2.318c-1.33-1.331-2.318-1.553-2.318-1.553"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M22.062 15.403c.681-.681.942-1.677.372-2.454a8.33 8.33 0 0 0-.83-.96 8.285 8.285 0 0 0-.96-.83c-.776-.57-1.772-.309-2.453.373l-5.785 5.784a1.513 1.513 0 0 0-.459.938c-.04.487-.07 1.317.008 2.544a.9.9 0 0 0 .84.84c1.228.08 2.057.05 2.545.008a1.51 1.51 0 0 0 .938-.46l5.784-5.783Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconDocumentEdit = (props: IconCommonProps) => <Icon Svg={DocumentEdit} {...props} />
