// src/parsePath.ts
var parsePath = (path, baseUrl = "http://localhost") => {
  const { hash, pathname, search } = new URL(path, baseUrl);
  return {
    hash,
    pathname,
    search
  };
};

// src/parseQueryParams.ts
import { pipe } from "fp-ts/function";
import queryString from "query-string";
var parseQueryParams = (path) => {
  const query = pipe(path, parsePath, ({ search }) => queryString.parse(search));
  return Object.entries(query).filter(([key]) => !["altk"].includes(key.toLowerCase())).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: value ? String(value).trim() : "1"
    }),
    {}
  );
};
export {
  parsePath,
  parseQueryParams
};
