import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PathCoaching = ({ color, width }: Props) => (
  <svg
    data-test-id="icon-path-coaching"
    height={width}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M37.14 20a17.14 17.14 0 1 1-34.28 0 17.14 17.14 0 0 1 34.28 0ZM40 20a20 20 0 1 1-40 0 20 20 0 0 1 40 0Zm-12.86 0a7.14 7.14 0 1 1-14.28 0 7.14 7.14 0 0 1 14.28 0ZM30 20a10 10 0 1 1-20 0 10 10 0 0 1 20 0Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)

export const IconPathCoaching = (props: IconCommonProps) => <Icon Svg={PathCoaching} {...props} />
