import { COLOR_ACCENT, COLOR_MINTTEA_20, COLOR_PRIMARY } from 'design-tokens'
import { Svg } from './components/Svg'

export const GestoLogoHorizontal = () => (
  <Svg className="srns-gesto-light" fill="none" height="25" viewBox="0 0 81 25" width="81">
    <path
      d="M3.46 16.93C1.6 16.37.7 15.17.7 13.3c0-2.63 1.9-4.1 5.14-4.1h5.86v2.31h-1.55c-.59 0-1.17-.02-1.99-.05v.03c1.45.28 2.58.89 2.58 2.52 0 2.12-1.76 3.21-5.02 3.21-.77 0-1.46-.07-2.07-.25l-.25.4c-.18.28-.28.41-.28.61 0 .44.35.64 1.04.64 1.43 0 2.96-.08 4.28-.08 2.55 0 3.62 1.12 3.62 2.99 0 2.24-1.53 3.46-6.1 3.46C1.4 25 0 24.08 0 22.56c0-1.02 1-1.84 2.85-2.07-1.22-.13-1.83-.66-1.83-1.58 0-.61.51-1.25 1.5-1.58l.95-.4Zm.49 3.61c-1.25 0-1.81.59-1.81 1.18 0 .94.71 1.25 3.77 1.25 3.29 0 3.95-.46 3.95-1.43 0-.54-.33-1-1.32-1H3.95Zm-.8-7.08c0 1.22.67 1.96 2.58 1.96 1.91 0 2.63-.74 2.63-1.96s-.9-1.99-2.65-1.99c-1.76 0-2.55.77-2.55 2ZM14.09 15.35c0-4.05 2.37-6.45 6.11-6.45 3.75 0 5.48 2.3 5.48 5.58v.02c0 .49.03.95-.05 1.58h-8.8c.26 2.02 1.38 3.01 3.4 3.01 2.01 0 2.65-.94 2.98-2.17l2.4.7c-.62 2.26-2.25 3.87-5.4 3.87-3.67 0-6.12-2.17-6.12-6.14Zm9.04-1.3c-.15-1.89-1.14-2.83-3-2.83s-2.96.9-3.26 2.83h6.26ZM28.2 17.38l2.58-.5c.38 1.5 1.14 2.52 3.05 2.52 1.45 0 2.17-.56 2.2-1.5 0-.9-.6-1.33-3.01-1.76-3.24-.61-4.39-1.76-4.39-3.67 0-2.17 1.58-3.57 4.72-3.57 3.13 0 4.61 1.43 5.12 3.7l-2.62.58c-.39-1.55-1.07-2.24-2.5-2.24-1.3 0-1.99.5-1.99 1.4 0 .82.46 1.4 2.9 1.81 3.34.61 4.46 1.84 4.46 3.67 0 2.24-1.55 3.7-4.94 3.7-3.82 0-5.17-1.82-5.58-4.13ZM41.1 9.2h1.62V6.03h2.73v3.19h3.54v2.31h-3.54v5.56c0 1.15.46 1.76 1.66 1.76.61 0 1.17-.16 1.7-.28l.34 2.52c-.9.28-1.48.43-2.68.43-2.62 0-3.74-1.73-3.74-3.97v-6.02H41.1V9.2ZM51.36 15.17c0-3.95 2.44-6.27 6.14-6.27 3.7 0 6.11 2.32 6.11 6.27 0 3.95-2.31 6.34-6.11 6.34-3.8 0-6.14-2.4-6.14-6.34Zm2.75-.03c0 2.32 1.2 3.85 3.36 3.85 2.17 0 3.42-1.53 3.42-3.85 0-2.32-1.27-3.74-3.4-3.74-2.1 0-3.38 1.47-3.38 3.74ZM80.13 0H72.6a.28.28 0 0 0-.17.06c-.42.3-2.47 1.86-4.1 4.84.02 0 6.33 3.2 6.35 8.57A19.12 19.12 0 0 1 80.33.6c.21-.2.08-.59-.2-.59Z"
      fill={COLOR_PRIMARY}
    />
    <path
      d="M68.34 4.9h-7.1c-.32 0-.43.45-.16.62 1.8 1.13 5.14 3.77 5.14 7.97 0-3.62.96-6.47 2.12-8.6Z"
      fill={COLOR_MINTTEA_20}
    />
    <path
      d="M68.34 4.9a17.63 17.63 0 0 0-2.12 8.6v.03c0 .35.26.63.58.63h7.31c.32 0 .58-.28.58-.63v-.06c-.02-5.36-6.33-8.56-6.35-8.57Z"
      fill={COLOR_ACCENT}
    />
  </Svg>
)
