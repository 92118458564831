import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisCheck = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 16 16" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="m12 5-5.5 6L4 8.27" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
)

export const IconSerenisCheck = (props: IconCommonProps) => <Icon Svg={SerenisCheck} {...props} />
