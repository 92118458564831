import { COLOR_GREY_40, COLOR_WHITE } from 'design-tokens'
import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PaymentCardVisa = ({ width }: Props) => (
  <svg fill="none" viewBox="0 0 50 34" width={width} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z" fill={COLOR_WHITE} />
    <path
      d="M1 5a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V5Z"
      stroke={COLOR_GREY_40}
      strokeWidth=".5"
    />
    <path
      clipRule="evenodd"
      d="M30.56 11.6a7.7 7.7 0 0 1 2.61.45l-.4 2.26-.26-.11a5.3 5.3 0 0 0-2.17-.4c-1.15 0-1.67.45-1.68.9 0 .48.62.8 1.64 1.28 1.68.75 2.46 1.66 2.45 2.85-.02 2.16-2.01 3.56-5.07 3.56a9.5 9.5 0 0 1-3.26-.55l.41-2.35.39.17a6.2 6.2 0 0 0 2.73.55c.84 0 1.74-.32 1.75-1.02 0-.45-.38-.79-1.5-1.3-1.11-.5-2.59-1.33-2.56-2.83 0-2.03 2.04-3.45 4.92-3.45Zm10.09.2h-2.26c-.7 0-1.22.19-1.52.86l-4.33 9.58h3.06l.6-1.57h3.75l.35 1.57H43L40.65 11.8Zm-3.6 6.74 1.16-2.92.1-.24.29-.76.2.9.67 3.02h-2.42Zm-14.45 3.7h-2.92l1.83-10.44h2.91L22.6 22.24ZM17.25 11.8l-2.86 7.12-.31-1.45-1.02-4.8c-.17-.66-.68-.85-1.32-.87h-4.7L7 12c1.14.28 2.17.67 3.06 1.15l2.6 9.08h3.09l4.58-10.43h-3.08Z"
      fill="#1434CB"
      fillRule="evenodd"
    />
  </svg>
)

export const IconPaymentCardVisa = (props: IconCommonProps) => <Icon Svg={PaymentCardVisa} {...props} />
