import {
  FloatingFocusManager,
  FloatingOverlay as FloatingUiFloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { Flex, type FlexProps, media, PositionAbsolute, PositionSticky, Pressable } from 'cdk'
import {
  BORDER_RADIUS_SM,
  COLOR_BLACK,
  COLOR_LIGHTER,
  OPACITY_48,
  SPACING_0,
  SPACING_3XL,
  SPACING_6XL,
  SPACING_LG,
  SPACING_MD,
  SPACING_XL,
  TIME_300_VALUE,
} from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { Icon, SerenisClose } from 'icons'
import {
  cloneElement,
  createContext,
  forwardRef,
  type HTMLProps,
  isValidElement,
  type PropsWithChildren,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import { Card } from './Card'
import { Text, type TextProps } from './Text'

/**
 * NOTE(@heavybeard): Drawer will be aligned to Modal restyle
 * @see https://linear.app/serenis/issue/ENG-1235/align-drawer-to-new-design
 */

type DrawerOptions = {
  isOpen?: boolean
  onIsOpenChange?: (isOpen: boolean) => void
}

const useDrawer = ({ isOpen: controlledIsOpen, onIsOpenChange }: DrawerOptions) => {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(controlledIsOpen ?? false)
  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()
  const { isSmallOnly } = useBreakpoints()

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen
  const setIsOpen = onIsOpenChange ?? setUncontrolledIsOpen

  const data = useFloating({ open: isOpen, onOpenChange: setIsOpen })
  const context = data.context

  const click = useClick(context, { enabled: controlledIsOpen == null })
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const role = useRole(context)
  const interactions = useInteractions([click, dismiss, role])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const { isMounted, styles: modalContainerStyles } = useTransitionStyles(context, {
    duration: parseInt(TIME_300_VALUE),
    initial: { transform: isSmallOnly ? 'translateY(100%)' : 'translateX(100%)', filter: 'blur(3.5px)' },
  })
  const { styles: floatingOverlayStyles } = useTransitionStyles(context, {
    initial: { opacity: '0' },
  })

  return useMemo(
    () => ({
      close,
      isOpen,
      setIsOpen,
      ...interactions,
      ...data,
      isMounted,
      modalContainerStyles,
      floatingOverlayStyles,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [
      close,
      isOpen,
      setIsOpen,
      interactions,
      data,
      isMounted,
      modalContainerStyles,
      floatingOverlayStyles,
      labelId,
      descriptionId,
    ],
  )
}

type Context = ReturnType<typeof useDrawer> | null

const DrawerContext = createContext<Context>(null)

export const useDrawerContext = () => {
  const context = useContext(DrawerContext)

  if (context === null) {
    throw new Error('Drawer components must be wrapped in <DrawerProvider />')
  }

  return context
}

export const DrawerFooter = forwardRef<HTMLElement, Partial<FlexProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setLabelId } = useDrawerContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setLabelId(id)

      return () => setLabelId(undefined)
    }, [id, setLabelId])

    return (
      <PositionSticky
        ref={ref}
        $backgroundColorName="lighter"
        $bottom={0}
        $direction="row"
        $justify="space-between"
        $pb={SPACING_XL}
        $pt={SPACING_MD}
        id={id}
        {...itemProps}
      >
        {children}
      </PositionSticky>
    )
  },
)

export const DrawerHeader = forwardRef<
  HTMLElement,
  Partial<FlexProps> & HTMLProps<HTMLElement> & { hideCloseButton?: boolean }
>(({ children, hideCloseButton = false, ...props }, propRef) => {
  const { close, getItemProps, setLabelId } = useDrawerContext()
  const id = useId()
  const ref = useMergeRefs([propRef])
  const itemProps = getItemProps(props)

  useLayoutEffect(() => {
    setLabelId(id)

    return () => setLabelId(undefined)
  }, [id, setLabelId])

  return (
    <PositionSticky
      ref={ref}
      $backgroundColorName="lighter"
      $direction="row"
      $justify="space-between"
      $py={SPACING_LG}
      $top={0}
      $zIndex={1}
      id={id}
      {...itemProps}
    >
      {children}
      {!hideCloseButton && (
        <PositionAbsolute $right={SPACING_0} $top={SPACING_LG} $zIndex={1}>
          <Pressable aria-label="close drawer" data-test-id="drawer-close" onClick={close}>
            <Flex>
              <Icon Svg={SerenisClose} size={24} />
            </Flex>
          </Pressable>
        </PositionAbsolute>
      )}
    </PositionSticky>
  )
})

export const DrawerTitle = forwardRef<HTMLElement, Partial<TextProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setLabelId } = useDrawerContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setLabelId(id)

      return () => setLabelId(undefined)
    }, [id, setLabelId])

    return (
      <Text as="h2" kind="h2" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export const DrawerTrigger = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement> & PropsWithChildren>(
  ({ children, ...props }, propRef) => {
    const { getReferenceProps, isOpen, refs } = useDrawerContext()
    const childrenRef = (children as any).ref
    const ref = useMergeRefs([refs.setReference, propRef, childrenRef])
    const otherProps = {
      'data-state': isOpen ? 'open' : 'closed',
    }

    if (isValidElement(children)) {
      return cloneElement(
        children,
        getReferenceProps({
          ref,
          ...props,
          ...children.props,
          ...otherProps,
        }),
      )
    }

    return (
      <button ref={ref} {...otherProps} {...getReferenceProps(props)}>
        {children}
      </button>
    )
  },
)

// Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
const FloatingOverlay = styled(FloatingUiFloatingOverlay)`
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  ${media.gtSm`
    align-items: center;
    justify-content: flex-end;
  `}

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: ${COLOR_BLACK};
    opacity: ${OPACITY_48};
  }
`

const DrawerContainer = styled(Card)`
  ${media.gtSm`
    inset: ${SPACING_MD} ${SPACING_MD} ${SPACING_MD} auto;
    width: calc(100% - ${SPACING_6XL});
    max-width: 600px;
    max-height: none;
    border-bottom-left-radius: ${BORDER_RADIUS_SM};
    border-bottom-right-radius: ${BORDER_RADIUS_SM};
  `}
`

const DrawerContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  min-height: 0;
  padding: ${SPACING_0} ${SPACING_LG};
  background-color: ${COLOR_LIGHTER};
`

export const Drawer = forwardRef<HTMLDivElement, PropsWithChildren>(({ children, ...props }, propRef) => {
  const {
    context,
    descriptionId,
    floatingOverlayStyles,
    getFloatingProps,
    isMounted,
    labelId,
    modalContainerStyles,
    refs,
  } = useDrawerContext()

  const ref = useMergeRefs([refs.setFloating, propRef])

  if (!isMounted) {
    return null
  }

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll style={floatingOverlayStyles}>
        <FloatingFocusManager context={context}>
          <DrawerContainer
            ref={ref}
            $borderRadiusBottomLeft={0}
            $borderRadiusBottomRight={0}
            $bottom={0}
            $left={0}
            $maxHeight={`calc(100% - ${SPACING_3XL})`}
            $overflow="hidden"
            $p={SPACING_0}
            $position="fixed"
            $right={0}
            $zIndex={102}
            aria-describedby={descriptionId}
            aria-labelledby={labelId}
            aria-modal="true"
            {...getFloatingProps(props)}
            style={modalContainerStyles}
          >
            <DrawerContentWrapper>{children}</DrawerContentWrapper>
          </DrawerContainer>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})

export type DrawerProps = PropsWithChildren<DrawerOptions>

export const DrawerProvider = ({ children, ...options }: DrawerProps) => {
  const modal = useDrawer(options)

  return <DrawerContext.Provider value={modal}>{children}</DrawerContext.Provider>
}
