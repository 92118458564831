import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisTicketsSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M11.5536 4.33665c-.6102.15671-1.2658.32864-1.96967.51723-3.92139 1.05073-6.28726 1.8011-7.65745 2.28036C.595996 7.59963-.198469 8.8767.0430591 10.2912c.1069419.6262.2657509 1.4393.5016619 2.4785.179034.7887.859539 1.2162 1.431909 1.3707.72342.1953 1.31939.7553 1.52447 1.5207.20507.7653-.03103 1.5483-.55991 2.0791-.41844.42-.79399 1.1305-.55471 1.903.3153 1.018.5843 1.8015.80481 2.3973.49807 1.3458 1.82463 2.0545 3.20957 1.7923 1.42624-.27 3.85034-.8031 7.77174-1.8539.7038-.1886 1.3576-.3675 1.9644-.5369l-.4934-1.8415c-.1199-.4473.1456-.9071.5929-1.027.4474-.1199.9072.1456 1.027.5929l.4866 1.8161c1.8816-.5475 3.1906-.9728 4.08-1.2839 1.3304-.4654 2.1249-1.7425 1.8834-3.157-.107-.6262-.2658-1.4392-.5017-2.4784-.179-.7887-.8596-1.2163-1.432-1.3707-.7234-.1953-1.3194-.7553-1.5245-1.5207-.2051-.7654.0311-1.54843.56-2.07922.4184-.41999.794-1.13055.5548-1.90306-.3154-1.01793-.5843-1.80143-.8049-2.39725C20.0672 3.44653 18.7406 2.7378 17.3557 3c-.9258.17529-2.2721.46142-4.1753.928l.4866 1.8161c.1199.44733-.1456.90714-.5929 1.027-.4474.11986-.9072-.1456-1.027-.59294l-.4935-1.84151Zm1.9545 4.05439c.4474-.11986.9072.1456 1.027.59294l.4961 1.85132c.1199.4474-.1456.9072-.5929 1.027-.4474.1199-.9072-.1456-1.027-.5929l-.4961-1.85136c-.1199-.44734.1456-.90714.5929-1.027Zm2.3912 5.68416c-.1198-.4473-.5796-.7128-1.027-.5929-.4473.1198-.7128.5796-.5929 1.027l.4961 1.8513c.1198.4474.5796.7128 1.027.593.4473-.1199.7128-.5797.5929-1.027l-.4961-1.8514Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      d="M12.8494.352387c-1.209.702723-3.25747 1.975373-6.52463 4.217743L16.447 2.00002c-.3683-.48116-.6825-.87435-.947-1.193588-.6703-.80930977-1.7441-.981636-2.6506-.454045Z"
      fill={color}
    />
  </svg>
)

export const IconSerenisTicketsSolid = (props: IconCommonProps) => <Icon Svg={SerenisTicketsSolid} {...props} />
