import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const PenTool = ({ color, width }: Props) => (
  <svg data-test-id="icon-pen-tool" fill="none" viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7.47c.16-1.96.8-3.9 1.13-4.85a.8.8 0 0 1 .86-.54 9.3 9.3 0 0 1 5.26 2.67A9.33 9.33 0 0 1 21.92 10a.8.8 0 0 1-.54.86c-.94.34-2.9.97-4.85 1.13 0 0-.45-1.36-1.81-2.72S12 7.47 12 7.47Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M16.54 11.98s-.45-1.36-1.8-2.71a7.76 7.76 0 0 0-2.72-1.81m4.52 4.52a49.16 49.16 0 0 1-.6 5.67 2.58 2.58 0 0 1-1.83 2.04c-6.35 1.86-10.24 2.25-10.24 2.25s-.68.22-1.36-.46c-.68-.67-.45-1.35-.45-1.35s.39-3.9 2.25-10.24a2.58 2.58 0 0 1 2.03-1.84c1.25-.2 3.19-.47 5.68-.6m4.52 4.53s0 0 0 0Zm-4.52-4.52s0 0 0 0ZM8.2 15.8l-5.7 5.7"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M7.72 14.38a1.9 1.9 0 1 0 3.8 0 1.9 1.9 0 0 0-3.8 0Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconPenTool = (props: IconCommonProps) => <Icon Svg={PenTool} {...props} />
