// src/lib/chunk.ts
var chunk = (size) => (value) => {
  const chunks = [];
  for (let i = 0; i < value.length; i += size) {
    chunks.push(value.slice(i, i + size));
  }
  return chunks;
};

// src/lib/distinct.ts
var distinct = (value) => [...new Set(value)];

// src/lib/fromCommaSeparatedList.ts
var fromCommaSeparatedList = (value) => value.split(",").map((v) => v.trim());

// src/lib/groupByToMap.ts
var groupByToMap = (list, getKey) => list.reduce(
  (previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
  },
  {}
);

// src/lib/groupByToEntries.ts
var groupByToEntries = (list, getKey) => Object.entries(groupByToMap(list, getKey));

// src/lib/pickRandom.ts
var pickRandom = (items) => items[Math.floor(Math.random() * items.length)];

// src/lib/range.ts
var range = (size, mapFunction) => Array.from({ length: size }, (_, index) => mapFunction(index));
var rangeWithDefault = (size) => Array.from({ length: size }, (_, index) => index);

// src/lib/shuffle.ts
var createSeededRandom = (seed) => function() {
  const a = 1664525;
  const c = 1013904223;
  const m = 2 ** 32;
  seed = (a * seed + c) % m;
  return seed / m;
};
var shuffle = (array, seed) => {
  const random = createSeededRandom(seed);
  return array.map((value) => ({ value, sort: random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
};
export {
  chunk,
  distinct,
  fromCommaSeparatedList,
  groupByToEntries,
  groupByToMap,
  pickRandom,
  range,
  rangeWithDefault,
  shuffle
};
