import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisTickets = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="m13.5578 4.99699.4549 1.70317m1.9412 7.26684.4853 1.8167m.7279 2.725.455 1.7032M14.7407 9.42521l.4853 1.81669m2.4476-7.50964c-.6154-.83045-1.1102-1.45767-1.4981-1.92583-.6703-.809307-1.7441-.981634-2.6506-.45404-1.209.70272-3.2575 1.97537-6.52465 4.21774-1.05316.72283-1.96679 1.3638-2.75796 1.9299M7.37613 22.8619c-1.03027.1966-1.98586-.3226-2.34934-1.3085-.2787-.7641-.53689-1.5355-.77432-2.3134-.12783-.4156.06382-.8529.37016-1.1606.34933-.3495.6008-.7849.72913-1.2624.12834-.4774.12901-.9802.00194-1.4577-.12766-.4773-.37848-.9122-.72724-1.2612-.34876-.349-.78317-.5996-1.25953-.7268-.41857-.113-.80221-.3955-.89821-.8192-.22782-1.0089-.3798-1.7935-.4816-2.3936-.17582-1.03602.39336-1.96563 1.38332-2.31336 1.32033-.46341 3.61845-1.19636 7.44486-2.22639 3.8265-1.03003 6.1814-1.55011 7.5551-1.81143 1.0303-.19658 1.9859.32257 2.3493 1.30856.2104.57042.4689 1.32637.7736 2.31408.1283.41544-.064.85247-.3702 1.16062-.3491.34955-.6003.78492-.7285 1.26235-.1282.47747-.1287.98007-.0016 1.45747.1276.4773.3784.9124.7272 1.2614.3488.3491.7832.5998 1.2596.727.4185.1126.8021.3951.8981.8188.1819.7934.3425 1.5915.4816 2.3936.1759 1.036-.3933 1.9656-1.3833 2.3134-1.3199.4632-3.6186 1.1959-7.4445 2.2258-3.8265 1.03-6.18183 1.5502-7.55557 1.8115Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisTickets = (props: IconCommonProps) => <Icon Svg={SerenisTickets} {...props} />
