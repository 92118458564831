import {
  FloatingFocusManager,
  FloatingOverlay as FloatingUiFloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { Flex, media, OverflowAuto, OverflowHidden, PositionFixed, Pressable } from 'cdk'
import {
  BORDER_RADIUS_2XL,
  BORDER_RADIUS_MD,
  COLOR_BLACK,
  OPACITY_48,
  SPACING_MD_VALUE,
  TIME_300_VALUE,
} from 'design-tokens'
import { AnimatePresence, motion, useDragControls } from 'framer-motion'
import {
  type ButtonHTMLAttributes,
  cloneElement,
  createContext,
  forwardRef,
  type HTMLProps,
  isValidElement,
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { Text, type TextProps } from './Text'

type BottomSheetOptions = {
  initialIsOpen?: boolean
  isOpen?: boolean
  onIsOpenChange?: (isOpen: boolean) => void
  stepHeights?: number[]
  preventClosing?: boolean
  preventClosingOutside?: boolean
  maxHeight?: number
  allowClickOutside?: boolean
  withBackgroud?: boolean
}

type BottomSheetTriggerProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode
}

const FloatingOverlay = styled(FloatingUiFloatingOverlay)<{ allowClickOutside?: boolean; withBackgroud?: boolean }>`
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: ${({ allowClickOutside, withBackgroud }) => {
    if (withBackgroud) {
      return 'auto'
    }
    return allowClickOutside ? 'none' : 'auto'
  }};

  ${({ withBackgroud }) => {
    if (withBackgroud) {
      return css`
        &::before {
          content: '';
          position: fixed;
          inset: 0;
          background-color: ${COLOR_BLACK};
          opacity: ${OPACITY_48};
        }
      `
    }
  }}

  ${media.gtSm`
    align-items: center;
    justify-content: center;
  `}
`

const BottomSheetContainer = styled(OverflowHidden)`
  width: 100%;
  height: 100%;
  pointer-events: auto;
  user-select: text;
  transform: none;
`

const DragHandle = styled(Flex)`
  width: 40px;
  height: 4px;
  margin: 12px auto;
  cursor: grab;
`

const ModalContainer = styled(PositionFixed)`
  width: 100%;
  margin: 0 auto;
`

export function useBottomSheet({
  initialIsOpen = false,
  isOpen: controlledIsOpen,
  onIsOpenChange: setControlledIsOpen,
  preventClosing = false,
  preventClosingOutside = true,
  stepHeights = [200, 500],
  maxHeight = typeof window !== 'undefined' ? window.innerHeight * 0.9 : 800,
  allowClickOutside = false,
  withBackgroud = true,
}: BottomSheetOptions) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(initialIsOpen)
  const isOpen = controlledIsOpen ?? uncontrolledIsOpen
  const [labelId, setLabelId] = useState<string | undefined>()
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [descriptionId, setDescriptionId] = useState<string | undefined>()
  const [height, setHeight] = useState(() => stepHeights[0])

  const setIsOpen = useCallback(
    (open: boolean) => {
      if (setControlledIsOpen) {
        setControlledIsOpen(open)
      } else {
        setUncontrolledIsOpen(open)
      }
    },
    [setControlledIsOpen],
  )

  const data = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
  })

  const click = useClick(data.context, { enabled: controlledIsOpen == null })
  const dismiss = useDismiss(data.context, {
    enabled: !preventClosing,
    outsidePress: !preventClosingOutside,
    outsidePressEvent: 'mousedown',
  })
  const role = useRole(data.context)
  const interactions = useInteractions([click, dismiss, role])

  const close = useCallback(() => {
    if (preventClosing) return

    setCurrentStepIndex(0)
    setIsOpen(false)
  }, [preventClosing, setIsOpen])

  const { isMounted, styles: bottomSheetContainerStyles } = useTransitionStyles(data.context, {
    duration: parseInt(TIME_300_VALUE),
    initial: { translate: '0 100%', filter: 'blur(3.5px)' },
  })

  const moveToNextStep = useCallback(() => {
    if (currentStepIndex < stepHeights.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1)
      return true
    }
    return false
  }, [currentStepIndex, stepHeights])

  const moveToPreviousStep = useCallback(() => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1)
      return true
    }
    return false
  }, [currentStepIndex])

  const getCurrentHeight = useCallback(
    () => (height === 0 ? 0 : stepHeights[currentStepIndex]),
    [height, currentStepIndex, stepHeights],
  )

  return useMemo(
    () => ({
      close,
      isOpen,
      preventClosing,
      preventClosingOutside,
      setIsOpen,
      stepHeights,
      maxHeight,
      moveToNextStep,
      moveToPreviousStep,
      currentStepIndex,
      setCurrentStepIndex,
      getCurrentHeight,
      allowClickOutside,
      withBackgroud,
      setLabelId,
      descriptionId,
      setDescriptionId,
      labelId,
      setHeight,
      height,
      isMounted,
      bottomSheetContainerStyles,
      ...interactions,
      ...data,
    }),
    [
      close,
      isOpen,
      preventClosing,
      preventClosingOutside,
      setIsOpen,
      stepHeights,
      maxHeight,
      moveToNextStep,
      moveToPreviousStep,
      currentStepIndex,
      setCurrentStepIndex,
      getCurrentHeight,
      allowClickOutside,
      withBackgroud,
      setLabelId,
      labelId,
      descriptionId,
      setDescriptionId,
      interactions,
      data,
      setHeight,
      height,
      isMounted,
      bottomSheetContainerStyles,
    ],
  )
}

type ContextType = ReturnType<typeof useBottomSheet> | null

const BottomSheetContext = createContext<ContextType>(null)

export const useBottomSheetContext = () => {
  const context = useContext(BottomSheetContext)

  if (context === null) {
    throw new Error('BottomSheet components must be wrapped in <BottomSheetProvider />')
  }

  return context
}

export const BottomSheetTrigger = forwardRef<HTMLButtonElement, BottomSheetTriggerProps>(
  ({ children, ...props }, propRef) => {
    const { getReferenceProps, isOpen, refs } = useBottomSheetContext()

    const ref = useMergeRefs([refs.setReference, propRef, (children as any).ref])
    const otherProps = {
      'data-state': isOpen ? 'open' : 'closed',
    }

    if (isValidElement(children)) {
      return cloneElement(
        children,
        getReferenceProps({
          ref,
          ...props,
          ...(isValidElement(children) && typeof children.props === 'object' ? children.props : {}),
          ...otherProps,
        }),
      )
    }

    return (
      <Pressable ref={ref} {...otherProps} {...getReferenceProps(props)}>
        {children}
      </Pressable>
    )
  },
)

export const BottomSheetTop = forwardRef<HTMLElement, Partial<TextProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps } = useBottomSheetContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    return (
      <Text as="p" kind="paragraph" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export const BottomSheetTitle = forwardRef<HTMLElement, TextProps & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setLabelId } = useBottomSheetContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setLabelId(id)

      return () => setLabelId(undefined)
    }, [id, setLabelId])

    return (
      <Text as="h2" kind="h1" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export const BottomSheetDescription = forwardRef<HTMLElement, TextProps & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setDescriptionId } = useBottomSheetContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setDescriptionId(id)

      return () => setDescriptionId(undefined)
    }, [id, setDescriptionId])

    return (
      <Text as="p" kind="paragraph" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export type BottomSheetProps = PropsWithChildren

const handleDragEnd = (
  _: any,
  { offset }: { offset: { y: number } },
  {
    moveToNextStep,
    moveToPreviousStep,
    close,
    height,
    setHeight,
    stepHeights,
  }: {
    moveToNextStep: () => boolean
    moveToPreviousStep: () => boolean
    close: () => void
    height: number
    setHeight: (height: number) => void
    stepHeights: number[]
  },
) => {
  const dragThreshold = height * 0.4

  if (offset.y > 0) {
    const shouldClose = Math.abs(offset.y) > dragThreshold || !moveToPreviousStep()
    if (shouldClose) close()
  } else {
    const canMoveToNextStep = moveToNextStep()
    if (canMoveToNextStep) {
      const currentStepIndex = stepHeights.findIndex((h) => h === height)
      const nextStepHeight = stepHeights[currentStepIndex + 1] || stepHeights[stepHeights.length - 1]
      setHeight(nextStepHeight)
    }
  }
}

export const BottomSheet = forwardRef<HTMLElement, BottomSheetProps>(({ children, ...props }, propRef) => {
  const {
    isOpen,
    context,
    close,
    currentStepIndex,
    stepHeights,
    maxHeight,
    moveToNextStep,
    moveToPreviousStep,
    getCurrentHeight,
    preventClosingOutside,
    allowClickOutside,
    withBackgroud,
    setHeight,
    height,
    getFloatingProps,
    refs,
    descriptionId,
    labelId,
    bottomSheetContainerStyles,
    isMounted,
  } = useBottomSheetContext()
  const controls = useDragControls()
  const ref = useMergeRefs([refs.setFloating, propRef])

  const contentRef = useRef<HTMLDivElement>(null)
  const lastScrollPosition = useRef(0)
  const scrollThreshold = 20

  // Update height with animation when step index changes
  useEffect(() => {
    if (isOpen) {
      const newHeight = getCurrentHeight()
      setHeight(newHeight)
    }
  }, [setHeight, currentStepIndex, isOpen, getCurrentHeight])

  const wrappedHandleDragEnd = (event: any, info: { offset: { y: number }; velocity: { y: number } }) =>
    handleDragEnd(event, info, {
      moveToNextStep,
      moveToPreviousStep,
      close,
      height,
      setHeight,
      stepHeights,
    })

  const handleScroll = () => {
    if (!contentRef.current) {
      return
    }

    const { scrollTop } = contentRef.current
    const scrollingUp = scrollTop < lastScrollPosition.current

    if (scrollingUp && scrollTop <= scrollThreshold) {
      moveToNextStep()
    }

    lastScrollPosition.current = scrollTop
  }

  if (!isMounted) {
    return null
  }

  const heightTransition = {
    type: 'spring',
    stiffness: 350,
    damping: 30,
    mass: 1,
  }

  return (
    <FloatingPortal>
      <AnimatePresence>
        {isOpen && (
          <FloatingOverlay
            allowClickOutside={allowClickOutside}
            data-test-id="bottom-sheet-overlay"
            lockScroll
            onClick={(e) => {
              e.stopPropagation()
              if (!preventClosingOutside) close()
            }}
            withBackgroud={withBackgroud}
          >
            <FloatingFocusManager context={context} guards={false}>
              <ModalContainer
                ref={ref}
                $bottom={0}
                $left={0}
                $maxHeight={maxHeight}
                $maxWidth={500}
                $right={0}
                $zIndex={103}
                animate={{
                  y: 0,
                  height,
                  transition: {
                    height: heightTransition,
                  },
                }}
                aria-describedby={descriptionId}
                aria-labelledby={labelId}
                aria-modal="true"
                as={motion.div}
                drag="y"
                dragConstraints={{ top: 0 }}
                dragControls={controls}
                dragElastic={0}
                dragTransition={{
                  bounceStiffness: 0,
                  bounceDamping: 0,
                  power: 0,
                }}
                exit={{
                  y: '100%',
                  height,
                  transition: {
                    type: 'spring',
                    stiffness: 400,
                    damping: 35,
                  },
                }}
                initial={{ y: '100%' }}
                onDragEnd={wrappedHandleDragEnd}
                style={bottomSheetContainerStyles}
                transition={{ duration: 0.5, ease: [0.32, 0.72, 0, 1] }}
                {...getFloatingProps(props)}
              >
                <BottomSheetContainer
                  $backgroundColorName="lighter"
                  $borderRadius={`${BORDER_RADIUS_MD} ${BORDER_RADIUS_MD} 0 0`}
                  $elevationName="lg"
                  $maxWidth={500}
                  as={motion.div}
                  onClick={(e) => e.stopPropagation()}
                >
                  <DragHandle
                    $backgroundColorName="neutral-40"
                    $borderRadius={BORDER_RADIUS_2XL}
                    as={motion.div}
                    data-test-id="drag-handle"
                    onPointerDown={(event) => controls.start(event)}
                    transition={{ scale: { duration: 0.15 } }}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ cursor: 'grabbing' }}
                  />
                  <OverflowAuto
                    ref={contentRef}
                    $grow={1}
                    $p={`0 ${SPACING_MD_VALUE}`}
                    $shrink={1}
                    onPointerDownCapture={(event) => event.stopPropagation()}
                    onScroll={handleScroll}
                    tabIndex={0} // Make the scrollable content focusable
                  >
                    {children}
                  </OverflowAuto>
                </BottomSheetContainer>
              </ModalContainer>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </AnimatePresence>
    </FloatingPortal>
  )
})

export type BottomSheetProviderProps = PropsWithChildren<
  {
    children:
      | [ReactElement<typeof BottomSheetTrigger>, ReactElement<typeof BottomSheet>]
      | ReactElement<typeof BottomSheet>
  } & BottomSheetOptions
>

export const BottomSheetProvider = ({ children, ...options }: BottomSheetProviderProps) => {
  const bottomSheet = useBottomSheet(options)

  return <BottomSheetContext.Provider value={bottomSheet}>{children}</BottomSheetContext.Provider>
}
