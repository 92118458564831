import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const LogoFacebook = ({ height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 12C22 6.47716 17.5228 2 12 2C6.47716 2 2 6.47716 2 12C2 16.6898 5.2286 20.6249 9.58424 21.7055V15.0558H7.52216V12H9.58424V10.6832C9.58424 7.27956 11.1246 5.70196 14.4662 5.70196C15.0997 5.70196 16.1928 5.82616 16.64 5.9504V8.72056C16.404 8.69572 15.9941 8.68328 15.4848 8.68328C13.845 8.68328 13.2115 9.3044 13.2115 10.9193V12H16.4778L15.9167 15.0558H13.2115V21.9268C18.1629 21.3287 22 17.1127 22 12Z"
      fill="#0866FF"
    />
  </svg>
)

export const IconLogoFacebook = (props: IconCommonProps) => <Icon Svg={LogoFacebook} {...props} />
