import { type IconCommonProps, type IconElementProps } from '../types'
import Icon from './Icon'

type Props = IconElementProps

export const SerenisNotificationOff = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.25007 22.0254 21.2501 2.02539M9.4668 21.0039c.6969.6237 1.5993.9686 2.5345.9686s1.8376-.3449 2.5345-.9686m4.6833-12.35273c-.3215-3.74776-3.4571-6.62578-7.2182-6.62578-3.76108 0-6.89708 2.8785-7.21819 6.62578l-.22753 2.65523c-.10159 1.185-.47987 2.3295-1.10439 3.3416l-.69921 1.1324c-.70159 1.1367-.31873 2.3114 1.00559 2.4876 1.51433.2019 4.04327.3824 8.24373.3824 4.2005 0 6.729-.1805 8.2438-.3819 1.3243-.1762 1.7072-1.3509 1.0056-2.4876l-.6992-1.1324c-.6247-1.0124-1.0029-2.1573-1.1044-3.3426l-.2276-2.65473Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)

export const IconSerenisNotificationOff = (props: IconCommonProps) => <Icon Svg={SerenisNotificationOff} {...props} />
